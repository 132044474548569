import React from "react"
import classes from "./Drawer.module.css"
import classNames from "classnames"
import { useDetectClickOutside } from "../../../features/ui/hooks/useDetectClickOutside"
import { SwipeEventData, useSwipeable } from "react-swipeable"
import { useDisableScroll } from "../../../features/ui/hooks/useDisableScroll"

export type DrawerPosition = "top" | "bottom" | "left" | "right"

export interface DrawerClasses {
  closed?: string
  open?: string
}

export interface DrawerProps {
  open: boolean
  onClose?: () => void
  children: any
  position: DrawerPosition
  minWidth?: string | number
  maxWidth?: string | number
  minHeight?: string | number
  maxHeight?: string | number
  padding?: string | number
  mobileFullWidth?: boolean
  hAuto?: boolean
  className?: string
  zIndex: number
  classes?: DrawerClasses
  translateFullScreen?: boolean
  swipable?: boolean
}

export default function Drawer({
  children,
  open,
  onClose,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  padding,
  mobileFullWidth,
  hAuto,
  className,
  position,
  zIndex,
  translateFullScreen,
  classes: userClasses,
  swipable,
}: DrawerProps) {
  const [initialized, setInitialized] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const handleClicks = (event: any) => {
    if (ref.current?.contains(event.target)) {
      return
    }
    onClose?.()
  }

  React.useEffect(() => {
    setInitialized(true)
  }, [])

  useDisableScroll(open)

  useDetectClickOutside({
    onTriggered: handleClicks,
  })

  const translateClasses = classNames({
    "translate-x-0": open && (position === "right" || position === "left"),
    "translate-y-0": open && (position === "top" || position === "bottom"),
    "translate-x-full": !open && position === "right",
    "-translate-x-full": !open && position === "left" && !translateFullScreen,
    [classes.translateLeftOut]:
      !open && position === "left" && translateFullScreen,
    "-translate-y-full": !open && position === "top" && !translateFullScreen,
    [classes.translateRightOut]:
      !open && position === "top" && translateFullScreen,
    "translate-y-full": !open && position === "bottom",
  })

  const positionClasses = classNames({
    "right-0 top-0": position === "right",
    "left-0 top-0": position === "left",
    "top-0 left-0": position === "top",
    "bottom-0 left-0": position === "bottom",
  })

  const handleSwipe = (eventData: SwipeEventData) => {
    if (eventData.dir === "Down" && position === "bottom") {
      onClose?.()
    }
    if (eventData.dir === "Up" && position === "top") {
      onClose?.()
    }
    if (eventData.dir === "Right" && position === "left") {
      onClose?.()
    }
    if (eventData.dir === "Left" && position === "right") {
      onClose?.()
    }
  }

  const handlers = swipable
    ? useSwipeable({
        onSwiped: handleSwipe,
      })
    : {}

  return (
    <>
      <div
        ref={ref}
        style={{
          zIndex,
        }}
        className={`${classes.shadow} ${open ? classes.shadowActive : ""}`}
      />
      <main
        {...handlers}
        style={{
          zIndex,
        }}
        className={classNames(
          classes.root,
          initialized ? "" : "hidden",
          "fixed overflow-hidden inset-0 transform ease-in-out",
          translateClasses,
          open
            ? `transition-opacity opacity-100 duration-500 ${
                userClasses?.open ?? ""
              } `
            : `transition-all delay-500 opacity-0 ${
                userClasses?.closed ?? ""
              } `,
          {
            [classes.mobileFullWidth]: mobileFullWidth,
          },
          className
        )}
      >
        <section
          style={{
            minWidth,
            maxWidth,
            minHeight,
            maxHeight,
            padding,
          }}
          className={classNames(
            "w-screen absolute bg-white shadow-xl delay-400 duration-500 ease-in-out transition-all transform",
            translateClasses,
            positionClasses,
            {
              "max-w-lg": maxWidth === undefined,
              "h-full": hAuto !== true,
            }
          )}
        >
          <article
            className={classNames("relative flex flex-col w-full h-full", {
              "max-w-lg": maxWidth === undefined,
            })}
          >
            {children}
          </article>
        </section>
        <section
          className="w-screen h-full cursor-pointer "
          onClick={() => {
            onClose?.()
          }}
        ></section>
      </main>
    </>
  )
}
