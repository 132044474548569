import React from "react"
import { wishlistPagePath } from "../../../root/routing/urls"
import { HeartIconEmpty } from "../../../ui/icons"
import QuantityPopup from "../../../ui/molecules/QuantityPopup"
import ConditionalLink from "../../../ui/utilities/ConditionalLink"
import { useWishlist } from "../hooks/useWishlist"

type WishlistLinkType = "button" | "link"

export interface WishlistLinkProp {
  className?: string
  type: WishlistLinkType
  onClick?: () => void
}

const WishlistLink = ({ className, type, onClick }: WishlistLinkProp) => {
  const { wishlist } = useWishlist()
  return (
    <ConditionalLink
      link={wishlistPagePath()}
      disabled={type === "button"}
      onClick={() => (type === "button" ? onClick?.() : undefined)}
      prefetch={false}
    >
      <div>
        <QuantityPopup quantity={wishlist?.items.length}>
          <HeartIconEmpty className={className} />
        </QuantityPopup>
      </div>
    </ConditionalLink>
  )
}

WishlistLink.defaultProps = {
  type: "link",
}

export default WishlistLink
