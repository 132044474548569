import classNames from "classnames"
import Link from "next/link"
import React from "react"
import { useLabels } from "../../../../../../../features/contents/hooks"
import CustomButton from "../../../../../../../ui/atoms/Button"
import classes from "./HeaderGenderPages.module.css"

interface HeaderGenderPagesClasses {
  button: string
}

interface Props {
  className?: string
  buttonFullWidth?: boolean
  classes?: HeaderGenderPagesClasses
}

const HeaderGenderPages = ({
  className,
  buttonFullWidth,
  classes: userClasses,
}: Props) => {
  const { getLabel } = useLabels()
  return (
    <div className={className}>
      <Link href="/c/donna/" passHref>
        <a>
          <CustomButton
            variant="outlined"
            className={classNames(classes.button, userClasses?.button)}
            fullWidth={buttonFullWidth}
          >
            {getLabel("menu", "woman")}
          </CustomButton>
        </a>
      </Link>
      <Link href="/c/uomo/" passHref>
        <a>
          <CustomButton
            variant="outlined"
            className={classNames(classes.button, userClasses?.button)}
          >
            {getLabel("menu", "man")}
          </CustomButton>
        </a>
      </Link>
    </div>
  )
}

export default HeaderGenderPages
