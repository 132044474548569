import React from "react"
import { categoryPagePath } from "../../../../../../root/routing/urls"
import { useAlgoliaFullTextSearch } from "../AlgoliaFullTextSearchContext"
import CustomResultTextSection from "../CustomResultTextSection"

const CategoryResults = () => {
  const { results } = useAlgoliaFullTextSearch()
  if ((results?.categories?.length ?? 0) <= 0) {
    return <></>
  }
  return (
    <CustomResultTextSection
      className="pt-4 text-center"
      title="Categorie"
      titleClassName="font-medium"
      items={
        results?.categories.map((x) => ({
          label: x.name,
          url: categoryPagePath(x.path),
        })) ?? []
      }
    />
  )
}

export default CategoryResults
