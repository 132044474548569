import { ThemeColor } from "@punks/core"
import classNames from "classnames"
import React from "react"
import { getThemeColor } from "../../../themes/utils"
import classes from "./Stroke.module.css"

interface Props {
  color: ThemeColor
  colorValue?: string
  noMargin: boolean
  className?: string
  fillContainer?: boolean
  thickness?: number
  style?: React.CSSProperties
}

const Stroke = ({
  color,
  colorValue,
  noMargin,
  className,
  fillContainer,
  thickness,
  style,
}: Props) => {
  const strokeClasses = classNames(classes, className, classes.root, {
    [classes.noMargin]: noMargin,
    [classes.fillContainer]: fillContainer,
  })
  return (
    <div
      className={strokeClasses}
      style={{
        ...style,
        borderBottomColor: colorValue ?? getThemeColor(color),
        borderBottomWidth: thickness,
      }}
    />
  )
}

Stroke.defaultProps = {
  color: "active",
  thickness: 1,
  noMargin: true,
}

export default Stroke
