import React from "react"
import { IconProps } from "./types"

export const AccountIcon = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.947"
      height="20.788"
      viewBox="0 0 25.947 20.788"
      {...props}
    >
      <g
        id="Raggruppa_851"
        data-name="Raggruppa 851"
        transform="translate(-45.315 -52.27)"
      >
        <g
          id="Raggruppa_221"
          data-name="Raggruppa 221"
          transform="translate(-1182.009 -63.359)"
        >
          <path
            id="Tracciato_154"
            data-name="Tracciato 154"
            d="M1228.009,136.1a13.444,13.444,0,0,1,24.576.015"
            fill="none"
            stroke="#000"
            strokeWidth="1.5"
          />
          <circle
            id="Ellisse_1"
            data-name="Ellisse 1"
            cx="4.035"
            cy="4.035"
            r="4.035"
            transform="translate(1236.259 116.379)"
            fill="none"
            stroke="#000"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  )
}
