import React from "react"
import { brandPagePath } from "../../../../../../root/routing/urls"
import { useAlgoliaFullTextSearch } from "../AlgoliaFullTextSearchContext"
import CustomResultTextSection from "../CustomResultTextSection"

const BrandResults = () => {
  const { results } = useAlgoliaFullTextSearch()
  if ((results?.brands?.length ?? 0) <= 0) {
    return <></>
  }
  return (
    <CustomResultTextSection
      className="pt-4 text-center"
      title="Brand"
      titleClassName="font-medium"
      items={
        results?.brands?.map((x) => ({
          label: x.name,
          url: brandPagePath(x.slug),
        })) ?? []
      }
    />
  )
}

export default BrandResults
