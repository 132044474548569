import {
  readObject,
  removeObject,
  writeObject,
} from "../../../../../root/punks/utils/storage"

export interface GamePopupState {
  lastActivationTimestamp: number
  nextResetTimestamp: number
}

const GAME_POPUP_STATE_KEY = "_GamePopupState"

export const saveGamePopupState = (value: GamePopupState) =>
  writeObject(GAME_POPUP_STATE_KEY, value)

export const readGamePopupState = () =>
  readObject(GAME_POPUP_STATE_KEY) as GamePopupState | undefined

export const deleteGamePopupState = () => removeObject(GAME_POPUP_STATE_KEY)
