// import {
//   SearchProductsQueryVariables,
//   SearchProductsQueryResult,
//   SearchProductsQuery,
//   SearchProductsDocument,
// } from "../backend-api"
import client from "../backend-api-client"

import {
  GetCommerceProductByVariantIdDocument,
  GetCommerceProductByVariantIdQuery,
  GetCommerceProductByVariantIdQueryResult,
  GetCommerceProductByVariantIdQueryVariables,
} from "../backend-api"

// export const getProductInventoryQuery = (
//   variables: GetProductInventoryDataQueryVariables,
//   noCache?: boolean
// ): Promise<GetProductInventoryDataQueryResult> =>
//   client.query<
//     GetProductInventoryDataQuery,
//     GetProductInventoryDataQueryVariables
//   >({
//     query: GetProductInventoryDataDocument,
//     variables,
//     fetchPolicy: noCache ? "no-cache" : undefined,
//   }) as any

// export const searchProductsQuery = (
//   variables: SearchProductsQueryVariables,
//   noCache?: boolean
// ): Promise<SearchProductsQueryResult> =>
//   client.query<SearchProductsQuery, SearchProductsQueryVariables>({
//     query: SearchProductsDocument,
//     variables,
//     fetchPolicy: noCache ? "no-cache" : undefined,
//   }) as any

export const getCommerceProductByVariantId = (
  variables: GetCommerceProductByVariantIdQueryVariables
): Promise<GetCommerceProductByVariantIdQueryResult> =>
  client.query<
    GetCommerceProductByVariantIdQuery,
    GetCommerceProductByVariantIdQueryVariables
  >({
    query: GetCommerceProductByVariantIdDocument,
    variables,
    fetchPolicy: "no-cache",
  }) as any
