import React from "react"
import classes from "./SearchBar.module.css"
import CustomIconButton from "../../../../ui/atoms/IconButton/CustomIconButton"
import { SearchIcon } from "../../../../ui/icons"
import { CustomContainer } from "../../../../ui/spacings/CustomContainer"
import { useLabels } from "../../../contents/hooks"
import { ModalCloseIcon } from "../../../../ui/icons/ModalCloseIcon"

interface Props {
  onClose: () => void
  searchTerm: string
  onSearchTermChange: (value: string) => void
}

const SearchBar = ({ onClose, searchTerm, onSearchTermChange }: Props) => {
  const { getLabel } = useLabels()
  return (
    <div className={classes.root}>
      <CustomContainer className={classes.container}>
        <div className={classes.searchIconContainer}>
          <SearchIcon className={classes.searchIcon} />
        </div>
        <div className={classes.inputContainer}>
          <input
            className={classes.input}
            value={searchTerm}
            onChange={(e) => onSearchTermChange(e.target.value)}
            placeholder={getLabel("search", "text-input")}
          />
        </div>
        <div className="hidden sm:block">
          <CustomIconButton
            onClick={onClose}
            icon={<ModalCloseIcon width={16} />}
          />
        </div>
      </CustomContainer>
    </div>
  )
}

export default SearchBar
