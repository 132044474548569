import * as React from "react"
import classes from "./SvgIcon.module.css"

export default function createSvgIcon(path: any, displayName: string) {
  const Component = (props: any, ref: any) => {
    const { className, width, ...other } = props
    return (
      <svg
        className={`${classes.root} ${className ?? ""}`}
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        data-testid={`${displayName}Icon`}
        ref={ref}
        style={{
          width,
        }}
        {...other}
      >
        {path}
      </svg>
    )
  }

  if (process.env.NODE_ENV !== "production") {
    Component.displayName = `${displayName}Icon`
  }

  return React.memo(React.forwardRef(Component))
}
