import React, { useEffect } from "react"
import {
  updateMobileMenuState,
  updateSearchDialogState,
} from "../../../../features/ui/state/uiActions"
import { useAppDispatch } from "../../../../state/store"
import DefaultDesktopHeader from "./DefaultDesktopHeader"
import DefaultMobileHeader from "./DefaultMobileHeader"

interface Props {
  hidden?: boolean
  hiddenMobile?: boolean
  mobileHeader?: React.ReactNode
  home?: boolean
}

const DefaultHeader = ({ hidden, hiddenMobile, mobileHeader, home }: Props) => {
  const dispatch = useAppDispatch()
  const openMobileMenu = () => dispatch(updateMobileMenuState(true))
  const openSearch = () => dispatch(updateSearchDialogState(true))
  const [headerShrink, setHeaderShrink] = React.useState(false)

  const handleScroll = () => {
    setHeaderShrink(window.scrollY > 2)
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      {hidden !== true && (
        <div className="hidden lg:block">
          <DefaultDesktopHeader fixed shrink={headerShrink} home={home} />
        </div>
      )}
      {hiddenMobile !== true && (
        <div className="lg:hidden">
          {mobileHeader || (
            <DefaultMobileHeader
              shrink={headerShrink}
              onMenuClick={openMobileMenu}
              onSearchClick={openSearch}
              replaceLogo={home}
              home={home}
            />
          )}
        </div>
      )}
    </>
  )
}

export default DefaultHeader

export { DefaultDesktopHeader, DefaultMobileHeader }
