import classNames from "classnames"
import React from "react"

interface Props {
  children: any
  mobileHidden?: boolean
  desktopHidden?: boolean
  hidden?: boolean
}

const ResponsiveSwitch = ({
  hidden,
  mobileHidden,
  desktopHidden,
  children,
}: Props) => {
  if (hidden) {
    return <></>
  }
  return (
    <div
      className={classNames(
        mobileHidden && "hidden",
        desktopHidden ? "sm:hidden" : "sm:block"
      )}
    >
      {children}
    </div>
  )
}

export default ResponsiveSwitch
