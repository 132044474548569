import React from "react"
import classNames from "classnames"
import { ThemeBreakpoint, ThemeColor } from "@punks/core"
import { useIsDown } from "../../../../features/layout/hooks"
import classes from "./CustomDialog.module.css"
import Modal from "./Modal"
import CustomDialogHead from "../CustomDialogHead"

export type DialogOpenDirection = "left" | "right" | "up" | "down"
export type DialogPosition = "left" | "right" | "top" | "bottom" | "center"

interface Props {
  open: boolean
  onClose?: () => void
  fullScreen?: boolean
  fullScreenMaxBreakpoint: ThemeBreakpoint
  fullScreenAll?: boolean
  fullWidth?: boolean
  minWidth?: string | number
  maxWidth?: string | number
  head?: React.ReactNode
  children?: React.ReactNode
  actions?: React.ReactNode
  backgroundColor?: ThemeColor
  noGutters?: boolean
  openDirection: DialogOpenDirection
  closeDirection?: DialogOpenDirection
  openSpeed?: number
  closeSpeed?: number
  position?: DialogPosition
  padding?: string | number
  userClass: {
    head?: string
    container?: string
    backDrop?: string
    modal?: string
    content?: string
  }
  zIndex?: number
  unmount?: boolean
}

export const CustomDialog = ({
  open,
  onClose,
  fullScreen,
  fullScreenMaxBreakpoint,
  fullScreenAll,
  fullWidth,
  minWidth,
  maxWidth,
  head,
  children,
  actions,
  // backgroundColor,
  padding,
  noGutters,
  // openDirection,
  // closeDirection,
  // openSpeed,
  // closeSpeed,
  // position,
  userClass,
  zIndex,
  unmount,
}: Props) => {
  const dialogFullScreen = fullScreen && useIsDown(fullScreenMaxBreakpoint)

  return (
    <Modal
      // classes={{
      //   paper: classNames(classes.root, userClass.container),
      //   container: classes.container,
      // }}
      minWidth={minWidth}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={dialogFullScreen || fullScreenAll}
      open={open}
      noGutters={noGutters}
      zIndex={zIndex}
      padding={padding}
      unmount={unmount}
      className={userClass?.modal}
      // TransitionComponent={Transition}
      // TransitionProps={
      //   {
      //     direction: open ? openDirection : closeDirection ?? openDirection,
      //     timeout: {
      //       enter: openSpeed,
      //       exit: closeSpeed,
      //     },
      //   } as CustomTransitionProps
      // }
      onClose={() => onClose?.()}
      // fullScreen={dialogFullScreen}
      // BackdropProps={{
      //   classes: { root: userClass.backDrop },
      // }}
    >
      {head ? (
        <div className={classNames(classes.head, userClass.head)}>{head}</div>
      ) : undefined}
      {children ? (
        <div className={classNames(classes.content, userClass?.content)}>
          {children}
        </div>
      ) : undefined}
      {actions ? <div className={classes.actions}>{actions}</div> : undefined}
    </Modal>
  )
}

CustomDialog.Head = CustomDialogHead

CustomDialog.defaultProps = {
  fullScreenMaxBreakpoint: "xs",
  openDirection: "down",
  openSpeed: 450,
  closeSpeed: 150,
  position: "center",
  noMargins: false,
  userClass: {},
}
