import Link from "next/link"
import React from "react"
import { loginPageUrl, profilePagePath } from "../../../../root/routing/urls"
import { useAppSelector } from "../../../../state/store"
import CustomButton from "../../../../ui/atoms/Button"
import Typography from "../../../../ui/atoms/Typography"
import classes from "./MobileMenuHead.module.css"

const MobileMenuHead = () => {
  const { userProfile, isAuthenticated } = useAppSelector((state) => state.user)

  return (
    <div className={classes.root}>
      <div className={classes.login}>
        {isAuthenticated ? (
          <Link href={profilePagePath()} passHref>
            <a>
              <Typography variant="subtitle1" weight="bold">
                Ciao {userProfile?.firstName}
              </Typography>
            </a>
          </Link>
        ) : (
          <Link href={loginPageUrl()} passHref>
            <a>
              <CustomButton size="small" fullWidth>
                Accedi
              </CustomButton>
            </a>
          </Link>
        )}
      </div>
    </div>
  )
}

export default MobileMenuHead
