import { getValidFromFilter, getValidToFilter } from "../../../api/filters"
import {
  BRANDS_INDEX_NAME,
  CATEGORIES_INDEX_NAME,
  getAlgoliaClient,
  PRODUCTS_INDEX_NAME,
} from "../../../services/client"

export interface FullTextSearchInput {
  query: string
  totProducts: number
  totBrands: number
  totCategories: number
  timestamp: Date
}

export const fullTextSearch = async (input: FullTextSearchInput) => {
  return await getAlgoliaClient().search<any>([
    {
      indexName: PRODUCTS_INDEX_NAME,
      params: {
        filters: [
          "inventory.maxSellQuantity>0",
          "hidden:false",
          `(${getValidFromFilter(input.timestamp)})`,
          `(${getValidToFilter(input.timestamp)})`,
        ].join(" AND "),
        facetingAfterDistinct: true,
        enablePersonalization: true,
        hitsPerPage: input.totProducts,
        query: input.query,
      },
    },
    {
      indexName: BRANDS_INDEX_NAME,
      params: {
        filters: "availableProducts>=0",
        hitsPerPage: input.totBrands,
        query: input.query,
      },
    },
    {
      indexName: CATEGORIES_INDEX_NAME,
      params: {
        filters: "availableProducts>0",
        hitsPerPage: input.totBrands,
        query: input.query,
      },
    },
  ])
}
