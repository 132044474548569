import React from "react"

export interface AlgoliaFullTextSearchResults {
  products: any[]
  brands: any[]
  categories: any[]
  hasMoreProducts: boolean
  noResults: boolean
}

export interface AlgoliaFullTextSearchData {
  results?: AlgoliaFullTextSearchResults
  query: string
  // onQueryUpdate: (query: string) => void
}

export const AlgoliaFullTextSearchContext = React.createContext<AlgoliaFullTextSearchData>(
  {
    query: "",
    // onQueryUpdate: () => {},
  }
)

export const useAlgoliaFullTextSearch = () =>
  React.useContext(AlgoliaFullTextSearchContext)
