import classNames from "classnames"
import React from "react"
import { CurrencyPosition, formatMoney } from "../../../../utils/money"
import Typography from "../../../atoms/Typography"
import classes from "./ProductPrice.module.css"

export type Layout = "horizontal" | "vertical"

interface Classes {
  price?: string
  compareAtPrice?: string
  newCompareAtPrice?: string
}

export interface ProductPriceProps {
  compareAtPrice?: number
  newCompareAtPrice?: number
  price: number
  layoutXs?: Layout
  layout: Layout
  className?: string
  small?: boolean
  left?: boolean
  mobileCenter?: boolean
  classes?: Classes
  currencyPosition?: CurrencyPosition
}

const ProductPrice = ({
  compareAtPrice,
  newCompareAtPrice,
  price,
  layout,
  layoutXs,
  className,
  small,
  left,
  mobileCenter,
  currencyPosition,
  classes: userClasses,
}: ProductPriceProps) => {
  const hasDiscount = compareAtPrice && compareAtPrice > price
  const hasExtraDiscount = newCompareAtPrice && newCompareAtPrice > price
  const hasPrice = price > 0
  return (
    <div
      className={classNames(
        {
          [classes.horizontal]: layout === "horizontal",
          [classes.horizontalXs]: (layoutXs ?? layout) === "horizontal",
          [classes.vertical]: layout === "vertical",
          [classes.verticalXs]: (layoutXs ?? layout) === "vertical",
          [classes.left]: left,
          [classes.mobileCenter]: mobileCenter,
        },
        className
      )}
    >
      {hasDiscount && (
        <Typography
          variant="subtitle2"
          component="p"
          textClassName={classNames(
            classes.compareAtPrice,
            {
              [classes.compareAtPriceHorizontal]: layout === "horizontal",
            },
            userClasses?.compareAtPrice
          )}
        >
          <del>
            {formatMoney({
              amount: compareAtPrice,
              currency: "eur",
              currencyPosition,
            })}
          </del>
        </Typography>
      )}
      {hasExtraDiscount ? (
        <Typography
          variant="subtitle2"
          component="p"
          textClassName={classNames(
            classes.newCompareAtPrice,
            {
              [classes.priceSmall]: small,
              [classes.newCompareAtPriceHorizontal]: layout === "horizontal",
            },
            userClasses?.newCompareAtPrice
          )}
        >
          <del>
            {formatMoney({
              amount: newCompareAtPrice,
              currency: "eur",
              currencyPosition,
            })}
          </del>
        </Typography>
      ) : undefined}
      {hasPrice ? (
        <Typography
          variant="subtitle2"
          component="p"
          textClassName={classNames(
            classes.price,
            {
              [classes.priceSmall]: small,
              [classes.extraDiscountedPrice]: hasExtraDiscount,
            },
            userClasses?.price
          )}
          color={hasDiscount ? "error" : undefined}
        >
          <ins style={{ textDecoration: "none" }}>
            {formatMoney({
              amount: price,
              currency: "eur",
              currencyPosition,
            })}
          </ins>
        </Typography>
      ) : undefined}
    </div>
  )
}

export default ProductPrice
