import classNames from "classnames"
import React from "react"

export interface CustomContainerProps {
  children: any
  className?: string
  fluid?: boolean
  noGutters?: boolean
  mobileNoGutters?: boolean
  mdNoGutters?: boolean
  lgNoGutters?: boolean
  xlNoGutters?: boolean
  desktopNoGutters?: boolean
  style?: React.CSSProperties
  h100?: boolean
  flexCol?: boolean
}

export const CustomContainer = ({
  children,
  className,
  fluid,
  noGutters,
  mobileNoGutters,
  desktopNoGutters,
  mdNoGutters,
  lgNoGutters,
  xlNoGutters,
  style,
  h100,
  flexCol,
}: CustomContainerProps) => {
  return (
    <div
      className={classNames(
        {
          "max-w-full px-0": fluid,
          "px-0": noGutters,
          "px-0 sm:px-6": mobileNoGutters && !fluid,
          "px-0 max-w-full md:px-6 md:max-w-auto": mdNoGutters && !fluid,
          "px-0 max-w-full lg:px-6 lg:max-w-auto": lgNoGutters && !fluid,
          "px-0 max-w-full xl:px-6 xl:max-w-auto": xlNoGutters && !fluid,
          "sm:px-0": desktopNoGutters && !fluid,
          "h-full": h100,
          "flex flex-col flex-1": flexCol,
        },
        "container",
        className
      )}
      style={style}
    >
      {children ?? <></>}
    </div>
  )
}
