import { ThemeColor } from "@punks/core"
import React from "react"
import { useAppDispatch } from "../../../state/store"
import { lightBlack } from "../../../themes/variables"
import { HeartIconEmpty, HeartIconFilled } from "../../../ui/icons"
import { useWishlist } from "../hooks/useWishlist"
import { addItemToUserWishlist } from "../state/actions/addItemToUserWishlist"
import { removeItemFromUserWishlist } from "../state/actions/removeItemFromWishlist"

interface Props {
  variantId: string
  className?: string
  color?: ThemeColor
  iconColor?: string
  iconFontSize?: string | number
  iconWidth?: number
}

const WishlistButton = ({
  variantId,
  color,
  iconColor,
  iconFontSize,
  iconWidth,
  className,
}: Props) => {
  const dispatch = useAppDispatch()
  const { wishlist, updating } = useWishlist()
  const inWishlist = wishlist?.items.find((x) => x.variantId === variantId)
  const styles = {
    fontSize: iconFontSize ?? 24,
    color: iconColor ?? lightBlack,
    width: iconWidth,
    height: iconWidth,
  }

  const toggleWishlist = () => {
    if (updating) {
      return
    }

    if (inWishlist) {
      dispatch(removeItemFromUserWishlist(variantId))
    } else {
      dispatch(addItemToUserWishlist(variantId)) //, variant))
    }
  }

  return (
    <div color={color} className={className} onClick={toggleWishlist}>
      {inWishlist ? (
        <HeartIconFilled style={styles} />
      ) : (
        <HeartIconEmpty style={styles} />
      )}
    </div>
  )
}

export default WishlistButton
