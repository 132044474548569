import classNames from "classnames"
import React from "react"
import style from "./index.module.css"

function isFunction(functionToCheck: any) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  )
}

export interface SelectionRange {
  start: number
  end: number
}

export interface CustomTextInputProps {
  id?: string
  name?: string
  label?: string
  variant?: "filled" | "outlined" | "standard"
  value?: string
  defaultValue?: string | number | ReadonlyArray<string>
  autoComplete?: string
  placeholder?: string
  error?: boolean
  pattern?: string
  classes?: {
    container?: string
    input?: string
    label?: string
    endAdornment?: string
  }
  required?: boolean
  fullWidth?: boolean
  disabled?: boolean
  inputRef?: React.Ref<any>
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week"
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
  onEnterKey?: () => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onInput?: (e: React.FormEvent) => void
  onChange?: (value: string) => void
  onFocus?: (e: React.FocusEvent) => void
  onTouchEnd?: (e: React.TouchEvent) => void
  className?: string
  endAdornment?: React.ReactNode
  endAdornmentFocusOnClick?: boolean
  selectionRange?: SelectionRange
}

const CustomTextInput = ({
  id,
  name,
  label,
  type,
  inputMode,
  inputRef,
  placeholder,
  pattern,
  required,
  fullWidth,
  value,
  defaultValue,
  classes,
  variant,
  error,
  disabled,
  autoComplete,
  onEnterKey,
  onKeyDown,
  onInput,
  onChange,
  onFocus,
  onTouchEnd,
  className,
  endAdornment,
  endAdornmentFocusOnClick,
  selectionRange,
}: CustomTextInputProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(event)
    if (event.key === "Enter" && onEnterKey) {
      onEnterKey()
    }
  }

  const ref = React.useRef<HTMLInputElement>(null)
  const focusInput = () => {
    ;(ref?.current as any)?.focus?.()
  }

  React.useEffect(() => {
    if (selectionRange) {
      if (!ref.current) {
        return
      }
      ref.current.setSelectionRange(selectionRange.start, selectionRange.end)
    }
  }, [selectionRange?.start, selectionRange?.end])

  const registerRef = (element: HTMLInputElement | null) => {
    if (inputRef) {
      // eslint-disable-next-line
      if ((inputRef as any).hasOwnProperty("current")) {
        ;(inputRef as any).current = element
      } else if (isFunction(inputRef)) {
        ;(inputRef as any)(element)
      } else {
        console.error("inputRef invalid", inputRef)
      }
    }
    ;(ref as any).current = element
  }

  const handEndAdornmentClick = () => {
    if (endAdornmentFocusOnClick) {
      focusInput()
    }
  }

  return (
    <div
      className={classNames(
        "relative h-12",
        { "w-full": fullWidth },
        classes?.container,
        className
      )}
    >
      <input
        ref={(element) => {
          registerRef(element)
        }}
        id={id}
        type={type}
        inputMode={inputMode}
        onInput={onInput}
        onChange={(e) => onChange?.(e.target.value)}
        required={required}
        name={name}
        value={value}
        placeholder={placeholder ?? label}
        pattern={pattern}
        autoComplete={autoComplete}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        defaultValue={defaultValue}
        onFocus={onFocus}
        onTouchEnd={onTouchEnd}
        className={classNames(
          style.input,
          variant === "filled" && style.inputFilled,
          "w-full h-full px-3 transition-all",
          variant === "filled" ? "bg-disabled border-0" : "border border-solid",
          error ? style.inputError : "",
          classes?.input
        )}
      />
      {label && (
        <label
          htmlFor="newsletter-email"
          onClick={() => focusInput()}
          className={classNames(
            "absolute px-1 text-xs transition-all transform -translate-y-1/2 select-none cursor-text left-2 focus:outline-none",
            variant === "standard" ? "bg-white top-0" : "",
            classes?.label
          )}
        >
          {required ? `${label} *` : label}
        </label>
      )}
      {endAdornment && (
        <div
          className={classNames(style.endAdornment, classes?.endAdornment)}
          onClick={() => handEndAdornmentClick()}
        >
          {endAdornment}
        </div>
      )}
    </div>
  )
}
CustomTextInput.defaultProps = {
  variant: "filled",
}

export default CustomTextInput
