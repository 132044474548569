import React from "react"
import Head from "next/head"

const fonts = [
  // "/fonts/GillSansNova/GillSansNova-Book.woff2",
  // "/fonts/GillSansNova/GillSansNova-Medium.woff2",
  // "/fonts/GillSansNova/GillSansNova-Light.woff2",
  // "/fonts/GillSansNova/GillSansNova-SemiBold.woff2",
  "/fonts/Manrope/Manrope-Regular.ttf",
  "/fonts/Manrope/Manrope-Medium.ttf",
  "/fonts/Manrope/Manrope-Light.ttf",
  "/fonts/Manrope/Manrope-Bold.ttf",
]

const DefaultDocumentHead = () => {
  return (
    <Head>
      {fonts.map((font) => (
        <link rel="preload" key={font} href={font} as="font" crossOrigin="" />
      ))}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap"
        rel="stylesheet"
      />
    </Head>
  )
}

export default DefaultDocumentHead
