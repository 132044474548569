import * as React from "react"
import createSvgIcon from "../utilities/SvgIcon"

export const SearchIcon = createSvgIcon(
  <path
    d="M18.3115606,3.1417671 C22.3508419,7.1810484 22.4952307,13.6403056 18.7447271,17.8525966 L23.8038145,22.9114737 C24.3987083,23.5063675 23.5063675,24.3987083 22.9114737,23.8038145 L17.8525966,18.7447271 L17.8525966,18.7447271 C13.6403056,22.4952307 7.1810484,22.3508419 3.1417671,18.3115606 C-1.0472557,14.1225378 -1.0472557,7.3307899 3.1417671,3.1417671 C7.3307899,-1.0472557 14.1225378,-1.0472557 18.3115606,3.1417671 Z M4.0341079,4.0341079 C0.337911305,7.73030449 0.337911305,13.7230232 4.0341079,17.4192198 C7.73030449,21.1154164 13.7230232,21.1154164 17.4192198,17.4192198 C21.1154164,13.7230232 21.1154164,7.73030449 17.4192198,4.0341079 C13.7230232,0.337911305 7.73030449,0.337911305 4.0341079,4.0341079 Z"
    id="path"
  ></path>,
  "Search"
)

interface IconProps {
  width: number
}

export const SearchIcon2 = ({ width }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 15.612 15.612"
    >
      <defs>
        <style>{".a{fill:none;stroke:#000;stroke-miterlimit:10;}"}</style>
      </defs>
      <g transform="translate(-1188.502 114.324)">
        <circle
          className="a"
          cx="5.541"
          cy="5.541"
          r="5.541"
          transform="translate(1189.002 -113.824)"
        />
        <line
          className="a"
          x2="5.3"
          y2="5.3"
          transform="translate(1198.461 -104.366)"
        />
      </g>
    </svg>
  )
}
