import React from "react"
import MenuPanel, {
  MenuPanelSection,
} from "../../../../ui/organisms/Menu/MenuPanel"

export interface MobileMenuPanelProps {
  sections: MenuPanelSection[]
  head?: React.ReactNode
  className?: string
  leftPadding?: number
  narrow?: boolean
}

const MobileMenuPanel = ({
  sections,
  head,
  className,
  leftPadding,
  narrow,
}: MobileMenuPanelProps) => {
  return (
    <MenuPanel
      sections={sections}
      head={head}
      className={className}
      leftPadding={leftPadding}
      narrow={narrow}
    />
  )
}

export default MobileMenuPanel
