import React from "react"

import { IconProps } from "./types"

export const ChevronDownIcon = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.385"
      height="12.326"
      viewBox="0 0 25.385 12.326"
      {...props}
    >
      <path
        id="Tracciato_220"
        data-name="Tracciato 220"
        d="M-285.115,175.136l12.2,10.763,12.2-10.763"
        transform="translate(285.611 -174.574)"
        fill="none"
        stroke="#000"
        strokeWidth="1.5"
      />
    </svg>
  )
}
