import * as React from "react"
import { IconProps } from "./types"

export interface CloseIconProps extends IconProps {
  color?: string
}

export const ModalCloseIcon = (props: CloseIconProps) => {
  const { color, ...other } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.077"
      height="26.214"
      viewBox="0 0 25.077 26.214"
      {...other}
    >
      <g transform="translate(-2090.89 -1957.338)">
        <line
          x2="24.353"
          y2="25.524"
          transform="translate(2091.251 1957.683)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
        <line
          x1="24.353"
          y2="25.524"
          transform="translate(2091.251 1957.683)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

ModalCloseIcon.defaultProps = {
  color: "currentColor",
  width: 18,
}
