import { ThemeColor } from "@punks/core"
import classNames from "classnames"
import React from "react"
import classes from "./QuantityPopup.module.css"
import Dot from "../../atoms/Dot"

// const useStyles = makeStyles(() => ({
//   root: {
//     position: "relative",
//   },
//   count: {
//     position: "absolute",
//     top: -4,
//     right: -6,
//     fontSize: 10,
//   },
// }))

export interface QuantityPopupProps {
  quantity?: number
  color: ThemeColor
  children: any
  className?: string
  dotClassName?: string
}

const QuantityPopup = ({
  quantity,
  children,
  color,
  className,
  dotClassName,
}: QuantityPopupProps) => {
  return (
    <div className={classNames(classes.root, className)}>
      {children}
      {quantity && quantity > 0 ? (
        <div className={classes.count}>
          <Dot color={color} className={dotClassName}>
            {quantity}
          </Dot>
        </div>
      ) : undefined}
    </div>
  )
}

QuantityPopup.defaultProps = {
  color: "primary",
}

export default QuantityPopup
