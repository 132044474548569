import { CheckoutInput } from "../../../../api/backend/backend-api"
import { Basket } from "../checkoutTypes"
import { createBasketPayload } from "./checkoutPayload"

export const toCheckoutInput = (basket: Basket): CheckoutInput => ({
  lineItems: basket.items.map((x) => ({
    isGift: x.order.gift,
    quantity: x.order.quantity,
    variantId: x.variant?.info.variantId as string,
    productId: x.product?.info.productId ?? "",
    promoCodes: x.order.promoCodes,
    source: x.source,
  })),
  payload: createBasketPayload(),
})
