import classNames from "classnames"
import React from "react"
import classes from "./Loader.module.css"

export interface LoaderProps {
  fullScreen?: boolean
  show?: boolean
  className?: string
}

const Loader = ({ fullScreen, show, className }: LoaderProps) => {
  if (show === false) {
    return <></>
  }

  return (
    <div
      className={classNames({
        [classes.fullScreen]: fullScreen,
        [classes.regular]: !fullScreen,
      })}
    >
      <div className={classNames("lds-ring", className)}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader
