import sanityClient, { SanityClient } from "@sanity/client"
import { createPreviewSubscriptionHook } from "next-sanity"

let defaultClient: SanityClient
export const client = () => {
  if (!defaultClient) {
    defaultClient = sanityClient({
      dataset: process.env.SANITY_DATASET,
      projectId: process.env.SANITY_PROJECT_ID ?? "",
      useCdn: process.env.NODE_ENV === "production",
    })
  }
  return defaultClient
}

let defaultPreviewClient: SanityClient
export const previewClient = () => {
  if (!defaultPreviewClient) {
    defaultPreviewClient = sanityClient({
      dataset: process.env.SANITY_DATASET,
      projectId: process.env.SANITY_PROJECT_ID ?? "",
      token: process.env.SANITY_TOKEN,
      useCdn: false,
    })
  }
  return defaultPreviewClient
}

export const usePreviewSubscription = createPreviewSubscriptionHook({
  dataset: process.env.SANITY_DATASET ?? "",
  projectId: process.env.SANITY_PROJECT_ID ?? "",
})

export const getClient = (preview = false) =>
  preview ? previewClient() : client()
