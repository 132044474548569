import { useIsClient } from "@punks/core"
import React from "react"

export const useDisableScroll = (disableScroll: boolean) => {
  const isClient = useIsClient()
  const [activated, setActivated] = React.useState(false)

  const handleDisableScroll = () => {
    document.body.classList.add("noScroll")
  }
  const handleEnableScroll = () => {
    document.body.classList.remove("noScroll")
  }

  React.useEffect(() => {
    if (!isClient) {
      return
    }

    if (disableScroll) {
      handleDisableScroll()
      setActivated(true)
    } else {
      if (activated) {
        handleEnableScroll()
      }
    }
  }, [disableScroll])
}
