import classes from "./HeadSearchPopover.module.css"
import React from "react"
import FullTextSearchPanelWrapper from "../FullTextSearchPanelWrapper"
import CustomPopover from "../../../../ui/organisms/CustomPopover"

interface Props {
  anchor: HTMLElement
  open: boolean
  onClose: () => void
  query: string
  topOffset?: number
}

const HeadSearchPopover = ({
  anchor,
  open,
  onClose,
  query,
  topOffset,
}: Props) => {
  // const top =
  //   anchor.getBoundingClientRect().height + anchor.getBoundingClientRect().y

  return (
    <CustomPopover
      open={open}
      onClose={onClose}
      anchor={anchor}
      contentParams={{
        className: classes.content,
        topOffset,
      }}
      position="bottom"
      disableScroll
    >
      <FullTextSearchPanelWrapper term={query} onClose={onClose} />
    </CustomPopover>
  )
}

export default HeadSearchPopover
