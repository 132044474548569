import Link from "next/link"
import React from "react"

interface ConditionalLinkProps {
  link?: string
  children: any
  className?: string
  disabled?: boolean
  onClick?: () => void
  style?: React.CSSProperties
  noDiv?: boolean
  jsLink?: boolean
  prefetch?: boolean
}

const ConditionalLink = ({
  link,
  disabled,
  children,
  onClick,
  className,
  noDiv,
  prefetch,
  style,
}: ConditionalLinkProps) => {
  if (link && !disabled) {
    return (
      <Link href={link} passHref prefetch={prefetch}>
        <a className={className}>{children}</a>
      </Link>
    )
  }
  if (noDiv) {
    return <>{children}</>
  }
  return (
    <div className={className} onClick={onClick} style={style}>
      {children}
    </div>
  )
}

export default ConditionalLink
