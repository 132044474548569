import React, { Fragment, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import classNames from "classnames"
import classes from "./Modal.module.css"

interface Props {
  open: boolean
  onClose: () => void
  children: any
  zIndex: number
  fullScreen?: boolean
  fullWidth?: boolean
  minWidth?: string | number
  maxWidth?: string | number
  noGutters?: boolean
  padding?: string | number
  unmount?: boolean
  className?: string
}

const Modal = ({
  onClose,
  children,
  open,
  zIndex,
  minWidth,
  maxWidth,
  fullScreen,
  fullWidth,
  noGutters,
  padding,
  unmount,
  className,
}: Props) => {
  const dialogRef = useRef(null)
  return (
    <Transition show={open} as={Fragment} unmount={unmount}>
      <Dialog
        initialFocus={dialogRef}
        open={open}
        onClose={onClose}
        className="fixed inset-0 flex items-center justify-center"
        style={{
          zIndex,
        }}
        unmount={unmount}
      >
        <Transition.Child
          enter="ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-90"
          leave="ease-in duration-150"
          leaveFrom="opacity-90"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 z-20 bg-black opacity-50" />
        </Transition.Child>
        <div ref={dialogRef}></div>

        <Transition.Child
          enter="transform transition duration-400"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 rotate-0 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100 "
          leaveTo="opacity-0 scale-95 "
          className={classNames(
            "overflow-y-auto z-30 bg-white w-full flex flex-col items-stretch",
            {
              "px-2 py-2": !noGutters,
              "my-8": !fullScreen,
              "mx-8": !fullScreen,
              [classes.maxWidthLg]: maxWidth === undefined && !fullScreen,
              "w-full": fullScreen,
              "h-full": fullScreen,
              "w-100": fullWidth,
              [classes.notFull]: !fullScreen,
            },
            className
          )}
          style={{
            minWidth,
            maxWidth,
            padding,
          }}
        >
          {/* <Dialog.Title>{title}</Dialog.Title> */}
          <div className="flex flex-col items-stretch flex-1">{children}</div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}
Modal.defaultProps = {
  zIndex: 999,
}

export default Modal
