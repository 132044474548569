import React, { AriaAttributes } from "react"
import { ThemeColor } from "@punks/core"
import classes from "./CustomIconButton.module.css"
import CustomButton from "../Button"
import { getThemeColor, getThemeColorContrast } from "../../../themes/utils"

interface Props extends AriaAttributes {
  icon: React.ReactNode
  color?: ThemeColor
  iconColor?: ThemeColor
  onClick?: () => void
  preventDefault?: boolean
  className?: string
  disabled?: boolean
  style?: React.CSSProperties
  size?: string | number
}

const normalizeSize = (value: number | string) =>
  typeof value === "string" ? value : `${value}px`

const CustomIconButton = ({
  className,
  icon,
  color,
  iconColor,
  onClick,
  preventDefault,
  style,
  size,
  ...rest
}: Props) => {
  const getIconColor = () => {
    if (iconColor) {
      return getThemeColor(iconColor)
    }

    if (color) {
      return getThemeColorContrast(color)
    }
  }

  return (
    <CustomButton
      className={className}
      variant="text"
      classes={{ button: classes.button }}
      onClick={onClick}
      style={{
        ["--icon-color" as any]: getIconColor(),
        ["--bg-color" as any]: color ? getThemeColor(color) : undefined,
        ["--size" as any]: size ? normalizeSize(size) : "34px",
        ...style,
      }}
      {...rest}
    >
      <div className={classes.iconContainer}>{icon}</div>
    </CustomButton>
  )
}

export default CustomIconButton
