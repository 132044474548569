import React from "react"
import classes from "./index.module.css"
import { productPagePath } from "../../../../root/routing/urls"
import Square from "../../../shapes/Square"
import { lightGray } from "../../../../themes/variables"
import ProductPrice from "../../../molecules/Product/ProductPrice"
import ProductBadges from "../ProductBadges"
import ProductLabel from "../../../molecules/Product/ProductLabel"
import WishlistButton from "../../../../features/wishlist/components/WishlistButton"
import dynamic from "next/dynamic"
import DynamicModalLoader from "../../../../features/ui/components/DynamicWrapper"
import { EyeIcon } from "../../../icons"
import DesktopOnly from "../../../../root/punks/mui/visibility/DesktopOnly"
import { SanityNextImg } from "../../../../cms/components"
import { toTitleCase } from "../../../../utils/strings"
import Typography from "../../../atoms/Typography"
import classNames from "classnames"
import { useLabels } from "../../../../features/contents/hooks"
import { useRouter } from "next/router"
import { getQueryParam } from "../../../../utils/url"
import { PartialGridItemProduct } from "./types"

export interface ProductGridItemProps {
  product: PartialGridItemProduct
  prefetch?: boolean
  className?: string
  lineXs?: boolean
  textLeftXs?: boolean
  verticalPriceXs?: boolean
  quickViewZIndex?: number
  onClick?: () => void
  disableNavigation?: boolean
  hideExtraControls?: boolean
}

const QuickViewDialog = dynamic(() => import("./QuickViewDialog"), {
  ssr: false,
})

const ProductGridItem = ({
  product,
  className,
  lineXs,
  textLeftXs,
  verticalPriceXs,
  quickViewZIndex,
  onClick,
  disableNavigation,
  hideExtraControls,
}: ProductGridItemProps) => {
  const { getLabel } = useLabels()
  const variant = product.defaultVariant

  const [showQuickView, setShowQuickView] = React.useState(false)
  const [showEan, setShowEan] = React.useState(false)
  React.useEffect(
    () => setShowEan(getQueryParam(window.location, "showEan") === "true"),
    []
  )

  const price = variant.inventory?.price ?? 0
  const url = productPagePath(variant.info.slug)
  const isAvailable = variant.inventory?.available ?? false
  const router = useRouter()

  const handleProductClick = () => {
    onClick?.()

    if (disableNavigation) {
      return
    }
    router.push(url)
  }

  return (
    <div
      className={`relative px-2 pb-0 pt-7 sm:m-2 sm:p-4 sm:pt-7 ${
        className ?? ""
      }`}
    >
      <Square className="text-center cursor-pointer img-container">
        {variant.media.imageAsset && (
          <SanityNextImg
            onClick={handleProductClick}
            asset={variant.media.imageAsset}
            objectFit="contain"
            h100
            width={250}
            blurUp
            alt={[toTitleCase(variant.info.name ?? ""), variant.info.brandName]
              .filter((x) => x)
              .join(" ")}
          />
        )}
      </Square>
      <div className={classes.badges}>
        <ProductBadges
          new={variant.info.new}
          pricing={
            variant.inventory
              ? {
                  discountPercent: variant.inventory.discountPercent ?? 0,
                }
              : undefined
          }
          promoLogoUrl={variant.info.promoLogoUrl}
          promoLogoUrlWidth={36}
          promoLogoClassName={classes.promoLogo}
          badgeClassName="mr-1"
        />
      </div>
      {hideExtraControls !== true && (
        <DesktopOnly className="items-center sm:flex">
          <div
            className={classes.previewControl}
            onClick={() => setShowQuickView(true)}
          >
            <EyeIcon style={{ width: 20 }} />
          </div>
          <WishlistButton
            className={classes.previewControl}
            variantId={variant.info.variantId}
            iconColor={lightGray}
            iconWidth={18}
          />
        </DesktopOnly>
      )}
      <div className="card-separator" />
      <div className="cursor-pointer" onClick={handleProductClick}>
        <ProductLabel
          productLink={url}
          title={variant.info.brandName}
          subtitle={variant.info.name?.toLowerCase()}
          category={variant.info.categoryName}
          mobileCenter={!textLeftXs}
          className={classNames(classes.descriptions, {
            [classes.descriptionsLineXs]: lineXs,
          })}
          ellipses={false}
        />
        {isAvailable && price > 0 ? (
          <ProductPrice
            layout="horizontal"
            layoutXs={verticalPriceXs ? "vertical" : "horizontal"}
            compareAtPrice={variant.inventory?.compareAtPrice}
            price={price}
            small
            mobileCenter={!textLeftXs}
          />
        ) : (
          <Typography
            variant="subtitle2"
            textClassName={`${!textLeftXs ? "text-center" : ""} sm:text-left`}
          >
            {getLabel("product", "out-of-stock")}
          </Typography>
        )}
      </div>
      {showEan && (
        <div className="mt-5" style={{ fontSize: 15 }}>
          <p>{variant.info.variantId}</p>
          <p>{variant.info.productId}</p>
        </div>
      )}
      <DynamicModalLoader
        Component={QuickViewDialog}
        ComponentProps={{
          product,
          zIndex: quickViewZIndex,
        }}
        open={showQuickView}
        onClose={() => setShowQuickView(false)}
      />
    </div>
  )
}

export default ProductGridItem
