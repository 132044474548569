import React from "react"
import { SearchIcon } from "../../../../ui/icons"

interface Props {
  onClick: () => void
  className?: string
}

const SearchPanelButton = ({ onClick, className }: Props) => {
  return <SearchIcon onClick={onClick} className={className} />
}

export default SearchPanelButton
