import { ThemeColor } from "@punks/core"
import classNames from "classnames"
import React from "react"
import { getThemeColor } from "../../../themes/utils"

export type FlexOrientation = "row" | "col"

export interface CustomBoxProps {
  children?: any
  p?: number
  pb?: number
  pt?: number
  pl?: number
  pr?: number
  px?: number
  py?: number
  m?: number
  mb?: number
  mt?: number
  ml?: number
  mr?: number
  mx?: number
  my?: number
  className?: string
  flex?: FlexOrientation
  flexFill?: boolean | number
  flexBasis?: string | number
  flexWrap?: boolean | string
  minHeight?: string | number
  maxHeight?: string | number
  height?: string | number
  minWidth?: string | number
  maxWidth?: string | number
  width?: string | number
  fixed?: boolean
  sticky?: boolean
  top?: string | number
  bottom?: string | number
  left?: string | number
  right?: string | number
  zIndex?: number
  background?: ThemeColor
  backgroundColor?: string
  color?: ThemeColor
  fullWidth?: boolean
  hidden?: boolean
  shrink?: boolean
  transparent?: boolean
  relative?: boolean
  absolute?: boolean
  textLeft?: boolean
  textCenter?: boolean
  textRight?: boolean
  justifyContent?: string
  alignItems?: string
  fontSize?: string | number
  lineHeight?: string
  w100?: boolean
  h100?: boolean
  style?: React.CSSProperties
  cursorPointer?: boolean
  overflowHidden?: boolean
  onClick?: () => void
}

const space = (unit?: number) =>
  unit !== undefined ? `${0.5 * unit}rem` : undefined

export const CustomBox = ({
  children,
  p,
  pt,
  pb,
  pl,
  pr,
  px,
  py,
  m,
  mt,
  mb,
  mr,
  ml,
  mx,
  my,
  className,
  minHeight,
  maxHeight,
  height,
  minWidth,
  maxWidth,
  width,
  flex,
  flexBasis,
  flexFill,
  fixed,
  flexWrap,
  sticky,
  relative,
  absolute,
  top,
  bottom,
  left,
  right,
  zIndex,
  background,
  backgroundColor,
  color,
  fullWidth,
  hidden,
  shrink,
  transparent,
  textCenter,
  textRight,
  textLeft,
  fontSize,
  lineHeight,
  alignItems,
  w100,
  h100,
  style,
  cursorPointer,
  overflowHidden,
  justifyContent,
  onClick,
}: CustomBoxProps) => {
  const ptValue = pt ?? py ?? p
  const pbValue = pb ?? py ?? p
  const plValue = pl ?? px ?? p
  const prValue = pr ?? px ?? p
  const mtValue = mt ?? my ?? m
  const mbValue = mb ?? my ?? m
  const mlValue = ml ?? mx ?? m
  const mrValue = mr ?? mx ?? m

  return (
    <div
      onClick={() => onClick?.()}
      style={{
        paddingTop: space(ptValue),
        paddingBottom: space(pbValue),
        paddingLeft: space(plValue),
        paddingRight: space(prValue),
        marginTop: space(mtValue),
        marginBottom: space(mbValue),
        marginLeft: space(mlValue),
        marginRight: space(mrValue),
        minHeight,
        maxHeight,
        height,
        minWidth,
        maxWidth,
        width,
        top,
        bottom,
        left,
        right,
        zIndex,
        fontSize,
        lineHeight,
        alignItems: alignItems ?? (flex === "row" ? "center" : undefined),
        justifyContent,
        flexBasis,
        backgroundColor:
          backgroundColor ??
          (background ? getThemeColor(background) : undefined),
        color: color ? getThemeColor(color) : undefined,
        flex: flexFill === true ? 1 : (flexFill as number),
        flexWrap: flexWrap === true ? "wrap" : (flexWrap as any),
        ...style,
        display: hidden ? "none !important" : undefined,
      }}
      className={classNames(
        {
          flex: flex,
          "flex-row": flex === "row",
          "flex-column": flex === "col",
          fixed: fixed,
          absolute: absolute,
          relative: relative,
          sticky: sticky,
          "w-full": fullWidth || w100,
          "h-0": shrink,
          "h-full": h100,
          "opacity-0": transparent,
          "text-left": textLeft,
          "text-center": textCenter,
          "text-right": textRight,
          "cursor-pointer": cursorPointer,
          "overflow-hidden": overflowHidden,
        },
        className
      )}
    >
      {children}
    </div>
  )
}
