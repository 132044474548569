import classNames from "classnames"
import React from "react"
import classes from "./CustomRow.module.css"

export type GridItemsAlignment =
  | "flex-start"
  | "center"
  | "flex-end"
  | "stretch"
  | "baseline"

export type GridJustification =
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly"

export interface CustomRowProps {
  children: any
  noGutters?: boolean
  alignItems?: GridItemsAlignment
  justify?: GridJustification
  className?: string
  style?: React.CSSProperties
  h100?: boolean
  spacing?: boolean
  reverse?: boolean
}

export const CustomRow = ({
  children,
  className,
  noGutters,
  h100,
  alignItems,
  justify,
  spacing,
  reverse,
}: CustomRowProps) => {
  return (
    <div
      className={classNames(
        "flex flex-wrap box-border w-full",
        {
          "flex-row-reverse": reverse,
          "h-full": h100,
          "px-0": noGutters,
          [classes.spacing]: spacing,
        },
        className
      )}
      style={{ alignItems, justifyContent: justify }}
    >
      {children}
    </div>
  )
}
