import { CombinedState, ThunkDispatch } from "@reduxjs/toolkit"
import {
  CheckoutDataFragment,
  CheckoutInput,
} from "../../../../api/backend/backend-api"
import {
  checkoutCreate,
  checkoutUpdate,
} from "../../../../api/backend/checkout/api"
import { AppThunk } from "../../../../state/store"
import { clearBasket, saveBasket } from "../../services/persist"
import {
  basketReset,
  updateBasketCompleted,
  updateBasketError,
  updateBasketStarted,
} from "../checkoutSlice"
import { Basket } from "../checkoutTypes"
import { createBasketPayload } from "../converters/checkoutPayload"
import { toBasket } from "../converters/toBasket"
import { toCheckoutInput } from "../converters/toCheckout"
import { RootState } from "../../../../state/rootReducer"

export interface BasketUpdate {
  input: (checkout: CheckoutInput) => CheckoutInput
  callback?: (
    basket: Basket | undefined,
    dispatch?: ThunkDispatch<CombinedState<RootState>, unknown, any>
  ) => void
  errorCallback?: (error: any) => void
}

const invokeBasketOperation = async (
  input: CheckoutInput,
  languageId: string,
  currentBasketId?: string
): Promise<CheckoutDataFragment | undefined> => {
  if (!currentBasketId) {
    const result = await checkoutCreate({
      input,
      languageId,
    })
    if (!result.data?.checkoutCreate.data) {
      throw Error("Empty response")
    }
    return result.data?.checkoutCreate.data
  }

  const result = await checkoutUpdate({
    id: currentBasketId,
    input,
    languageId,
  })
  if (!result.data?.checkoutUpdate.data && input.lineItems.length > 0) {
    throw Error("Empty response")
  }
  return result.data?.checkoutUpdate?.data ?? undefined
}

const createNewCheckout = (): CheckoutInput => ({
  lineItems: [],
  payload: createBasketPayload(),
})

export const basketUpdate = (data: BasketUpdate): AppThunk => async (
  dispatch,
  getState
) => {
  const { input, callback, errorCallback } = data
  const languageId = getState().localization.languageId
  dispatch(updateBasketStarted())

  try {
    const currentBasketId = getState().checkout.basket?.shopify.id
    const currentBasket = getState().checkout.basket
    const currentCheckout = currentBasket
      ? input(toCheckoutInput(currentBasket))
      : input(createNewCheckout())

    const checkout = await invokeBasketOperation(
      currentCheckout,
      languageId,
      currentBasketId
    )

    if (checkout) {
      const basket = toBasket(checkout)
      saveBasket(basket)
      dispatch(updateBasketCompleted(basket))
      callback?.(basket, dispatch)
    } else {
      clearBasket()
      dispatch(basketReset())
      callback?.(undefined, dispatch)
    }
  } catch (err) {
    dispatch(updateBasketError(err.toString()))
    errorCallback?.(err)
    console.error("Error updating basket", input, err)
  }
}
