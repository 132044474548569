import { readLastPopupState, saveLastPopupState } from "./store"

const POPUP_DELAY_SECONDS = 60 * 5

export const saveLastPopupActivationTimestamp = (popupId: string) =>
  saveLastPopupState({
    lastActivationTimestamp: new Date().getTime(),
    popupId,
  })

export const isPassedEnoughBetweenLastPopup = () => {
  const lastPopupState = readLastPopupState()
  if (!lastPopupState) {
    return true
  }

  const now = new Date().getTime()
  return (
    now - lastPopupState.lastActivationTimestamp > POPUP_DELAY_SECONDS * 1000
  )
}
