import {
  readObject,
  removeObject,
  writeObject,
} from "../../../root/punks/utils/storage"

export interface GameState {
  gameId: string
  playedTimestamp: number
}

const GAME_STATE_KEY = "_GameState"

export const saveGameState = (value: GameState) =>
  writeObject(GAME_STATE_KEY, value)

export const readGameState = () =>
  readObject(GAME_STATE_KEY) as GameState | undefined

export const deleteGameState = () => removeObject(GAME_STATE_KEY)
