import React from "react"
import { useToasts } from "react-toast-notifications"
import { useAppDispatch, useAppSelector } from "../../../../state/store"
import { useLabels } from "../../../contents/hooks"
import { resetPasswordReset } from "../../state/passwordReset/resetPasswordSlice"

const PwdResetNotification = () => {
  const { addToast } = useToasts()
  const { passwordResetSuccess } = useAppSelector(
    (state) => state.resetPassword
  )
  const dispatch = useAppDispatch()
  const { getLabel } = useLabels()

  React.useEffect(() => {
    if (passwordResetSuccess) {
      addToast(getLabel("account", "pwd-reset-success"), {
        autoDismiss: true,
        appearance: "success",
      })
      dispatch(resetPasswordReset())
    }
  }, [passwordResetSuccess])

  return <></>
}

export default PwdResetNotification
