import { useDispatch } from "react-redux"
import { useRootContents } from "../../../aggregators/rootData"
import { useAppSelector } from "../../../state/store"
import { useBasket } from "../../checkout/state/hooks"
import { useJob } from "../../navigation/hooks/useJob"
import { triggerPopup } from "../state/popupSlice"
import { evaluatePopupActivation } from "./activators"
import { saveLastPopupState } from "./guards/modals-distance/store"
import { usePopupGuardsEvaluator } from "./guards/usePopupGuardsEvaluator"

const POPUP_CHECK_INTERVAL_SECONDS = 5

export const useMarketingPopupTriggers = () => {
  const { promoBanner, gameModals, layout } = useRootContents()

  const newsletterBanner = layout?.data?.body?.newsletterBanner

  const basket = useBasket()
  const { account, marketingProfile } = useAppSelector((state) => state.user)
  const { triggeredPopup } = useAppSelector((state) => state.popup)
  const session = useAppSelector((state) => state.session)
  const dispatch = useDispatch()

  const { evaluateGuards } = usePopupGuardsEvaluator()

  const evaluateTriggers = async () => {
    const guardCondition = evaluateGuards()
    if (!guardCondition.passed) {
      console.log("Popup guard condition failed", guardCondition.condition)
      return
    }

    const popup = await evaluatePopupActivation({
      newsletterBanner,
      gameModals,
      promoBanner,
      basket,
      account,
      marketingProfile,
      session,
    })

    if (popup) {
      console.log("popup triggered", popup)
      dispatch(triggerPopup(popup))
      saveLastPopupState({
        lastActivationTimestamp: new Date().getTime(),
        popupId: popup.data.id,
      })
    }
  }

  useJob({
    active: !triggeredPopup,
    callback: () => {
      evaluateTriggers()
    },
    interval: 1000 * POPUP_CHECK_INTERVAL_SECONDS,
    startImmediately: true,
  })
}
