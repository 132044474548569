import { useRouter } from "next/router"
import React from "react"
import AlgoliaFullTextSearch from "./AlgoliaFullTextSearch"
import AlgoliaFullTextSearchController from "./components/AlgoliaFullTextSearchController"

interface Props {
  onClose: () => void
  term: string
}

const AlgoliaFullTextSearchWrapper = ({ onClose, term }: Props) => {
  const router = useRouter()

  React.useEffect(() => {
    router.events.on("routeChangeStart", onClose)

    return () => {
      router.events.off("routeChangeStart", onClose)
    }
  }, [])

  return (
    <AlgoliaFullTextSearchController term={term}>
      <AlgoliaFullTextSearch onClose={onClose} />
    </AlgoliaFullTextSearchController>
  )
}

export default AlgoliaFullTextSearchWrapper
