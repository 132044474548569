import * as React from "react"
import { IconProps } from "./types"

export const BasketIcon = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.145"
    height="21.066"
    viewBox="0 0 27.145 21.066"
    {...props}
  >
    <g id="Basket" data-name="Basket" transform="translate(-136.464 -52.348)">
      <path
        id="Tracciato_151"
        data-name="Tracciato 151"
        d="M1320.676,120.724l-1.426,10.788a4.066,4.066,0,0,0,4.041,4.511H1340.8a4.065,4.065,0,0,0,4.041-4.511l-1.425-10.788Z"
        transform="translate(-1182.009 -63.359)"
        fill="none"
        stroke="#000"
        strokeWidth="1.5"
      />
      <path
        id="Tracciato_152"
        data-name="Tracciato 152"
        d="M1337.157,124.971v-3.4a5.112,5.112,0,1,0-10.224,0v3.4"
        transform="translate(-1182.009 -63.359)"
        fill="none"
        stroke="#000"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

// createSvgIcon(
//   <g>
//     <path
//       d="M0.571428571,19.4285714 L20,19.4285714 C20.7619048,19.4285714 20.7619048,20.5714286 20,20.5714286 L0.571428571,20.5714286 C-0.19047619,20.5714286 -0.19047619,19.4285714 0.571428571,19.4285714 Z"
//       id="path"
//     ></path>
//     <path
//       d="M2.90633652,5.71428571 L1.16492035,19.645615 C1.15022577,19.7631717 1.14285714,19.8815285 1.14285714,20 C1.14285714,21.5779564 2.42204357,22.8571429 4,22.8571429 L16.8037791,22.8571429 C16.9222506,22.8571429 17.0406074,22.8497742 17.158164,22.8350796 C18.7239353,22.6393582 19.8345801,21.2113863 19.6388587,19.645615 L17.8974426,5.71428571 L2.90633652,5.71428571 Z M2.40188954,4.57142857 L18.4018895,4.57142857 C18.6900743,4.57142857 18.9331606,4.78602082 18.9689055,5.07198015 L20.7728906,19.5038611 C21.0469005,21.6959408 19.4919978,23.6951015 17.299918,23.9691115 C17.1353387,23.9896839 16.9696392,24 16.8037791,24 L4,24 C1.790861,24 0,22.209139 0,20 C0,19.8341399 0.0103160799,19.6684404 0.0308884931,19.5038611 L1.83487361,5.07198015 C1.87061852,4.78602082 2.11370481,4.57142857 2.40188954,4.57142857 Z"
//       id="path"
//     ></path>
//     <path
//       d="M6.28934152,8.57142857 C6.28934152,8.88701986 6.03350423,9.14285714 5.71791295,9.14285714 C5.40232166,9.14285714 5.14648438,8.88701986 5.14648438,8.57142857 L5.14648438,4 C5.14648438,1.790861 6.93734538,0 9.14648438,0 L11.4321987,0 C13.6413377,0 15.4321987,1.790861 15.4321987,4 L15.4321987,8.57142857 C15.4321987,8.88701986 15.1763614,9.14285714 14.8607701,9.14285714 C14.5451788,9.14285714 14.2893415,8.88701986 14.2893415,8.57142857 L14.2893415,4 C14.2893415,2.42204357 13.0101551,1.14285714 11.4321987,1.14285714 L9.14648438,1.14285714 C7.56852795,1.14285714 6.28934152,2.42204357 6.28934152,4 L6.28934152,8.57142857 Z"
//       id="path"
//     ></path>
//   </g>,
//   "Basket"
// )
