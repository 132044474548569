import * as React from "react"
import { IconProps } from "./types"

export const ArrowUpIcon = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.894"
      height="17.446"
      viewBox="0 0 10.894 17.446"
      {...props}
    >
      <g
        id="Raggruppa_870"
        data-name="Raggruppa 870"
        transform="translate(-3479.172 1551.183) rotate(-90)"
      >
        <g
          id="Raggruppa_869"
          data-name="Raggruppa 869"
          transform="translate(1533.737 3479.172)"
        >
          <path
            id="Tracciato_495"
            data-name="Tracciato 495"
            d="M1549.742,3486.069l-4.016,4-1.42-1.42,2.981-3h-13.551v-2.028h13.551l-2.981-3,1.42-1.44,5.457,5.457Z"
            transform="translate(-1533.737 -3479.172)"
          />
        </g>
      </g>
    </svg>
  )
}
