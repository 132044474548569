import React from "react"
import { FaFacebookF, FaInstagram } from "react-icons/fa"
import FooterSocialSection from "../FooterSocialSection"
import styles from "./css/index.module.css"
import Typography from "../../../atoms/Typography"
import FooterNewsletter from "../FooterNewsletter"
import CustomFooterColumnItem from "./CustomFooterColumnItem"
import FooterBottom from "./FooterBottom"

interface Props {
  data: any
}

const LogoInverted = () => {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fill: "#FFFFFF",
      }}
      viewBox="0 0 1129.9 328.1"
      y="0px"
      x="0px"
      version="1.1"
    >
      <g>
        <g>
          <path d="M372.7,15.6c-56.8,0-102.9,45.8-102.9,102.6c0,56.8,46.1,102.6,102.9,102.6c56.5,0,102.6-45.8,102.6-102.6    C475.3,61.4,429.2,15.6,372.7,15.6z M410.2,208.3c-43,18.3-94.9-7.3-115.7-57.4c-21.1-49.8-3.1-104.7,40.3-122.7    c43-18.3,94.9,7.6,115.7,57.4C471.6,135.3,453.6,190.3,410.2,208.3z" />
          <path d="M584.5,15.7c-15.6-0.3-36,0-62,0v205.5c15,0,42.4,0.3,62,0c47-0.9,69.3-19.2,78.2-28.4    c15.6-16.5,24.7-39.7,25.6-70.8c0.6-28.4-6.4-57.7-25.6-77.8C653.8,35,631.5,16.6,584.5,15.7z M580.8,216h-40.3V20.9h40.3    c69.6,0,89.1,46.7,89.5,97.4C670,169.3,650.5,216,580.8,216z" />
          <path d="M879,15.7l0.3,129.1c0,40.6-21.1,70.8-62.9,70.8c-38.5,0-61.4-29-61.4-70.8V15.7h-17.4V143    c0,94.3,129.1,100.7,152.3,32.4c6.7-19.5,6.1-20.5,6.1-159.7H879z" />
          <path d="M1083.7,121.6c-5.3-3.9-11.1-7-17.4-9.3c-6.3-2.3-12.8-4.2-19.5-5.5c-6.7-1.3-13.4-2.4-20.1-3.4    c-6.7-0.9-13.2-1.9-19.5-2.9c-7.1-1.4-13.5-3.4-19.1-6c-5.6-2.5-10-5.7-13.3-9.6c-3.3-3.9-5-8.5-5.2-13.9    c-0.2-5.4,1.4-11.6,4.9-18.8c2.2-4.5,5.3-8.7,9.2-12.7c3.9-4,8.6-7.4,14.2-10.2c5.6-2.8,12-5,19.2-6.6c7.2-1.5,15.4-2.1,24.6-1.7    c6.7,0.4,12.8,1.6,18.3,3.5c5.5,1.9,10.4,4.3,14.8,7c4.4,2.7,8.2,5.8,11.6,9c3.4,3.3,6.2,6.2,8.4,8.9l11.6-11.6    c-10-6.9-20-12.2-30.2-15.7c-10.2-3.6-20.1-5.6-29.9-6.3c-9.8-0.6-19.1,0-27.9,1.8c-8.9,1.8-16.9,4.6-24.3,8.4    c-7.3,3.8-13.8,8.3-19.5,13.6c-5.7,5.3-10.1,11.1-13.1,17.4c-2.4,5.3-4,10.5-4.7,15.6c-0.7,5.1-0.5,10,0.8,14.7    c1.2,4.7,3.5,9.1,6.7,13.1c3.3,4.1,7.8,7.5,13.7,10.4c6.5,3.3,14,5.8,22.6,7.5c8.5,1.7,17.2,3.3,25.9,4.7c8.7,1.4,17.1,3,25.2,4.7    c8,1.7,14.9,4.3,20.6,7.8c5.7,3.5,9.7,8,12.1,13.7c2.3,5.7,2.1,13.1-0.8,22.3c-1.6,5.5-4.6,10.8-8.9,15.9    c-4.3,5.1-9.5,9.7-15.6,13.7c-6.1,4.1-13,7.3-20.6,9.8c-7.6,2.4-15.7,3.8-24.3,4c-12,0.4-24.3-2.2-36.9-7.8    c-12.6-5.6-24.9-16-36.9-31.3l-13.4,12.2c14,10.8,27.8,18.7,41.2,23.8c13.4,5.1,26.2,7.9,38.3,8.6c12.1,0.6,23.4-0.6,33.9-3.7    c10.5-3.1,19.7-7.3,27.8-12.7c8-5.4,14.7-11.7,20-18.8c5.3-7.1,8.8-14.5,10.5-22.1c1.7-7.6,1.4-15.1-0.9-22.3    C1095.2,133.8,1090.6,127.3,1083.7,121.6z" />
          <polygon points="197.4,15.8 131.1,205.7 64.5,15.8 51.2,15.8 23.6,221.3 30.6,221.3 53.2,46.5 115.7,221.2     133.5,221.2 196,41.1 219.8,221.2 235.4,221.2 207.8,15.8" />
          <path d="M341.7,273c-4.2-2.6-8.6-2.6-18.1-2.5h-8.5v44.3h4.4v-18.3h15.3c7.2,0,13-5.8,13-13    C347.8,279.2,345.6,275.3,341.7,273z M343.4,283.8c-0.2,6.5-5.3,11.1-12.5,11.1h-11.4v-22.8c1.3,0,2.5,0,3.6,0    c9.2,0,11.3,0,14.5,1.7C341.3,275.7,343.4,279.4,343.4,283.8z" />
          <path d="M390.8,296.4c6.4-0.8,11.2-6.3,11.2-12.9c0-4.3-2.3-8.2-6.2-10.5c-4-2.4-7.9-2.5-17.3-2.5h-9.3v44.3h4.4    v-18.3l8.9,0c3.5,0.5,12.1,3.3,15.1,18l0.1,0.3h4.5l0-0.4C401.2,306.6,396.9,299.8,390.8,296.4z M391.8,273.7    c3.6,1.9,5.7,5.7,5.6,10.1c-0.1,6.4-5.2,10.9-12.4,10.9h-11.4v-22.4c2.2,0,4,0,5.6,0C386.8,272.2,388.9,272.2,391.8,273.7z" />
          <path d="M445.6,270.5c-12.2,0-22.2,9.9-22.2,22.1c0,12.2,10,22.1,22.2,22.1c12.2,0,22.1-9.9,22.1-22.1    C467.8,280.5,457.8,270.5,445.6,270.5z M453.5,311.5c-3.9,1.7-8.3,1.6-12.5-0.1c-5.1-2.1-9.3-6.4-11.6-11.9    c-2.3-5.5-2.5-11.5-0.4-16.6c1.7-4.2,4.8-7.4,8.7-9c1.9-0.8,3.9-1.2,6-1.2c2.1,0,4.3,0.4,6.4,1.3c5.1,2.1,9.3,6.4,11.6,11.9v0    c2.3,5.5,2.4,11.5,0.4,16.6C460.5,306.6,457.4,309.8,453.5,311.5z" />
          <path d="M491,314.7h4.4v-18.6l17.9,0.1v-1.9h-17.9v-22H509c3.8,0,11.6,2,11.7,2.1l0.4,0.1v-4H491V314.7z" />
          <path d="M571.3,270.5h-4.3l0.1,27.8c0,9.2-4.9,14.7-13,14.7c-7.7,0-12.7-5.8-12.7-14.7v-27.8h-4.4v27.4    c0,10.6,7.3,15.5,14.1,16.7c1.1,0.2,2.2,0.3,3.3,0.3c6.5,0,13.4-3.1,15.7-9.9c1.3-3.9,1.3-4.3,1.3-27.2L571.3,270.5z" />
          <path d="M654.1,314.8h30v-4.1l-0.4,0.1c-0.1,0-7.8,2.2-11.6,2.2h-13.5v-21.9h17.9v-1.9h-17.9v-17.1H672    c3.8,0,11.6,2,11.7,2.1l0.4,0.1v-4h-30.1V314.8z" />
          <path d="M726.4,296.4c6.4-0.8,11.2-6.3,11.2-12.9c0-4.3-2.3-8.2-6.2-10.5c-4-2.4-7.9-2.5-17.3-2.5h-9.3v44.3h4.4    v-18.3l8.9,0c3.5,0.5,12.1,3.3,15.1,18l0.1,0.3h4.5l0-0.4C736.8,306.6,732.5,299.8,726.4,296.4z M733.1,283.8    c-0.1,6.4-5.2,10.9-12.4,10.9h-11.4v-22.4c2.2,0,4,0,5.6,0c7.6-0.1,9.6-0.1,12.5,1.5C731,275.6,733.1,279.4,733.1,283.8z" />
          <rect height="44.3" width="4.4" y="270.5" x="759.5" />
          <path d="M784.7,314.8h30v-4.1l-0.4,0.1c-0.1,0-7.8,2.2-11.6,2.2h-13.5v-21.9H807v-1.9h-17.9v-17.1h13.5    c3.8,0,11.6,2,11.7,2.1l0.4,0.1v-4h-30.1V314.8z" />
          <polygon points="627.1,270.3 613.2,310 599.3,270.3 595.9,270.3 590,314.7 592.2,314.7 596.8,278.6 609.7,314.7     614,314.7 626.9,277.5 631.8,314.7 635.9,314.7 629.9,270.3" />
        </g>
      </g>
    </svg>
  )
}

const FooterDesktop = ({ data }: Props) => {
  const middleSections: any[] = []
  const bottomLeftSections: any[] = []
  const bottomRightSections: any[] = []
  // data.sections
  data.sections?.forEach((x: any) => {
    if (x.position === "middle") {
      middleSections.push(x)
    } else if (x.position === "bottom-left") {
      bottomLeftSections.push(x)
    } else if (x.position === "bottom-right") {
      bottomRightSections.push(x)
    }
  })
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={styles.logo}>
          <LogoInverted />
        </div>
        <div className={styles.newsletter}>
          <FooterNewsletter />
        </div>
        <div className={styles.box}>
          <div className={styles.boxSections}>
            {middleSections?.map((section: any, sectionIndex: number) => (
              <div
                key={sectionIndex}
                style={{ flex: 1 + (section.columns?.length ?? 0) * 0.75 }}
              >
                <Typography
                  weight="boldest"
                  variant="body2"
                  uppercase
                  className="mb-2"
                >
                  {section.title}
                </Typography>
                <div className="flex flex-column">
                  {section.columns?.map((column: any, columnIndex: number) => (
                    <div key={columnIndex} className="mr-6">
                      {column.items.map((item: any) => (
                        <CustomFooterColumnItem key={item._key} item={item} />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.socialSection}>
            <FooterSocialSection
              justifyEnd
              social={{
                items: [
                  {
                    icon: <FaFacebookF />,
                    url: data.social?.facebookUrl?.target,
                  },
                  {
                    icon: <FaInstagram />,
                    url: data.social?.instagramUrl?.target,
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomInner}>
          <FooterBottom
            data={data}
            leftSection={bottomLeftSections}
            rightSection={bottomRightSections}
          />
        </div>
      </div>
    </footer>
  )
}

export default FooterDesktop
