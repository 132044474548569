import React from "react"
import AlgoliaFullTextSearchWrapper from "../AlgoliaFullTextSearch"

interface Props {
  onClose: () => void
  term: string
}

const AlgoliaFullTextSearchPanel = ({ onClose, term }: Props) => {
  return (
    <div className="pb-16 bg-white" style={{ minHeight: "calc(100vh - 95px)" }}>
      <AlgoliaFullTextSearchWrapper term={term} onClose={onClose} />
    </div>
  )
}

export default AlgoliaFullTextSearchPanel
