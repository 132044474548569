import { deleteCookie, readCookie } from "../../../../root/punks/utils/cookies"

export const createBasketPayload = () => {
  try {
    const promo = readCookie("campaignPromo")
    deleteCookie("campaignPromo")
    return {
      awin: {
        cks: readCookie("awinCks"),
        source: readCookie("source"),
      },
      abiby: {
        uid: readCookie("__abb__uid"),
      },
      campaign: {
        promo,
      },
    }
  } catch (e) {
    console.error("Error creating basket payload", e)
    return undefined
  }
}
