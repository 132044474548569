import { toLinkField } from "../../../../../../containers/editorialPage/converters/link"
import { toRgba } from "../../../../../../converters/sanity"
import { PromoBarProps } from "../../../../../../ui/organisms/PromoBar"

export const toPromoBarProps = (data: any): PromoBarProps => ({
  backgroundColor: data.backgroundColor?.rgb
    ? toRgba(data.backgroundColor.rgb)
    : undefined,
  items:
    data.items?.map((x: any) => ({
      ...x,
      text: x.text,
      fontColor: x.textColor?.rgb ? toRgba(x.textColor.rgb) : undefined,
      link: x.link ? toLinkField(x.link) : undefined,
    })) ?? [],
})
