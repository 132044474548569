import React from "react"
import { useDebouncedCallback } from "use-debounce"
import { useLabels } from "../../../../../../features/contents/hooks"
import { ModalCloseIcon } from "../../../../../../ui/icons/ModalCloseIcon"
import { SearchIcon2 } from "../../../../../../ui/icons/SearchIcon"
import HeadSearchPopover from "../../../HeadSearchPanel"
import classes from "./HeaderSearchBar.module.css"

interface Props {
  clear?: boolean
}

const HeaderSearchBar = ({ clear }: Props) => {
  const { getLabel } = useLabels()
  const anchor = React.useRef<HTMLElement>(null)
  const [term, setTerm] = React.useState("")
  const [query, setQuery] = React.useState("")

  const debounced = useDebouncedCallback((value: string) => {
    setQuery(value)
  }, 100)

  const clearSearch = () => {
    setTerm("")
    setQuery("")
  }

  const updateSearch = (term: string) => {
    setTerm(term)
    debounced(term)
  }

  React.useEffect(() => {
    if (clear) {
      clearSearch()
    }
  }, [clear])

  const panelOpen = !!query

  return (
    <>
      <div ref={anchor as any} className={classes.root}>
        {panelOpen ? (
          <ModalCloseIcon
            className="cursor-pointer"
            width={12}
            onClick={() => clearSearch()}
          />
        ) : (
          <SearchIcon2 width={16} />
        )}
        <input
          placeholder={getLabel("common", "search-bar")}
          className={classes.input}
          value={term}
          onChange={(e) => updateSearch(e.target.value)}
        />
      </div>
      {anchor?.current && (
        <HeadSearchPopover
          anchor={anchor.current}
          open={panelOpen}
          onClose={() => clearSearch()}
          query={query}
          topOffset={8}
        />
      )}
    </>
  )
}

export default HeaderSearchBar
