import classNames from "classnames"
import React from "react"
import { imageUrl } from "../../services/images"

export interface SanityImgProps {
  asset: any
  srcImgWidth?: number
  width?: number
  height?: number
  imgWidth?: string | number
  imgHeight?: string | number
  blur?: number
  fluid?: boolean
  className?: string
  h100?: boolean
  style?: React.CSSProperties
  alt?: string
}

export const SanityImg = ({
  asset,
  fluid,
  className,
  h100,
  imgHeight,
  imgWidth,
  style,
  alt,
  width,
  srcImgWidth,
  ...options
}: SanityImgProps) => {
  const url = imageUrl(asset, {
    ...options,
    width: srcImgWidth,
  })
  return url ? (
    <img
      className={classNames(
        {
          "max-w-full max-h-full h-auto": fluid,
        },
        className
      )}
      src={url}
      height={h100 ? "100%" : imgHeight}
      width={imgWidth ?? width}
      style={style}
      alt={alt}
    />
  ) : (
    <></>
  )
}
