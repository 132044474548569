import React from "react"
import MenuSectionItem, { MenuListItem } from "./MenuSectionItem"

interface MenuSectionProps {
  items: MenuListItem[]
  leftPadding?: number
  narrow?: boolean
  className?: string
}

const MenuSection = ({
  items,
  leftPadding,
  narrow,
  className,
}: MenuSectionProps) => {
  return (
    <nav className={`py-2 ${className ?? ""}`}>
      {items.map((item, index) => (
        <MenuSectionItem
          key={index}
          item={item}
          leftPadding={leftPadding}
          narrow={narrow}
        />
      ))}
    </nav>
  )
}

export default MenuSection
