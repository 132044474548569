import classNames from "classnames"
import React from "react"
import { useEventListener } from "../../../features/navigation/hooks/useEventListener"
import { useDetectClickOutside } from "../../../features/ui/hooks/useDetectClickOutside"
import { useDisableScroll } from "../../../features/ui/hooks/useDisableScroll"
import classes from "./CustomPopover.module.css"

export type PopoverPosition = "top" | "bottom"

export interface ContentParams {
  topOffset?: number
  className?: string
  style?: React.CSSProperties
}

export interface CustomPopoverProps {
  children: any
  open: boolean
  onClose: () => void
  anchor: HTMLElement
  position: PopoverPosition
  contentParams?: ContentParams
  excludedCloseElements?: HTMLElement[]
  zIndex: number
  disableScroll?: boolean
}

const CustomPopover = ({
  children,
  open,
  onClose,
  anchor,
  position,
  contentParams,
  excludedCloseElements,
  zIndex,
  disableScroll,
}: CustomPopoverProps) => {
  useDisableScroll((disableScroll ?? false) && open)
  const [y, setY] = React.useState<number>()

  const handleClose = () => {
    if (open) {
      onClose()
    }
  }

  const rootRef = useDetectClickOutside({
    onTriggered: handleClose,
    excludedElements: excludedCloseElements,
  })

  const updateY = () =>
    setY(
      anchor.getBoundingClientRect().y + anchor.getBoundingClientRect().height
    )

  React.useEffect(() => {
    updateY()
  }, [])

  const handleScroll = () => {
    setTimeout(() => updateY(), 100)
    setTimeout(() => updateY(), 200)
    setTimeout(() => updateY(), 250)
  }

  React.useEffect(() => {
    if (anchor) {
      updateY()
    }
  }, [anchor?.getBoundingClientRect().y])

  useEventListener("scroll", handleScroll)

  const top = (y ?? 0) + (contentParams?.topOffset ?? 0)

  return (
    <div
      ref={rootRef}
      style={{
        ["--z" as any]: zIndex,
        ["--anchor-y" as any]: `${top}px`,
        ["--max-h" as any]: `calc(100vh - ${top}px)`,
        // ["--top-offset" as any]: contentParams?.topOffset
        //   ? `${contentParams.topOffset}px`
        //   : undefined,
      }}
      className={classNames(classes.root, {
        [classes.open]: open && y !== undefined,
        [classes.top]: position === "top",
        [classes.bottom]: position === "bottom",
      })}
    >
      <div
        style={contentParams?.style}
        className={`${classes.content} ${contentParams?.className ?? ""}`}
      >
        {children}
      </div>
    </div>
  )
}

CustomPopover.defaultProps = {
  zIndex: 100,
}

export default CustomPopover
