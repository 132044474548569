import { SearchClient } from "algoliasearch"

export const PRODUCTS_INDEX_NAME = "modus_products"
export const BRANDS_INDEX_NAME = "modus_brands"
export const CATEGORIES_INDEX_NAME = "modus_categories"

let client: SearchClient

export const getAlgoliaClient = (): SearchClient => {
  if (!client) {
    const algoliasearch: any = require("algoliasearch") // eslint-disable-line
    client = algoliasearch(
      process.env.ALGOLIA_APPLICATION_ID,
      process.env.ALGOLIA_SEARCH_API_KEY
    )
  }
  return client
}
