import React from "react"
import { fullTextSearch } from "../../services/search"
import {
  AlgoliaFullTextSearchContext,
  AlgoliaFullTextSearchResults,
} from "../AlgoliaFullTextSearchContext"

interface Props {
  children: any
  term: string
}

const TOT_BRANDS = 6
const TOT_PRODUCTS = 12
const TOT_CATEGORIES = 12

const AlgoliaFullTextSearchController = ({ children, term }: Props) => {
  // const [query, setQuery] = React.useState("")
  const [results, setResults] = React.useState<AlgoliaFullTextSearchResults>()

  const search = async (query: string) => {
    const results = await fullTextSearch({
      query,
      totBrands: TOT_BRANDS,
      totCategories: TOT_CATEGORIES,
      totProducts: TOT_PRODUCTS,
      timestamp: new Date(),
    })
    setResults({
      products: results.results[0]?.hits ?? [],
      brands: results.results[1]?.hits ?? [],
      categories: results.results[2]?.hits ?? [],
      noResults:
        results.results[0]?.hits.length === 0 &&
        results.results[1]?.hits.length === 0 &&
        results.results[2]?.hits.length === 0,
      hasMoreProducts: results.results[0]?.nbHits > TOT_PRODUCTS,
    })
    console.log("results", results)
  }

  React.useEffect(() => {
    if (term) {
      search(term)
    }
  }, [term])

  // const handleQueryUpdate = useDebouncedCallback((query: string) => {
  //   setQuery(query)
  //   search(query)
  // }, SEARCH_DELAY_MS)

  return (
    <AlgoliaFullTextSearchContext.Provider
      value={{
        query: term,
        // onQueryUpdate: handleQueryUpdate,
        results,
      }}
    >
      {children}
    </AlgoliaFullTextSearchContext.Provider>
  )
}

export default AlgoliaFullTextSearchController
