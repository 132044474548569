import Link from "next/link"
import React from "react"
import CustomButton from "../../../../ui/atoms/Button"
import Typography from "../../../../ui/atoms/Typography"
import classes from "./SearchResultTextSection.module.css"

export interface TextResult {
  url: string
  label: string
}

interface Props {
  className?: string
  title: string
  titleClassName?: string
  items: TextResult[]
}

const SearchResultTextSection = ({
  className,
  title,
  titleClassName,
  items,
}: Props) => {
  return (
    <div className={className}>
      <Typography textClassName={titleClassName}>{title}</Typography>
      <div className={classes.items}>
        {items.map((x, key) => (
          <div key={key} className={classes.linkContainer}>
            <Link href={x.url} passHref>
              <a className={classes.link}>
                <CustomButton variant="text">{x.label}</CustomButton>
              </a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SearchResultTextSection
