import { useRouter } from "next/router"
import { useCallback } from "react"
import { useAppSelector } from "../../../../state/store"
import { isGameInProgress } from "./is-game-in-progress"
import { isInCheckout } from "./is-in-checkout"
import { isWindowActive } from "./is-window-active"
import { hasModalConflicts } from "./modals-conflicts"
import { isPassedEnoughBetweenLastPopup } from "./modals-distance"

export type GuardEvaluationError =
  | "window-inactive"
  | "invalid-section"
  | "modal-conflict"
  | "not-enough-time"
  | "game-in-progress"

export interface GuardsEvaluationResult {
  passed: boolean
  condition?: GuardEvaluationError
}

export const usePopupGuardsEvaluator = () => {
  const router = useRouter()
  const uiState = useAppSelector((state) => state.ui)
  const gamesState = useAppSelector((state) => state.games)

  const evaluateGuards = useCallback((): GuardsEvaluationResult => {
    if (!isWindowActive()) {
      return {
        passed: false,
        condition: "window-inactive",
      }
    }

    if (isInCheckout(router.pathname)) {
      return {
        passed: false,
        condition: "invalid-section",
      }
    }

    if (isGameInProgress(gamesState)) {
      return {
        passed: false,
        condition: "game-in-progress",
      }
    }

    if (hasModalConflicts(uiState)) {
      return {
        passed: false,
        condition: "modal-conflict",
      }
    }

    if (!isPassedEnoughBetweenLastPopup()) {
      return {
        passed: false,
        condition: "not-enough-time",
      }
    }

    return {
      passed: true,
    }
  }, [])

  return {
    evaluateGuards,
  }
}
