import React from "react"

export type SlideFrom = "left" | "right" | "top" | "bottom"

export interface SlideProps {
  children: any
  in: boolean
  from: SlideFrom
  className?: string
  style?: React.CSSProperties
  timeout: number
  jsHidden?: boolean
  displayNone?: boolean
  inDelay?: number
}

const translateTransform = (from: SlideFrom) => {
  switch (from) {
    case "left":
      return "translateX(-100vw)"
    case "right":
      return "translateX(100vw)"
    case "top":
      return "translateY(100vh)"
    case "bottom":
      return "translateY(-100vh)"
  }
}

export const Slide = ({
  className,
  in: open,
  from,
  children,
  style,
  timeout,
  jsHidden,
  displayNone,
  inDelay,
}: SlideProps) => {
  const [effectIn, setEffectIn] = React.useState(false)

  React.useEffect(() => {
    if (!inDelay) {
      setEffectIn(open)
    } else {
      setTimeout(() => {
        setEffectIn(open)
      }, inDelay)
    }
  }, [open])

  if (!open && jsHidden) {
    return <></>
  }
  return (
    <div
      className={className}
      style={{
        visibility: effectIn ? undefined : "hidden",
        transition: `transform ${timeout}ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
        transform: effectIn ? "none" : translateTransform(from),
        ...style,
        display: displayNone ? "none" : undefined,
      }}
    >
      {children}
    </div>
  )
}

Slide.defaultProps = {
  from: "left" as SlideFrom,
  timeout: 225,
}

export default Slide
