import classNames from "classnames"
import React from "react"
import { TwRichTextField } from "../../../../root/punks/core/fields/RichTextField"
import Typography from "../../../atoms/Typography"

interface Props {
  companyInfo: any
  classes?: {
    container?: string
    copyright?: string
    companyInfo?: string
  }
}

const FooterCopyright = ({ companyInfo, classes }: Props) => {
  return (
    <div className={classNames(classes?.container ?? "mt-6")}>
      <Typography
        lineHeightValue="18px"
        variant="body1"
        className={classNames(classes?.copyright)}
      >
        Copyright © {new Date().getFullYear()}
      </Typography>
      <TwRichTextField
        value={companyInfo}
        className={classNames(classes?.companyInfo ?? "mt-2")}
      />
    </div>
  )
}

export default FooterCopyright
