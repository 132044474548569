import { useRouter } from "next/router"
import React from "react"
import {
  profileInfoPagePath,
  profilePagePath,
} from "../../../../root/routing/urls"
import { useAppDispatch } from "../../../../state/store"
import { AccountIcon } from "../../../../ui/icons"
import { useIsMobile } from "../../../layout/hooks"
import { updateLoginDialogState } from "../../../ui/state/uiActions"
import { useAppUser } from "../../state/userHooks"
import classes from "./AccountLink.module.css"

interface Props {
  className?: string
}

const AccountLink = ({ className }: Props) => {
  const { isAuthenticated, userProfile } = useAppUser()
  const isMobile = useIsMobile()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const handleClick = () => {
    if (!isAuthenticated) {
      dispatch(updateLoginDialogState(true))
    } else {
      router.push(isMobile ? profilePagePath() : profileInfoPagePath())
    }
  }
  return (
    <>
      {isAuthenticated ? (
        <div className="cursor-pointer" onClick={handleClick}>
          <div className={classes.hello}>Ciao</div>
          <div className={classes.name}>
            {userProfile?.firstName.split(" ")[0]}
          </div>
        </div>
      ) : (
        <AccountIcon onClick={handleClick} className={className} />
      )}
    </>
  )
}

export default AccountLink
