import { addHours, addSeconds } from "date-fns"
import { SessionState } from "../../../../session/state/sessionTypes"
import { MarketingProfile } from "../../../../user/state/userTypes"
import {
  deleteNewsletterPopupState,
  NewsletterPopupState,
  readNewsletterPopupState,
  saveNewsletterPopupState,
} from "./store"

const DEFAULT_POPUP_RESET_HOURS = 24
const DEFAULT_DELAY_MINUTES = 1

const isAlreadySubscribed = (profile: MarketingProfile | undefined) => {
  return profile?.subscribedToNewsletter
}

const shouldReset = (state: NewsletterPopupState) => {
  return new Date().getTime() > state.nextResetTimestamp
}

const shouldTrigger = (bannerDefinition: any, session: SessionState) => {
  const validFrom = addSeconds(
    session.startTimestamp,
    (bannerDefinition?.data?.trigger?.delayMinutes ?? DEFAULT_DELAY_MINUTES) *
      60
  )
  return new Date().getTime() > validFrom.getTime()
}

export const evaluateNewsletterBanner = async (
  bannerDefinition: any,
  profile: MarketingProfile | undefined,
  session: SessionState
) => {
  if (bannerDefinition?.data?.info?.disabled) {
    return undefined
  }

  if (isAlreadySubscribed(profile)) {
    return undefined
  }

  const state = readNewsletterPopupState()
  if (!state && shouldTrigger(bannerDefinition, session)) {
    saveNewsletterPopupState({
      lastActivationTimestamp: new Date().getTime(),
      nextResetTimestamp: addHours(
        new Date(),
        bannerDefinition?.trigger?.resetAfterHours ?? DEFAULT_POPUP_RESET_HOURS
      ).getTime(),
    })
    return bannerDefinition
  }

  if (state && shouldReset(state)) {
    deleteNewsletterPopupState()
  }

  return undefined
}
