import React from "react"
import classNames from "classnames"
import styles from "./CustomDrawer.module.css"
import contentClasses from "./CustomDrawerContent.module.css"
import Drawer, { DrawerPosition } from "./Drawer"

export interface ExtraContent {
  item: React.ReactNode
  offset: number
}

export interface DrawerClasses {
  root?: string
  content?: string
}

interface Props {
  children: any
  head?: React.ReactNode
  position: DrawerPosition
  swipable?: boolean
  open: boolean
  onClose?: () => void
  minWidth?: string | number
  maxWidth?: string | number
  minHeight?: string | number
  maxHeight?: string | number
  mobileFullWidth?: boolean
  hAuto?: boolean
  padding?: string | number
  className?: string
  rightContent?: ExtraContent
  zIndex: number
  translateFullScreen?: boolean
  classes?: DrawerClasses
}

export const DrawerContent = ({
  className,
  contentClassName,
  head,
  children,
}: any) => {
  return (
    <div className={classNames(contentClasses.root, className)}>
      {head && <div>{head}</div>}
      <div className={classNames(contentClasses.content, contentClassName)}>
        {children}
      </div>
    </div>
  )
}

export const CustomDrawer = ({
  position,
  open,
  onClose,
  swipable,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  padding,
  head,
  hAuto,
  children,
  className,
  rightContent,
  mobileFullWidth,
  zIndex,
  translateFullScreen,
  classes,
}: Props) => {
  return (
    <Drawer
      minWidth={minWidth}
      maxWidth={maxWidth}
      minHeight={minHeight}
      maxHeight={maxHeight}
      open={open}
      padding={padding}
      mobileFullWidth={mobileFullWidth}
      position={position}
      onClose={onClose}
      zIndex={zIndex}
      translateFullScreen={translateFullScreen}
      swipable={swipable}
      hAuto={hAuto}
    >
      <DrawerContent
        className={classNames(styles.root, classes?.root, className)}
        contentClassName={classes?.content}
        head={head}
      >
        {children}
      </DrawerContent>
      {rightContent && (
        <div
          className={styles.rightContent}
          style={{
            right: rightContent?.offset,
          }}
        >
          {rightContent.item}
        </div>
      )}
    </Drawer>
  )
}

CustomDrawer.defaultProps = {
  swipable: false,
  position: "right",
  zIndex: 1000,
}
