import React from "react"
import Link from "next/link"
import { CustomContainer } from "../../spacings"
import classes from "./Header.module.scss"
import classNames from "classnames"

export interface HeaderProps {
  logoUrl?: string
  sticky?: boolean
  animateIn?: boolean
  rightControls?: React.ReactNode
  leftControls?: React.ReactNode
  links?: React.ReactNode
  fluid?: boolean
  className?: string
  containerClassName?: string
  id?: string
  shrink: boolean
}

const Header = ({
  logoUrl,
  links,
  leftControls,
  rightControls,
  sticky,
  animateIn,
  fluid,
  className,
  containerClassName,
  id,
}: HeaderProps) => {
  return (
    <header
      id={id}
      style={
        animateIn
          ? {
              animation:
                "700ms ease-in-out 0s normal none 1 running fadeInDown",
            }
          : undefined
      }
      className={classNames(
        "transition-all duration-300 py-4",
        {
          "fixed z-999 top-0 left-0 w-full bg-white": sticky,
        },
        className
      )}
    >
      <CustomContainer fluid={fluid} className={containerClassName}>
        <div className="flex items-center justify-between">
          {leftControls && (
            <div style={{ minWidth: "33%" }}>{leftControls}</div>
          )}
          <div
            className={classes.inner}
            // style={{ maxWidth: 142, marginTop: 9, marginBottom: 9 }}
          >
            <Link href="/" passHref>
              <a>
                <img
                  src={logoUrl}
                  height={64}
                  style={{ minHeight: 41 }}
                  className="w-full h-auto align-middle"
                />
              </a>
            </Link>
          </div>
          {links && <div>{links}</div>}
          <div style={{ minWidth: "33%" }}>{rightControls}</div>
        </div>
      </CustomContainer>
    </header>
  )
}

export default Header
