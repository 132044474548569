import {
  CheckoutInput,
  CheckoutLineItemInput,
} from "../../../../api/backend/backend-api"
import { trackAddToCart } from "../../../tracking"
import { DEFAULT_MAX_SELL_QUANTITY } from "../../parameters"
import { LineItemInput } from "../../types"
import { showProductDiscountPopup } from "../checkoutSlice"
import { Basket } from "../checkoutTypes"
import { basketUpdate } from "./basketUpdate"

const mapBasketItem = (item: CheckoutLineItemInput, omitPromoCode: boolean) => {
  const { promoCodes, ...other } = item
  return {
    ...other,
    promoCodes: omitPromoCode ? undefined : promoCodes,
  }
}

const mergeData = (
  item: LineItemInput,
  checkout: CheckoutInput
): CheckoutInput => {
  const currentItem = checkout.lineItems.find(
    (x) => x.variantId === item.productVariant.info.variantId
  )
  if (currentItem) {
    currentItem.quantity = Math.min(
      item.productVariant.inventory?.maxSellQuantity ??
        DEFAULT_MAX_SELL_QUANTITY,
      currentItem.quantity + item.quantity
    )
    if (item.productPromoCode) {
      currentItem.promoCodes = [item.productPromoCode]
    }
    return checkout
  }

  return {
    ...checkout,
    lineItems: [
      ...checkout.lineItems.map((x) =>
        // omit promo codes from existing items is the new one has it
        mapBasketItem(x, !!item.productPromoCode)
      ),
      {
        isGift: false,
        quantity: item.quantity,
        variantId: item.productVariant.info.variantId as string,
        productId: item.product.info.productId,
        source: item.source,
        promoCodes: item.productPromoCode ? [item.productPromoCode] : undefined,
      },
    ],
  }
}

export const addToBasket = (
  item: LineItemInput,
  callback?: (basket: Basket | undefined) => void,
  errorCallback?: (error: any) => void
) => {
  return basketUpdate({
    input: (checkout) => mergeData(item, checkout),
    callback: (basket, dispatch) => {
      console.log("addToBasket -> callback", basket)
      if (basket) {
        trackAddToCart({
          item,
          basket,
        })
      }
      callback?.(basket)

      if (basket?.promotionsExtra?.availableProductPromoDiscounts?.length) {
        console.log(
          "showProductDiscountPopup",
          basket.promotionsExtra.availableProductPromoDiscounts
        )
        dispatch?.(
          showProductDiscountPopup(
            basket.promotionsExtra.availableProductPromoDiscounts
          )
        )
      }
    },
    errorCallback,
  })
}
