import React from "react"
import Ripple from "material-ripple-effects"
import classNames from "classnames"
import styles from "./CustomFab.module.css"
import { ThemeColor } from "@punks/core"

export interface CustomFabProps {
  disabled?: boolean
  ripple: "light" | "dark"
  size: "small" | "medium" | "large"
  children: any
  onClick?: () => void
  className?: string
  color?: ThemeColor
  colorValue?: string
}

const CustomFab = ({
  disabled,
  ripple,
  children,
  onClick,
  className,
  color,
  colorValue,
  size,
}: CustomFabProps) => {
  const rippleEffect = new Ripple()
  return (
    <button
      className={classNames(
        styles.root,
        color === "primary" && styles.primary,
        size === "small" && styles.small,
        size === "medium" && styles.medium,
        size === "large" && styles.large,
        className
      )}
      style={{
        backgroundColor: colorValue,
      }}
      onClick={onClick}
      disabled={disabled}
      onMouseUp={(e) => {
        disabled !== true && ripple === "dark" && rippleEffect.create(e, "dark")
        disabled !== true &&
          ripple === "light" &&
          rippleEffect.create(e, "light")
      }}
    >
      {children}
    </button>
  )
}

CustomFab.defaultProps = {
  ripple: "dark",
  size: "medium",
}

export default CustomFab
