import { last } from "lodash"
import { CatalogProductDocument } from "../api/backend/backend-api"
import { PartialGridItemProduct } from "../ui/organisms/Product/ProductGridItem/types"
import { isNew } from "./catalog"

export const toPartialGridItemProduct = (
  input: CatalogProductDocument,
  defaultVariantId?: string
): PartialGridItemProduct => {
  const defaultVariantIdValue =
    defaultVariantId ??
    input.defaultVariant.info.variantId ??
    input.variants?.[0].info.variantId
  const variant = input.variants.find(
    (x) => x.info.variantId === defaultVariantIdValue
  )

  return {
    defaultVariant: {
      info: {
        brandName: variant?.metadata.brand?.name ?? "",
        name: variant?.contents?.name ?? "",
        new: isNew(input),
        productId: input.product.info.productId,
        slug: input.product.info.productSlug,
        variantId: variant?.info.variantId ?? "",
        categoryName: last(
          input.product.metadata.categoryPaths?.[0]?.path ?? []
        )?.name,
        // promoLogoUrl: input.product.extraData?.promotions?.[0]?.logoUrl
      },
      media: {
        imageAsset: variant?.contents?.productImages?.[0]?.asset,
      },
      inventory: variant?.inventory
        ? {
            available: variant.inventory.maxSellQuantity > 0,
            price: variant.inventory.price,
            compareAtPrice: variant.inventory.compareAtPrice ?? undefined,
            discountPercent: variant.inventory.discountPercent ?? 0,
          }
        : undefined,
    },
  }
}
