import classNames from "classnames"
import Link from "next/link"
import React from "react"
import { SanityNextImg } from "../../../../cms/components"
import { editorialPageUrl } from "../../../../root/routing/urls"
import Typography from "../../../atoms/Typography"

const FooterColumnLabel = ({ item, children }: any) => {
  if (item.externalLink) {
    return (
      <a
        key={item._key}
        href={item.externalLink.target}
        target="blank"
        className="w-full h-full"
      >
        {children}
      </a>
    )
  }

  if (item.internalLink) {
    return (
      <Link
        key={item._key}
        href={editorialPageUrl(
          item.internalLink.data.seo.slug,
          item.internalLink.data.category?.slug
        )}
        passHref
      >
        <a className="w-full h-full">{children}</a>
      </Link>
    )
  }

  return <>{children}</>
}

interface CustomFooterColumnItemProps {
  item: any
  classes?: {
    container?: string
    textContainer?: string
    icon?: string
    label?: string
    imageContainer?: string
    image?: string
  }
}
const CustomFooterColumnItem = ({
  item,
  classes,
}: CustomFooterColumnItemProps) => {
  return (
    <FooterColumnLabel item={item}>
      <div key={item._key} className={classNames(classes?.container, "mt-3")}>
        <div
          className={classNames(
            classes?.textContainer,
            "mb-3 flex items-center"
          )}
        >
          {item.icon && (
            <span className="mr-2">
              <SanityNextImg
                asset={item.icon.asset}
                className={classes?.icon}
                layout="intrinsic"
                width={16}
                noBox
              />
            </span>
          )}
          <Typography variant="body2" weight="light" className={classes?.label}>
            {item.label}
          </Typography>
        </div>
        {item.images && (
          <div className={classes?.imageContainer}>
            {item.images.map((image: any, imageIndex: number) => (
              <React.Fragment key={imageIndex}>
                <SanityNextImg
                  asset={image.icon}
                  className={classes?.image}
                  noBox
                  layout="intrinsic"
                />
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </FooterColumnLabel>
  )
}

export default CustomFooterColumnItem
