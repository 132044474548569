import React from "react"
import { CustomContainer } from "../../spacings"

export interface LinksBarProps {
  children: any
  className?: string
  left?: React.ReactNode
  right?: React.ReactNode
}

const LinksBar = ({ children, left, right, className }: LinksBarProps) => {
  return (
    <CustomContainer
      className={`flex items-center justify-between ${className ?? ""}`}
    >
      {left}
      {children}
      {right}
    </CustomContainer>
  )
}

export default LinksBar
