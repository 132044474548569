import { useRootContents } from "../../../aggregators/rootData"
import { LabelsMapItem } from "../../../contents/labels/types"
import { LabelScope } from "../components/EditorialLabel"
import { replacePlaceholders } from "../utils/placeholders"

interface GetLabelOptions {
  placeholders?: any
  emptyIfMissing?: boolean
}

export const useLabels = () => {
  const { labelsData } = useRootContents()
  const getLabelItem = (
    scope: LabelScope,
    id: string
  ): LabelsMapItem | undefined => labelsData?.[scope]?.[id]

  const getLabel = (
    scope: LabelScope,
    id: string,
    options?: GetLabelOptions
  ) => {
    const text = getLabelItem(scope, id)?.text
    if (text === undefined && options?.emptyIfMissing) {
      return ""
    }

    if (text === undefined) {
      return `${scope}:${id}`
    }
    return options?.placeholders
      ? replacePlaceholders(text, options.placeholders)
      : text
  }
  return { getLabelItem, getLabel }
}
