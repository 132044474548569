import React from "react"
import ProductGridItem from "../../../../ui/organisms/Product/ProductGridItem"
import { PartialGridItemProduct } from "../../../../ui/organisms/Product/ProductGridItem/types"

interface Props {
  product: PartialGridItemProduct
}

const SearchPanelProductResult = ({ product }: Props) => {
  return <ProductGridItem product={product} quickViewZIndex={1500} />
}

export default SearchPanelProductResult
