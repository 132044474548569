import React from "react"
import Link from "next/link"
import classes from "./HeaderMobile.module.css"
import classNames from "classnames"

export interface HeaderProps {
  topWidget?: React.ReactNode
  logoUrl?: string
  replaceLogo?: boolean
  onMenuClick: () => void
  onSearchClick: () => void
  sticky?: boolean
  animateIn?: boolean
  leftControls?: React.ReactNode
  rightControls?: React.ReactNode
  shrink: boolean
  // onShrinkChange: (value: boolean) => void
}

export const headerShrinkSize = 12

const HeaderMobile = ({
  logoUrl,
  animateIn,
  sticky,
  leftControls,
  rightControls,
  shrink,
  topWidget,
  replaceLogo,
}: HeaderProps) => {
  return (
    <div
      className={classNames(
        {
          "fixed z-999 top-0 left-0 w-full bg-white": sticky,
        },
        classes.header
      )}
    >
      <div>{topWidget}</div>
      <header
        style={{
          animation: animateIn
            ? "700ms ease-in-out 0s normal none 1 running fadeInDown"
            : undefined,
        }}
        className={classNames(
          "transition-all duration-300 flex items-center justify-center"
        )}
      >
        <div
          style={{
            transition: "padding 0.3s",
            paddingBottom: shrink ? 6 : 12,
            paddingTop: shrink ? 6 : 12,
          }}
          className="w-full px-4"
        >
          <div className={classes.inner}>
            <div className={classes.leftControls}>{leftControls}</div>
            <div
              className={classNames(classes.logoContainer, {
                "px-1": shrink,
              })}
            >
              <Link href="/" passHref>
                <a>
                  {replaceLogo ? (
                    <h1
                      style={{
                        ["--logo-url" as any]: `url(${logoUrl})`,
                      }}
                      className={classes.logoSeo}
                    >
                      <span>Modus Profumerie</span>
                    </h1>
                  ) : (
                    <img src={logoUrl} height={40} className={classes.logo} />
                  )}
                </a>
              </Link>
            </div>
            <div className={classes.rightControls}>{rightControls}</div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default HeaderMobile
