import React, { CSSProperties } from "react"
import classNames from "classnames"
import { ThemeColor, ThemeColorVariant } from "@punks/core"
import classes from "./Dot.module.css"
import { getThemeColor } from "../../../themes/utils"

interface Props {
  color?: ThemeColor
  colorVariant?: ThemeColorVariant
  backgroundImage?: string
  colorValue?: string
  size: string
  onClick?: () => void
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
}

const Dot = ({
  color,
  colorVariant,
  colorValue,
  size,
  onClick,
  className,
  style,
  backgroundImage,
  children,
}: Props) => {
  return (
    <div
      className={classes.container}
      style={{
        ["--size" as any]: size,
        ["--color" as any]:
          colorValue ??
          (color ? getThemeColor(color, colorVariant) : undefined),
        ["--background-img" as any]: backgroundImage,
      }}
    >
      <div
        className={classNames(
          classes.dot,
          { [classes.clickable]: !!onClick },
          className
        )}
        onClick={onClick}
        style={style}
      >
        {children}
      </div>
    </div>
  )
}

Dot.defaultProps = {
  color: "primary",
  colorVariant: "main",
  size: "0.875rem",
}

export default Dot
