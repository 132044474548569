import * as React from "react"
import { IconProps } from "./types"

export const EyeIcon = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.58"
      height="12.546"
      viewBox="0 0 22.58 12.546"
      {...props}
    >
      <g
        id="Raggruppa_234"
        data-name="Raggruppa 234"
        transform="translate(0.905 0.75)"
      >
        <path
          id="Tracciato_175"
          data-name="Tracciato 175"
          d="M1192.193-84.066a12.5,12.5,0,0,1-10.379-5.519,12.507,12.507,0,0,1,10.386-5.527,12.506,12.506,0,0,1,10.385,5.527,12.5,12.5,0,0,1-10.379,5.519"
          transform="translate(-1181.814 95.112)"
          fill="none"
          stroke="#000"
          strokeWidth="1.5"
        />
        <circle
          id="Ellisse_3"
          data-name="Ellisse 3"
          cx="3.693"
          cy="3.693"
          r="3.693"
          transform="translate(6.696 1.753)"
          fill="none"
          stroke="#000"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  )
}
