import { useRouter } from "next/router"
import React from "react"
import classes from "./SearchPanelResults.module.css"
import {
  CatalogProductDocument,
  FullTextSearchQuery,
} from "../../../../api/backend/backend-api"
import { brandPagePath, categoryPagePath } from "../../../../root/routing/urls"
import Loader from "../../../../ui/atoms/Loader"
import SearchPanelProductResult from "../SearchPanelProductResult"
import SearchResultTextSection from "../SearchResultTextSection"
import {
  CustomBox,
  CustomContainer,
  CustomRow,
  CustomCol,
} from "../../../../ui/spacings"
import Typography from "../../../../ui/atoms/Typography"
import { toPartialGridItemProduct } from "../../../../converters/carousel"

interface Props {
  query: string
  loading: boolean
  data?: FullTextSearchQuery
  onClose: () => void
}

const SearchPanelResults = ({ query, loading, data, onClose }: Props) => {
  const router = useRouter()

  React.useEffect(() => {
    router.events.on("routeChangeStart", onClose)

    return () => {
      router.events.off("routeChangeStart", onClose)
    }
  }, [])

  const isEmptyQuery = (query: string) => query.trim() === ""

  const isEmptyResult = () =>
    data?.fullTextSearch.brands.length === 0 &&
    data?.fullTextSearch.categories.length === 0 &&
    data?.fullTextSearch.products.length === 0

  return (
    <>
      {loading && <Loader />}
      {!isEmptyQuery(query) && data && (
        <CustomBox background="white" py={2}>
          <CustomContainer className={classes.results}>
            <CustomBox mt={2}>
              {isEmptyResult() && (
                <Typography variant="body1">
                  Nessun risultato trovato
                </Typography>
              )}
              {!isEmptyResult() && data.fullTextSearch.brands.length > 0 && (
                <SearchResultTextSection
                  className={classes.section}
                  title="Brand"
                  titleClassName={classes.title}
                  items={data.fullTextSearch.brands.map((x) => ({
                    label: x.name,
                    url: brandPagePath(x.slug),
                  }))}
                />
              )}
              {!isEmptyResult() && data.fullTextSearch.categories.length > 0 && (
                <SearchResultTextSection
                  className={classes.section}
                  title="Categorie"
                  titleClassName={classes.title}
                  items={data.fullTextSearch.categories.map((x) => ({
                    label: x.name,
                    url: categoryPagePath([x.slug]),
                  }))}
                />
              )}
              {!isEmptyResult() && data.fullTextSearch.products.length > 0 && (
                <div className={classes.section}>
                  <Typography textClassName={classes.title}>
                    Prodotti
                  </Typography>
                  <CustomRow>
                    {data.fullTextSearch.products.map((x, key) => (
                      <CustomCol key={key} xs={6} sm={2}>
                        <CustomBox my={3}>
                          <SearchPanelProductResult
                            product={toPartialGridItemProduct(
                              x as CatalogProductDocument
                            )}
                          />
                        </CustomBox>
                      </CustomCol>
                    ))}
                  </CustomRow>
                </div>
              )}
            </CustomBox>
          </CustomContainer>
        </CustomBox>
      )}
    </>
  )
}

export default SearchPanelResults
