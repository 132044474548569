import { UiState } from "../../../../ui/state/uiTypes"

export const hasModalConflicts = (uiState: UiState) => {
  return (
    uiState.basketDrawerOpen ||
    uiState.wishlistDrawerOpen ||
    uiState.loginDialogOpen ||
    uiState.searchDialogOpen ||
    uiState.newPoliciesPanelOpen ||
    uiState.welcomePanelOpen ||
    uiState.promoRouletteOpen ||
    uiState.promoTimedVoucherOpen
  )
}
