import { CatalogProductVariantSearchDataFragment } from "../../../../../api/backend/backend-api"
import { PartialGridItemProduct } from "../../../../../ui/organisms/Product/ProductGridItem/types"

export const toPartialGridItemProduct = (
  variant: CatalogProductVariantSearchDataFragment
): PartialGridItemProduct => {
  return {
    defaultVariant: {
      info: {
        brandName: variant.metadata.brand?.name ?? "",
        name: variant.contents.name ?? "",
        new: false,
        productId: "",
        slug: variant.info.slug ?? "",
        variantId: variant.info.variantId,
      },
      media: {
        imageAsset: variant.contents.productImages?.[0]?.asset,
      },
      inventory: {
        available: variant.inventory.maxSellQuantity > 0,
        price: variant.inventory.price,
        compareAtPrice: variant.inventory.compareAtPrice ?? undefined,
        discountPercent: variant.inventory.discountPercent ?? 0,
      },
    },
  }
}
