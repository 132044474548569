import Link from "next/link"
import React from "react"
import { searchPageUrl } from "../../../../../../root/routing/urls"
import CustomButton from "../../../../../../ui/atoms/Button"
import ProductGridItem from "../../../../../../ui/organisms/Product/ProductGridItem"
import { CustomCol, CustomRow } from "../../../../../../ui/spacings"
import { useLabels } from "../../../../../contents/hooks"
import { toPartialGridItemProduct } from "../../../../converters"
import { useAlgoliaFullTextSearch } from "../AlgoliaFullTextSearchContext"

const ProductResults = () => {
  const { getLabel } = useLabels()
  const { results, query } = useAlgoliaFullTextSearch()
  return (
    <>
      <CustomRow>
        {results?.products?.map((x, index) => (
          <CustomCol key={index} xs={6} sm={2}>
            <div className="my-6">
              <ProductGridItem
                product={toPartialGridItemProduct(x)}
                quickViewZIndex={2000}
                verticalPriceXs
              />
            </div>
          </CustomCol>
        ))}
      </CustomRow>
      {results?.hasMoreProducts && (
        <div className="mt-4 text-center">
          <Link href={searchPageUrl(query)} passHref>
            <a>
              <CustomButton variant="outlined" size="large">
                {getLabel("search", "view-all")}
              </CustomButton>
            </a>
          </Link>
        </div>
      )}
    </>
  )
}

export default ProductResults
