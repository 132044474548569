import React from "react"
import DefaultFooter from "./components/Footer"
import DefaultHeader from "./components/Header"
import DefaultDocumentHead from "./components/Head"
import classNames from "classnames"
import DefaultModals from "./components/DefaultModals"
import LayoutInitializer from "./components/LayoutInitializer"
import classes from "./DefaultLayout.module.css"
import DefaultNotifications from "./components/DefaultNotifications"
import ScrollTopButton from "../../ui/molecules/ScrollTopButton"
import ResponsiveSwitch from "../../root/punks/mui/visibility/ResponsiveSwitch"
import { useRootContents } from "../../aggregators/rootData"
import { getMobileTopPadding } from "./components/Header/utils"
import PromoBannerController from "../../features/promotions/components/PromoBannerController"
import ProductDiscountPromoControl from "../../features/checkout/components/promo/ProductDiscountPromoControl"

export interface DefaultLayoutProps {
  children: any
  contentFlex?: boolean
  hideHeader?: boolean
  hideHeaderMobile?: boolean
  hideFooter?: boolean
  hideFooterMobile?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  grayBackground?: boolean
  disableScrollTop?: boolean
  disableScrollTopOnMobile?: boolean
  home?: boolean
  mobileHeaderOffset?: number
}

export const DefaultLayout = ({
  children,
  contentFlex,
  hideHeader,
  hideHeaderMobile,
  hideFooter,
  hideFooterMobile,
  header,
  footer,
  grayBackground,
  disableScrollTop,
  disableScrollTopOnMobile,
  mobileHeaderOffset,
  home,
}: DefaultLayoutProps) => {
  const { layout } = useRootContents()
  const mobileContentOffset =
    mobileHeaderOffset ?? getMobileTopPadding(layout, home ?? false)

  return (
    <>
      <LayoutInitializer />
      <DefaultDocumentHead />
      <div
        className={classes.root}
        style={{
          ["--content-offset" as any]: mobileContentOffset
            ? `${mobileContentOffset}px`
            : undefined,
        }}
      >
        <div>
          {header || (
            <DefaultHeader
              hidden={hideHeader}
              hiddenMobile={hideHeaderMobile}
              home={home}
            />
          )}
        </div>
        <div
          id="page-content"
          className={classNames(classes.content, {
            "flex flex-col flex-1": contentFlex,
            "bg-disabled": grayBackground,
          })}
        >
          {children}
        </div>
        {footer || (
          <DefaultFooter
            desktopHidden={hideFooter}
            mobileHidden={hideFooterMobile}
          />
        )}

        <ResponsiveSwitch
          hidden={disableScrollTop}
          mobileHidden={disableScrollTopOnMobile}
        >
          <ScrollTopButton />
        </ResponsiveSwitch>
      </div>
      <DefaultModals />
      <DefaultNotifications />
      <PromoBannerController />
      <ProductDiscountPromoControl />
    </>
  )
}
