import React from "react"
import { basketPagePath } from "../../../../root/routing/urls"
import { BasketIcon } from "../../../../ui/icons"
import QuantityPopup from "../../../../ui/molecules/QuantityPopup"
import ConditionalLink from "../../../../ui/utilities/ConditionalLink"
import { useBasket } from "../../state/hooks"

type BasketLinkType = "button" | "link"

export interface BasketLinkProp {
  className?: string
  type: BasketLinkType
  onClick?: () => void
}

const BasketLink = ({ className, type, onClick }: BasketLinkProp) => {
  const basket = useBasket()
  return (
    <ConditionalLink
      link={basketPagePath()}
      disabled={type === "button"}
      onClick={() => (type === "button" ? onClick?.() : undefined)}
      prefetch={false}
    >
      <div>
        <QuantityPopup quantity={basket?.items.length}>
          <BasketIcon className={className} />
        </QuantityPopup>
      </div>
    </ConditionalLink>
  )
}

BasketLink.defaultProps = {
  type: "link",
}

export default BasketLink
