import React, { useState } from "react"
import { CheckoutProductDiscountPopupState } from "../../../state/checkoutTypes"
import { CatalogProductVariantSearchDataFragment } from "../../../../../api/backend/backend-api"
import Typography from "../../../../../ui/atoms/Typography"
import ProductGridItem from "../../../../../ui/organisms/Product/ProductGridItem"
import { toPartialGridItemProduct } from "./converters"
import { normalizeEan } from "../../../../../utils/ean"
import { PartialGridItemProduct } from "../../../../../ui/organisms/Product/ProductGridItem/types"
import { div, mul, sub } from "../../../../../utils/math"
import CustomButton from "../../../../../ui/atoms/Button"
import { useAppDispatch } from "../../../../../state/store"
import { addToBasket } from "../../../state/actions/addToBasket"
import { getCommerceProductByVariantId } from "../../../../../api/backend/products/api"
import { toProduct } from "../../../../../converters/catalog"

const toPartialGridItemProductWithPriceOverride = (
  variant: CatalogProductVariantSearchDataFragment,
  discounts: CheckoutProductDiscountPopupState
): PartialGridItemProduct => {
  const item = toPartialGridItemProduct(variant)

  const discount = discounts.discounts.find((x) =>
    x.eanCodes
      .map((x) => normalizeEan(x))
      .includes(normalizeEan(variant.info.variantId))
  )
  if (!discount || !item.defaultVariant.inventory) {
    return item
  }

  const compareAtPrice =
    item.defaultVariant.inventory.compareAtPrice ??
    item.defaultVariant.inventory.price
  const price = sub(
    compareAtPrice,
    div(mul(compareAtPrice, discount.amount.value), 100)
  )
  const discountPercent = mul(
    100,
    div(sub(compareAtPrice, price), compareAtPrice)
  )

  return {
    ...item,
    defaultVariant: {
      ...item.defaultVariant,
      inventory: item.defaultVariant.inventory
        ? {
            ...item.defaultVariant.inventory,
            compareAtPrice,
            price,
            discountPercent,
          }
        : undefined,
    },
  }
}

export interface ProductDiscountPromoContentProps {
  discounts: CheckoutProductDiscountPopupState
  variants: CatalogProductVariantSearchDataFragment[]
  onClose?: () => void
}

const ProductDiscountPromoContent = ({
  discounts,
  variants,
  onClose,
}: ProductDiscountPromoContentProps) => {
  console.log("ProductDiscountPromoContent", discounts, variants)

  const [loading, setLoading] = useState(false)
  const [
    addedProduct,
    setAddedProduct,
  ] = useState<CatalogProductVariantSearchDataFragment>()

  const amount = discounts.discounts[0]?.amount?.value ?? 0
  const dispatch = useAppDispatch()

  const handleProductAdd = async (
    variant: CatalogProductVariantSearchDataFragment
  ) => {
    setLoading(true)
    setAddedProduct(variant)

    const catalogProduct = await getCommerceProductByVariantId({
      input: {
        variantId: variant.info.variantId,
        languageId: "it_it",
      },
    })

    if (!catalogProduct.data?.getCommerceProductByVariantId) {
      console.error("ProductDiscountPromoContent: product not found")
      return
    }

    const product = toProduct(
      catalogProduct.data.getCommerceProductByVariantId as any
    )
    const productVariant = product.variants.find(
      (x) =>
        normalizeEan(x.info.variantId) === normalizeEan(variant.info.variantId)
    )

    if (!productVariant) {
      console.error("ProductDiscountPromoContent: variant not found")
      return
    }

    dispatch(
      addToBasket(
        {
          product,
          productVariant,
          quantity: 1,
          source: "product-discount-promo",
        },
        () => {
          setLoading(false)
          setAddedProduct(undefined)
          onClose?.()
        }
      )
    )
  }

  return (
    <div className="p-2">
      <Typography variant="h4">Complimenti!</Typography>
      <Typography variant="subtitle2">
        Hai diritto a uno sconto del {amount}% su uno di questi articoli
      </Typography>
      <div className="mt-6 flex flex-wrap md:px-[60px] justify-center">
        {variants.map((x) => (
          <div key={x.info.variantId} className="w-2/3 md:w-1/2 mb-12">
            <ProductGridItem
              disableNavigation
              hideExtraControls
              product={toPartialGridItemProductWithPriceOverride(x, discounts)}
            />
            <div className="text-center mt-4">
              <CustomButton
                size="small"
                onClick={() => handleProductAdd(x)}
                loading={
                  loading && x.info.variantId === addedProduct?.info.variantId
                }
              >
                Aggiungi
              </CustomButton>
            </div>
          </div>
        ))}
        {/* <ProductSwiperCarousel
          productItems={variants.map((x) => (
            <div key={x.info.variantId}>
              <ProductGridItem
                disableNavigation
                hideExtraControls
                product={toPartialGridItemProductWithPriceOverride(
                  x,
                  discounts
                )}
              />
              <div className="text-center">
                <CustomButton size="small">Aggiungi</CustomButton>
              </div>
            </div>
          ))}
          styles={{
            backgroundColor: "",
            backgroundFilled: false,
          }}
          maxSlidesPerView={2.5}
          variant="default"
        /> */}
      </div>
    </div>
  )
}

export default ProductDiscountPromoContent
