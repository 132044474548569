import React from "react"
import ProductDiscountBadge from "./ProductDiscountBadge"
import ProductNewBadge from "./ProductNewBadge"

export interface ProductPrice {
  discountPercent: number
}

export interface ProductBadgesProps {
  new?: boolean
  pricing?: ProductPrice
  badgeClassName?: string
  promoLogoUrl?: string
  promoLogoUrlWidth?: number
  promoLogoClassName?: string
  noPromo?: boolean
  height: number
}

const ProductBadges = ({
  new: isNew,
  pricing,
  badgeClassName,
  promoLogoUrl,
  promoLogoUrlWidth,
  promoLogoClassName,
  height,
}: ProductBadgesProps) => {
  return (
    <>
      {promoLogoUrl && (
        <img
          src={promoLogoUrl}
          height={height}
          className={promoLogoClassName}
          style={{ marginRight: 4 }}
        />
      )}
      {pricing && pricing.discountPercent > 0 ? (
        <ProductDiscountBadge
          className={badgeClassName}
          discountPercent={pricing.discountPercent}
          height={height}
        />
      ) : undefined}
      {isNew ? (
        <ProductNewBadge className={badgeClassName} height={height} />
      ) : undefined}
    </>
  )
}

export default ProductBadges

ProductBadges.defaultProps = {
  height: 22,
}
