import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../state/store"
import { CustomDialog } from "../../../../../ui/molecules/Dialog/CustomDialog"
import { hideProductDiscountPopup } from "../../../state/checkoutSlice"
import { useBasketDiscountedProducts } from "../../hooks/useBasketDiscountedProducts"
import ProductDiscountPromoContent from "./ProductDiscountPromoContent"

// import dynamic from "next/dynamic"
// const ProductDiscountPromoContent = dynamic(
//   () => import("./ProductDiscountPromoContent")
// )

const ProductDiscountPromoControl = () => {
  const { productDiscountPopup, basket } = useAppSelector(
    (state) => state.checkout
  )
  const { products: discountedProducts } = useBasketDiscountedProducts(basket)

  const dispatch = useAppDispatch()

  const open =
    (productDiscountPopup?.show ?? false) &&
    (discountedProducts?.length ?? 0) > 0

  const handleClose = () => dispatch(hideProductDiscountPopup())

  return (
    <>
      <CustomDialog open={open} onClose={handleClose} zIndex={9999999}>
        {open && productDiscountPopup && (
          <ProductDiscountPromoContent
            discounts={productDiscountPopup}
            variants={discountedProducts ?? []}
          />
        )}
      </CustomDialog>
    </>
  )
}

export default ProductDiscountPromoControl
