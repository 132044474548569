import React from "react"
import classes from "./SearchPanel.module.css"
import { useDebouncedCallback } from "use-debounce"
import { useFullTextSearchLazyQuery } from "../../../../api/backend/backend-api"
import { useLanguageId } from "../../../localization/hooks"
import SearchPanelResults from "../SearchPanelResults"
import SearchBar from "./SearchBar"

const SEARCH_DELAY_MS = 250

interface Props {
  onClose: () => void
  style?: React.CSSProperties
}

const SearchPanel = ({ onClose, style }: Props) => {
  const [query, setQuery] = React.useState("")
  const [searching, setSearching] = React.useState(false)
  const [searchProducts, { data, loading }] = useFullTextSearchLazyQuery()
  const languageId = useLanguageId()

  const isEmptyQuery = (query: string) => query.trim() === ""

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      searchProducts({
        variables: {
          query: value,
          options: {
            languageId,
            maxBrands: 12,
            maxCategories: 12,
            maxProducts: 12,
          },
        },
      })
      setSearching(false)
    },
    SEARCH_DELAY_MS
  )

  const handleQueryChange = (query: string) => {
    setQuery(query)
    if (isEmptyQuery(query)) {
      setSearching(false)
      return
    }

    setSearching(true)
    debounced(query)
  }

  return (
    <>
      <div className={classes.searchBar} style={style}>
        <SearchBar
          searchTerm={query}
          onSearchTermChange={handleQueryChange}
          onClose={onClose}
        />
      </div>

      <SearchPanelResults
        data={data}
        loading={searching || loading}
        query={query}
        onClose={onClose}
      />
    </>
  )
}

export default SearchPanel
