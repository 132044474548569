import React from "react"

interface Props {
  Component: any
  open: boolean
  openDelay?: number
  onClose: () => void
  ComponentProps?: any
}

const DynamicModalLoader = ({
  Component,
  ComponentProps,
  open,
  openDelay,
  onClose,
}: Props) => {
  const [initialized, setInitialized] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false)

  React.useEffect(() => {
    if (open) {
      setInitialized(true)
      if (openDelay) {
        setTimeout(() => setModalOpen(true), openDelay)
      } else {
        setModalOpen(true)
      }
    } else {
      setModalOpen(false)
    }
  }, [open])

  return (
    <>
      {initialized && (
        <Component open={modalOpen} onClose={onClose} {...ComponentProps} />
      )}
    </>
  )
}

export default DynamicModalLoader
