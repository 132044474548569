import { addItemToWishlist } from "../../../../api/backend/wishlist/api"
import { AppThunk } from "../../../../state/store"
import { trackAddToWishlist } from "../../../tracking"
import { persistWishlistId, readWishlistId } from "../../services/persist"
import { toWishlist } from "../converters"
import {
  updateWishlistCompleted,
  updateWishlistError,
  updateWishlistStarted,
} from "../wishlistSlice"

export const addItemToUserWishlist = (variantId: string): AppThunk => async (
  dispatch,
  getState
) => {
  const wishlistId = getState().wishlist.wishlist?.id ?? readWishlistId()

  try {
    dispatch(updateWishlistStarted())
    const wishlist = await addItemToWishlist({
      input: {
        wishlistId,
        variantId,
      },
    })

    if (!wishlist.data?.wishlistItemAdd) {
      throw new Error(wishlist.errors?.[0].message ?? "Error response")
    }

    // todo: restore params
    trackAddToWishlist({
      variant: {
        variantId,
        brand: "",
        name: "",
        price: 0,
        compareAtPrice: 0,
      },
    })
    persistWishlistId(wishlist.data.wishlistItemAdd.id)
    dispatch(updateWishlistCompleted(toWishlist(wishlist.data.wishlistItemAdd)))
  } catch (err) {
    dispatch(updateWishlistError(err.toString()))
    console.error(`Error adding item to wishlist -> ${wishlistId}`, err)
  }
}
