import { FontWeight, TextVariant, ThemeColor } from "@punks/core"
import React from "react"
import { TwRichTextField } from "../../../../root/punks/core/fields/RichTextField"
import Typography from "../../../../ui/atoms/Typography"
import { useLabels } from "../../hooks"
import { replacePlaceholders } from "../../utils/placeholders"

export type LabelScope =
  | "common"
  | "legal"
  | "forms"
  | "messages"
  | "disclaimers"
  | "account"
  | "customer"
  | "newsletter"
  | "orders"
  | "order"
  | "returns"
  | "basket"
  | "checkout"
  | "product"
  | "search"
  | "login"
  | "payment"
  | "discounts"
  | "giftcard"
  | "stores"
  | "calendar"
  | "edit-order"
  | "menu"
  | "seo"

export interface LabelProps {
  component?: keyof JSX.IntrinsicElements
  scope: LabelScope
  id: string
  fillLine?: boolean
  variant?: TextVariant
  plainText?: boolean
  className?: string
  weight?: FontWeight
  style?: React.CSSProperties
  color?: ThemeColor
  colorValue?: string
  placeholders?: any
  prefix?: string
  suffix?: string
}

const EditorialLabel = ({
  component,
  scope,
  id,
  fillLine,
  variant,
  plainText,
  className,
  weight,
  style,
  color,
  colorValue,
  placeholders,
  prefix,
  suffix,
}: LabelProps) => {
  const { getLabelItem } = useLabels()
  const item = getLabelItem(scope, id)
  // const { labelsData } = useRootContents()
  // const item = labelsData?.data?.[scope]?.find(
  //   (x: any) => x.key.trim().toLowerCase() === id.toLowerCase()
  // )
  if (!item) {
    console.error(`Label not found ${scope}:${id}`)
    return <>{`${scope}:${id}`}</>
  }

  if (item.text?.trim()) {
    const text = placeholders
      ? replacePlaceholders(item.text, placeholders)
      : item.text
    return plainText ? (
      <>{[prefix, text, suffix].filter((x) => x).join(" ")}</>
    ) : (
      <Typography
        variant={variant}
        component={component}
        className={className}
        weight={weight}
        fillLine={fillLine}
        style={style}
        color={color}
        colorValue={colorValue}
      >
        {[prefix, text, suffix].filter((x) => x).join(" ")}
      </Typography>
    )
  }

  if (item.richText) {
    return (
      <TwRichTextField
        value={item.richText}
        variant={variant}
        className={className}
        weight={weight}
        fillLine={fillLine}
        style={style}
        color={color}
        colorValue={colorValue}
      />
    )
  }

  return <></>
}

export default EditorialLabel
