import React from "react"
import classes from "./FooterMobile.module.css"
import CustomAccordion from "../../../molecules/CustomAccordion"
import { CustomContainer } from "../../../spacings"
import FooterCopyright from "../FooterCopyright"
import FooterSocialSection from "../FooterSocialSection"
import { FaFacebookF, FaInstagram } from "react-icons/fa"
import FooterColumn from "../FooterDesktop/FooterColumn"
import FooterNewsletter from "../FooterNewsletter"

interface Props {
  data: any
}

const FooterMobile = ({ data }: Props) => {
  return (
    <div className={classes.root}>
      <CustomContainer>
        <div className="my-4">
          <FooterNewsletter mobile />
        </div>
        <div className="my-4">
          <CustomAccordion
            bordersColor="transparent"
            textColor="white"
            expanded={["sect-0"]}
            noGutters
            jsHidden
            items={data.sections.map((section: any, index: number) => ({
              id: `sect-${index}`,
              title: section.title,
              content: (
                <div className="w-full">
                  {section.columns?.map((column: any, colIndex: number) => (
                    <div key={colIndex} className="pl-2">
                      <FooterColumn data={column} mobile />
                    </div>
                  ))}
                </div>
              ),
            }))}
          />
        </div>
        <div className="mt-6 mb-2">
          <FooterSocialSection
            social={{
              items: [
                {
                  icon: <FaFacebookF />,
                  url: data.social?.facebookUrl?.target,
                },
                {
                  icon: <FaInstagram />,
                  url: data.social?.instagramUrl?.target,
                },
              ],
            }}
          />
        </div>
        <div className={classes.copyright}>
          <FooterCopyright companyInfo={data.companyInfo} />
        </div>
      </CustomContainer>
    </div>
  )
}

export default FooterMobile
