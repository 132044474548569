export const showPromoBar = (promoBar: any, home: boolean) => {
  return !home || promoBar?.data?.options?.disableInHp !== true
}

export const getPromoBarHeightDesktop = (promoBar: any) =>
  promoBar?.data?.options?.heightDesktop ?? 0

export const getPromoBarHeightMobile = (promoBar: any) =>
  promoBar?.data?.options?.heightMobile ?? 0

export const getMobileTopPadding = (layout: any, home: boolean) => {
  const promoBarOffset = showPromoBar(layout?.ref?.promoBar, home)
    ? 0
    : getPromoBarHeightMobile(layout.ref.promoBar)
  return (layout?.data?.header?.mobileTopPadding ?? 0) - promoBarOffset
}

export const getDesktopTopPadding = (layout: any, home: boolean) => {
  const promoBarOffset = showPromoBar(layout?.ref?.promoBar, home)
    ? 0
    : getPromoBarHeightDesktop(layout.ref.promoBar)
  return (layout?.data?.header?.desktopTopPadding ?? 0) - promoBarOffset
}
