export interface ValidityRange {
  validFrom?: string
  validTo?: string
}

export const isDateValid = (range: ValidityRange, when: Date) => {
  if (range.validFrom && when < new Date(range.validFrom)) {
    return false
  }

  if (range.validTo && when > new Date(range.validTo)) {
    return false
  }

  return true
}
