import React from "react"
import { useToasts } from "react-toast-notifications"
import { useNewsletterManageMutation } from "../../../api/backend/backend-api"
import { useLabels } from "../../contents/hooks"
import { trackLead } from "../../tracking"

export const useNewsletterManager = () => {
  const { addToast } = useToasts()
  const { getLabel } = useLabels()
  const [manage, { loading, error, data }] = useNewsletterManageMutation()

  const subscribe = (email: string) => {
    if (!email?.trim()) {
      return
    }

    manage({
      variables: {
        input: {
          email: email.trim(),
          subscribed: true,
        },
      },
    })
  }

  React.useEffect(() => {
    if (data?.newsletterManage.success) {
      addToast(
        data?.newsletterManage.alreadySubscribed
          ? getLabel("newsletter", "already-subscribed")
          : getLabel("newsletter", "subscribe-success"),
        {
          appearance: "success",
          autoDismiss: true,
        }
      )
      trackLead()
    }
    if (data?.newsletterManage.success === false || error) {
      addToast(getLabel("messages", "generic-error"), {
        appearance: "error",
        autoDismiss: true,
      })
    }
  }, [data, error])

  return {
    subscribe,
    loading,
    error,
    completed: data?.newsletterManage.success,
  }
}
