import React from "react"
import { IoChevronBack } from "react-icons/io5"
import classes from "./MobileSubMenuHead.module.css"

interface MobileSubMenuHeadProps {
  title?: string
  onBack: () => void
}

const MobileSubMenuHead = ({ onBack, title }: MobileSubMenuHeadProps) => {
  return (
    <div className={classes.root} onClick={onBack}>
      <div className={classes.arrow}>
        <IoChevronBack />
      </div>
      {title && <div className={classes.title}>{title}</div>}
    </div>
  )
}

export default MobileSubMenuHead
