import React from "react"
import EmailVerifiedNotifications from "../../../../features/authentication/components/EmailVerifiedNotifications"
import PwdResetNotification from "../../../../features/authentication/components/PwdResetNotification"

const DefaultNotifications = () => {
  return (
    <>
      <PwdResetNotification />
      <EmailVerifiedNotifications />
    </>
  )
}

export default DefaultNotifications
