import React from "react"
import classes from "./Collapse.module.css"

export interface CollapseProps {
  children: any
  in: boolean
  className?: string
}

export const Collapse = ({
  children,
  in: expanded,
  className,
}: CollapseProps) => {
  return (
    <div
      className={`${classes.root} ${!expanded ? classes.collapsed : ""} ${
        className ?? ""
      }`}
    >
      {children}
    </div>
  )
}
