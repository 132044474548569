// https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
export const isValidPassword = (password: string) => {
  // const strongRegex = new RegExp(
  //   "^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[.!@#$%^&*])(?=.{6,})"
  // )
  const strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})")
  return strongRegex.test(password)
}

export const isValidEmail = (email: string) => {
  const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return re.test(String(email).toLowerCase())
}
