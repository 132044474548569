import React from "react"
import { useCustomColor } from "../../../../contents/colors/hooks"
import { useLabels } from "../../../../features/contents/hooks"
import ProductBadge from "../../../molecules/Product/ProductBadge"

interface Props {
  className?: string
  height?: number
}

const ProductNewBadge = ({ className, height }: Props) => {
  const { getLabel } = useLabels()
  const color = useCustomColor("badge-new")
  return (
    <ProductBadge
      textColor={color?.contrast ?? "#fff"}
      backgroundColor={color?.value ?? "#bf9d51"}
      className={className}
      height={height}
    >
      {getLabel("product", "new-tag")}
    </ProductBadge>
  )
}

export default ProductNewBadge
