import React from "react"
import { CustomContainer } from "../../../../ui/spacings"
import { EditorialLabel } from "../../../contents"
import { useAlgoliaFullTextSearch } from "./components/AlgoliaFullTextSearchContext"
import BrandResults from "./components/BrandResults"
import CategoryResults from "./components/CategoryResults"
import ProductResults from "./components/ProductResults"

interface Props {
  onClose: () => void
}

const AlgoliaFullTextSearch = ({ onClose }: Props) => {
  const { results } = useAlgoliaFullTextSearch()
  return (
    <div className="sticky inset-0 z-20">
      {/* <SearchBar
        searchTerm={text}
        onSearchTermChange={(value) => {
          setText(value)
          onQueryUpdate(value)
        }}
        onClose={onClose}
      /> */}
      <CustomContainer>
        <BrandResults />
        <CategoryResults />
        <ProductResults />
        {results?.noResults === true && (
          <div className="my-8 text-center">
            <EditorialLabel scope="search" id="no-results" variant="body1" />
          </div>
        )}
      </CustomContainer>
    </div>
  )
}

export default AlgoliaFullTextSearch
