import React from "react"
import { ToastProvider } from "react-toast-notifications"
import { useRootContents } from "../../../../aggregators/rootData"
import FooterDesktop from "../../../../ui/organisms/Footer/FooterDesktop"
import FooterMobile from "../../../../ui/organisms/Footer/FooterMobile"

interface Props {
  mobileHidden?: boolean
  desktopHidden?: boolean
}

const DefaultFooter = ({ mobileHidden, desktopHidden }: Props) => {
  const { layout } = useRootContents()

  return (
    <>
      {!desktopHidden && (
        <div className="hidden lg:block" id="footer-desktop">
          <ToastProvider placement="bottom-right">
            <FooterDesktop data={layout.data.footer.data} />
          </ToastProvider>
        </div>
      )}
      {!mobileHidden && (
        <div className="lg:hidden" id="footer-mobile">
          <FooterMobile data={layout.data.footer.data} />
        </div>
      )}
    </>
  )
}

export default DefaultFooter
