import { buildPagePath } from "../../../root/routing/urls"

export type LinkType = "internal" | "external"
export interface LinkField {
  url: string
  type: LinkType
}

export const toLinkField = (link: any): LinkField | undefined => {
  if (link?.target) {
    return {
      url: buildPagePath(link.target),
      type: "internal",
    }
  }

  if (link?.href) {
    return {
      url: link.href,
      type: "external",
    }
  }

  return undefined
}
