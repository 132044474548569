import { groupBy } from "lodash"
// import { toISODate } from "../../../../utils/dates"
import { SearchParameters, Tag } from "../types/search"

const tagField = (tag: Tag) => `_tags:${tag.key}|${tag.code}`

const getTagsExclusionFilters = (tags: Tag[]): string[] =>
  tags.map((x) => `NOT ${tagField(x)}`)

const getTagGroupFilter = (tag: Tag[]) =>
  `(${tag.map((x) => tagField(x)).join(" OR ")})`

const getTagsFilters = (tags: Tag[]): string[] => {
  const components: string[] = []
  const tagGroups = groupBy(tags, (x) => x.key)
  for (const group in tagGroups) {
    components.push(getTagGroupFilter(tagGroups[group]))
  }
  return components
}

const productIdField = (productId: string) => `productId:${productId}`

const getProductIdFilter = (productIds: string[]) =>
  `(${productIds.map((x) => productIdField(x)).join(" OR ")})`

const getAvailabilityFilter = (searchParams: SearchParameters) => {
  return searchParams.includeUnavailable
    ? undefined
    : "inventory.maxSellQuantity>0"
}

const getNotHiddenFilter = () => {
  return "hidden:false"
}

export const getValidFromFilter = (timestamp: Date) => {
  return "_tags:validFrom|any"
  // return `(_tags:validFrom|any OR validFromTs>=${timestamp.getTime()})`
}

export const getValidToFilter = (timestamp: Date) => {
  return "_tags:validTo|any"
  // return `(_tags:validTo|any OR validToTs<="${timestamp.getTime()}")`
}

const getCategoryFilter = (categoryCodes: string[]): string =>
  getTagGroupFilter(
    categoryCodes.map((x) => ({
      key: "category",
      code: x.toLocaleLowerCase(),
    }))
  )

const getBrandFilter = (brands: string[]): string =>
  getTagGroupFilter(
    brands.map((x) => ({
      key: "brand",
      code: x.toLocaleLowerCase(),
    }))
  )

const excludedBrandsFilter = (brands: string[]): string =>
  getTagsExclusionFilters(
    brands.map((x) => ({
      key: "brand",
      code: x.toLocaleLowerCase(),
    }))
  ).join(" AND ")

const promoCodesFilter = (promoCodes: string[]) =>
  `(${promoCodes.map((x) => `inventory.promoCodes:${x}`).join(" OR ")})`

const minDiscountPercentFilter = (minDiscountPercent: number) =>
  `inventory.discountPercent>=${minDiscountPercent}`

const maxDiscountPercentFilter = (maxDiscountPercent: number) =>
  `inventory.discountPercent<=${maxDiscountPercent}`

const toExcludeProductIds = (excludeProductIds: string[]) =>
  excludeProductIds.map((x) => `NOT productId:${x}`).join(" AND ")

const isEmpty = (value: any) =>
  value === undefined || value === null || value === ""

export const toAlgoliaFilter = (
  searchParams: SearchParameters,
  timestamp: Date
) => {
  const components = [
    getAvailabilityFilter(searchParams),
    getValidFromFilter(timestamp),
    getValidToFilter(timestamp),
    ...(searchParams.includeHidden === true ? [] : [getNotHiddenFilter()]),
    ...(searchParams?.tags ? getTagsFilters(searchParams.tags) : []),
    ...((searchParams?.categories?.length ?? 0) > 0
      ? [getCategoryFilter(searchParams?.categories as string[])]
      : []),
    ...((searchParams?.brandCodes?.length ?? 0) > 0
      ? [getBrandFilter(searchParams?.brandCodes as string[])]
      : []),
    ...((searchParams?.excludeBrandCodes?.length ?? 0) > 0
      ? [excludedBrandsFilter(searchParams.excludeBrandCodes as string[])]
      : []),
    ...(!isEmpty(searchParams?.minDiscountPercent)
      ? [minDiscountPercentFilter(searchParams.minDiscountPercent ?? 0)]
      : []),
    ...(!isEmpty(searchParams?.maxDiscountPercent)
      ? [maxDiscountPercentFilter(searchParams.maxDiscountPercent ?? 100)]
      : []),
    ...(searchParams?.excludeProductIds &&
    searchParams.excludeProductIds.length > 0
      ? [toExcludeProductIds(searchParams.excludeProductIds)]
      : []),
    ...(searchParams?.promoCodes && searchParams.promoCodes.length > 0
      ? [promoCodesFilter(searchParams.promoCodes)]
      : []),
    ...(searchParams?.productIds && searchParams.productIds.length > 0
      ? [getProductIdFilter(searchParams.productIds)]
      : []),
  ].filter((x) => x)
  const q = components.join(" AND ")
  return q
}

const buildPriceFilters = (searchParams: SearchParameters) => {
  return [
    ...(searchParams?.minPrice
      ? [`inventory.price>=${searchParams.minPrice}`]
      : []),
    ...(searchParams?.maxPrice
      ? [`inventory.price<=${searchParams.maxPrice}`]
      : []),
  ]
}

export const toAlgoliaNumericFilters = (searchParams: SearchParameters) => {
  return buildPriceFilters(searchParams)
}
