import classNames from "classnames"
import React from "react"
import classes from "./Rectangle.module.css"

export interface RectangleProps {
  ratio: number
  ratioMobile?: number
  smNoRect?: boolean
  mdNoRect?: boolean
  lgNoRect?: boolean
  children?: any
  center?: boolean
  className?: string
  contentClassName?: string
  style?: React.CSSProperties
}

const Rectangle = ({
  ratio,
  ratioMobile,
  smNoRect,
  mdNoRect,
  lgNoRect,
  children,
  center,
  className,
  contentClassName,
  style,
}: RectangleProps) => {
  return (
    <div
      style={{
        ["--ratio" as any]: `${ratio}%`,
        ["--ratio-sm" as any]: `${ratioMobile ?? ratio}%`,
        ...style,
      }}
      className={classNames(
        classes.root,
        { [classes.rootSmNoRect]: smNoRect },
        { [classes.rootMdNoRect]: mdNoRect },
        { [classes.rootLgNoRect]: lgNoRect },
        className
      )}
    >
      <div
        className={classNames(
          classes.content,
          { [classes.center]: center },
          { [classes.contentSmNoRect]: smNoRect },
          { [classes.contentMdNoRect]: mdNoRect },
          { [classes.contentLgNoRect]: lgNoRect },
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Rectangle
