import * as React from "react"
import { IconProps } from "./types"

export const BurgherIcon = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.495"
    height="13.936"
    viewBox="0 0 16.495 13.936"
    {...props}
  >
    <g
      id="Raggruppa_264"
      data-name="Raggruppa 264"
      transform="translate(-13.535 -95.199)"
    >
      <path
        id="Tracciato_176"
        data-name="Tracciato 176"
        d="M3373.625-7162.769h16.495"
        transform="translate(-3360.09 7258.468)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      />
      <path
        id="Tracciato_177"
        data-name="Tracciato 177"
        d="M3373.625-7162.769h16.495"
        transform="translate(-3360.09 7264.936)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      />
      <path
        id="Tracciato_178"
        data-name="Tracciato 178"
        d="M3373.625-7162.769h16.495"
        transform="translate(-3360.09 7271.403)"
        fill="none"
        stroke="#000"
        strokeWidth="1"
      />
    </g>
  </svg>
)
