import dynamic from "next/dynamic"
import React from "react"
import { useLayoutData } from "../../../../aggregators/rootData"
import DynamicModalLoader from "../../../../features/ui/components/DynamicWrapper"
import { useAppUi } from "../../../../features/ui/hooks"
import {
  updateBasketDrawerState,
  updateLoginDialogState,
  updateMobileMenuState,
  updateNewPoliciesPanelState,
  updatePromoRouletteModalOpenState,
  updateWelcomePanelOpenState,
  updateWishlistDrawerState,
} from "../../../../features/ui/state/uiActions"
import { useAppDispatch } from "../../../../state/store"
import MobileMenuDrawer from "../MobileMenu/MobileMenuDrawer"

const BasketDrawer = dynamic(
  () => import("../../../../features/checkout/components/basket/BasketDrawer"),
  {
    ssr: false,
  }
)

const WishlistDrawer = dynamic(
  () => import("../../../../features/wishlist/components/WishlistDrawer"),
  {
    ssr: false,
  }
)

const LoginModal = dynamic(
  () => import("../../../../features/authentication/components/LoginModal"),
  {
    ssr: false,
  }
)

const WelcomeModal = dynamic(
  () => import("../../../../features/profile/components/WelcomeModal"),
  {
    ssr: false,
  }
)

const NewTermsModal = dynamic(
  () => import("../../../../features/profile/components/NewTermsModal"),
  {
    ssr: false,
  }
)

const PromoRouletteModal = dynamic(
  () => import("../../../../features/promotions/components/PromoRouletteModal"),
  {
    ssr: false,
  }
)

const DefaultModals = () => {
  const {
    mobileMenuOpen,
    basketDrawerOpen,
    wishlistDrawerOpen,
    loginDialogOpen,
    newPoliciesPanelOpen,
    welcomePanelOpen,
    promoRouletteOpen,
  } = useAppUi()
  const layout = useLayoutData()
  const dispatch = useAppDispatch()

  return (
    <>
      <MobileMenuDrawer
        open={mobileMenuOpen}
        onClose={() => dispatch(updateMobileMenuState(false))}
      />
      <DynamicModalLoader
        Component={BasketDrawer}
        openDelay={150}
        open={basketDrawerOpen}
        onClose={() => dispatch(updateBasketDrawerState(false))}
      />
      <DynamicModalLoader
        Component={WishlistDrawer}
        openDelay={150}
        open={wishlistDrawerOpen}
        onClose={() => () => dispatch(updateWishlistDrawerState(false))}
      />
      <DynamicModalLoader
        Component={LoginModal}
        openDelay={150}
        open={loginDialogOpen}
        onClose={() => dispatch(updateLoginDialogState(false))}
      />
      <DynamicModalLoader
        Component={WelcomeModal}
        openDelay={150}
        open={welcomePanelOpen}
        onClose={() => dispatch(updateWelcomePanelOpenState(false))}
      />
      <DynamicModalLoader
        Component={NewTermsModal}
        openDelay={150}
        open={newPoliciesPanelOpen}
        onClose={() => dispatch(updateNewPoliciesPanelState(false))}
      />
      {layout?.body?.roulettePromo && (
        <DynamicModalLoader
          Component={PromoRouletteModal}
          ComponentProps={{
            contents: layout.body.roulettePromo,
          }}
          openDelay={150}
          open={promoRouletteOpen}
          onClose={() => dispatch(updatePromoRouletteModalOpenState(false))}
        />
      )}
    </>
  )
}

export default DefaultModals
