import * as React from "react"
import { IconProps } from "./types"

export const HeartIconEmpty = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.363"
    height="21.83"
    viewBox="0 0 25.363 21.83"
    {...props}
  >
    <g
      id="Tracciato_496"
      data-name="Tracciato 496"
      transform="translate(1774.732 -1670.25)"
    >
      <path
        id="Tracciato_497"
        data-name="Tracciato 497"
        d="M-1762.186,1692.08l-.275-.112c-.5-.205-12.267-5.127-12.267-14.323a7.133,7.133,0,0,1,1.945-5.127,7.148,7.148,0,0,1,5.016-2.266,7.081,7.081,0,0,1,5.706,2.972,7.029,7.029,0,0,1,5.628-2.974,7.242,7.242,0,0,1,7.062,7.414c0,9.131-12.027,14.1-12.539,14.305Zm-5.525-20.369a5.706,5.706,0,0,0-4.008,1.807,5.7,5.7,0,0,0-1.55,4.1c0,7.509,9.35,12.088,11.086,12.874,1.763-.786,11.351-5.4,11.351-12.851a5.779,5.779,0,0,0-5.611-5.934,5.554,5.554,0,0,0-4.953,3.279l-.666,1.49-.667-1.49A5.562,5.562,0,0,0-1767.711,1671.711Z"
      />
    </g>
  </svg>
)
