import { AppThunk } from "../../../state/store"
import { setPopupOpen, triggerPopup } from "./popupSlice"

export const setPopupOpened = (open: boolean): AppThunk => async (dispatch) => {
  dispatch(setPopupOpen(open))
}

export const triggerPromoPopup = (popup: any): AppThunk => async (dispatch) => {
  dispatch(triggerPopup(popup))
}
