import { removeItemFromWishlist } from "../../../../api/backend/wishlist/api"
import { AppThunk } from "../../../../state/store"
import { readWishlistId } from "../../services/persist"
import { toWishlist } from "../converters"
import {
  updateWishlistCompleted,
  updateWishlistError,
  updateWishlistStarted,
} from "../wishlistSlice"

export const removeItemFromUserWishlist = (
  variantId: string
): AppThunk => async (dispatch, getState) => {
  const wishlistId = getState().wishlist.wishlist?.id ?? readWishlistId()
  if (!wishlistId) {
    throw new Error("Missing wishlist error")
  }

  try {
    dispatch(updateWishlistStarted())
    const wishlist = await removeItemFromWishlist({
      input: {
        wishlistId,
        variantId,
      },
    })

    if (!wishlist.data?.wishlistItemRemove) {
      throw new Error(wishlist.errors?.[0].message ?? "Error response")
    }

    dispatch(
      updateWishlistCompleted(toWishlist(wishlist.data.wishlistItemRemove))
    )
  } catch (err) {
    dispatch(updateWishlistError(err.toString()))
    console.error(`Error removing item to wishlist -> ${wishlistId}`, err)
  }
}
