import React from "react"
import Rectangle from "../Rectangle"

export interface SquareProps {
  children?: any
  center?: boolean
  className?: string
  contentClassName?: string
  style?: React.CSSProperties
  smNoRect?: boolean
  mdNoRect?: boolean
  lgNoRect?: boolean
}

const Square = ({ children, ...other }: SquareProps) => (
  <Rectangle {...other} ratio={100}>
    {children}
  </Rectangle>
)

export default Square
