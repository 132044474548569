import classNames from "classnames"
import React from "react"
import { useCustomColor } from "../../../../contents/colors/hooks"
import ProductBadge from "../../../molecules/Product/ProductBadge"

export interface ProductDiscountBadgeProps {
  discountPercent: number
  className?: string
  height?: number
  textColor?: string
  backgroundColor?: string
}

const ProductDiscountBadge = ({
  discountPercent,
  className,
  height,
  textColor,
  backgroundColor,
}: ProductDiscountBadgeProps) => {
  const color = useCustomColor("badge-discount")
  return (
    <ProductBadge
      textColor={textColor ?? color?.contrast ?? "#ff7941"}
      backgroundColor={backgroundColor ?? color?.value ?? "#204c47"}
      className={classNames("min-w-[48px]", className)}
      height={height}
    >
      -{discountPercent}%
    </ProductBadge>
  )
}

export default ProductDiscountBadge
