import React from "react"
import { initNavigation } from "../../../../features/navigation/state/navigationActions"
import { useAppDispatch } from "../../../../state/store"

const LayoutInitializer = () => {
  const dispatch = useAppDispatch()
  React.useEffect(() => dispatch(initNavigation()), [])

  return <></>
}

export default LayoutInitializer
