import { TriggeredPopup } from "../../state/popupTypes"
import { evaluateGameModal } from "./game"
import { evaluateNewsletterBanner } from "./newsletter"
import { EvaluatePopupActivationInput } from "./types"

export const evaluatePopupActivation = async (
  input: EvaluatePopupActivationInput
): Promise<TriggeredPopup | undefined> => {
  for (const gameModal of input.gameModals ?? []) {
    const gameModalBanner = await evaluateGameModal(gameModal, input.session)
    if (gameModalBanner) {
      return {
        type: "gameModal",
        data: gameModalBanner,
      }
    }
  }

  const newsletterBanner = await evaluateNewsletterBanner(
    input.newsletterBanner,
    input.marketingProfile,
    input.session
  )
  if (newsletterBanner) {
    return {
      data: newsletterBanner,
      type: "newsletter",
    }
  }

  return undefined
}
