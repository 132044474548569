import React from "react"
import { animateScroll } from "react-scroll"
import CustomFab from "../../atoms/CustomFab"
import styles from "./ScrollTopButton.module.css"
import classNames from "classnames"
import { useEventListener } from "../../../features/navigation/hooks/useEventListener"
import { ArrowUpIcon } from "../../icons/ArrowUpIcon"

const TOP = 100

const ScrollTopButton = () => {
  const [scroll, setScroll] = React.useState(0)

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  useEventListener("scroll", handleScroll)

  const scrollToTop = () => {
    animateScroll.scrollToTop()
  }
  return (
    <div
      className={classNames(styles.root, scroll > TOP ? styles.visible : "")}
    >
      <CustomFab
        color="primary"
        size="small"
        aria-label="scroll back to top"
        onClick={() => scrollToTop()}
        className={styles.fab}
      >
        <ArrowUpIcon
          style={{
            width: 8,
            marginTop: 4,
          }}
        />
      </CustomFab>
    </div>
  )
}

export default ScrollTopButton
