import {
  readObject,
  removeObject,
  writeObject,
} from "../../../../../root/punks/utils/storage"

export interface LastPopupState {
  lastActivationTimestamp: number
  popupId: string
}

const LAST_POPUP_STATE_KEY = "_LastPopupActivation"

export const saveLastPopupState = (value: LastPopupState) =>
  writeObject(LAST_POPUP_STATE_KEY, value)

export const readLastPopupState = () =>
  readObject(LAST_POPUP_STATE_KEY) as LastPopupState | undefined

export const deleteLastPopupState = () => removeObject(LAST_POPUP_STATE_KEY)
