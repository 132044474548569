import classNames from "classnames"
import Link from "next/link"
import React from "react"
import { SanityNextImg } from "../../../../cms/components"
import { editorialPageUrl } from "../../../../root/routing/urls"
import Typography from "../../../atoms/Typography"

interface Props {
  item: any
  noMarginTop?: boolean
  mobile?: boolean
}

const FooterColumnLabel = ({ item, children }: any) => {
  if (item.externalLink) {
    return (
      <a href={item.externalLink.target} target="blank">
        {children}
      </a>
    )
  }

  if (item.internalLink) {
    return (
      <Link
        href={editorialPageUrl(
          item.internalLink.data.seo.slug,
          item.internalLink.data.category?.slug
        )}
        passHref
      >
        <a>{children}</a>
      </Link>
    )
  }

  return <>{children}</>
}

const FooterColumnItem = ({ item, noMarginTop, mobile }: Props) => {
  return (
    <div className={classNames(!noMarginTop && "my-2")}>
      <FooterColumnLabel item={item}>
        <div className="flex flex-row items-center">
          {/* {item.icon && (
            <div style={{ width: 18 }} className="mr-4">
              <SanityNextImg asset={item.icon.asset} />
            </div>
          )} */}
          <div className="flex-1">
            <Typography variant="body1">{item.label}</Typography>
          </div>
        </div>
        {item.images && (
          <div className="flex flex-row flex-wrap items-center">
            {item.images.map((image: any, index: number) => (
              <div key={index} className="pr-4 my-2 max-w-1/6">
                <SanityNextImg
                  normalImgTag
                  asset={mobile ? image.iconMobile ?? image.icon : image.icon}
                  height={image.iconHeight ?? 24}
                  imgProps={{ height: image.iconHeight ?? 24 }}
                  alt={image.seoTitle}
                />
              </div>
            ))}
          </div>
        )}
      </FooterColumnLabel>
    </div>
  )
}

export default FooterColumnItem
