import React from "react"
import { useRootContents } from "../../../../../../aggregators/rootData"
import LinksBar from "../../../../../../ui/molecules/LinksBar"
import { CustomBox } from "../../../../../../ui/spacings"
import classNames from "classnames"
import DynamicModalLoader from "../../../../../../features/ui/components/DynamicWrapper"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { imageUrl } from "../../../../../../cms/services/images"
import Link from "next/link"
import classes from "./HeaderLinksBar.module.css"
import Typography from "../../../../../../ui/atoms/Typography"
import { useLabels } from "../../../../../../features/contents/hooks"
import HeaderSearchBar from "../HeaderSearchBar"
import { isDateValid } from "../../../../../../modules/automation/timing"

const isValidSection = (section: any) => {
  if (!section.data?.settings?.validity) {
    return true
  }

  return isDateValid(
    {
      validFrom: section.data.settings.validity.validFrom,
      validTo: section.data.settings.validity.validTo,
    },
    new Date()
  )
}

const isBrandsPanel = (section: any) =>
  section?.data?.contents?.columns?.some((col: any) => col.type === "brands")

interface HeaderLinksBarClasses {
  panelContent?: string
}

interface Props {
  classes?: HeaderLinksBarClasses
}

const HeaderLinksBarPanel = dynamic(() => import("./HeaderLinksBarPanel"), {
  ssr: false,
})

const HeaderLinksBar = ({ classes: userClasses }: Props) => {
  const { layout } = useRootContents()
  const { getLabel } = useLabels()
  const router = useRouter()

  const anchorId = "menuPanelAnchor"
  const [panelItem, setPanelItem] = React.useState<any>(undefined)

  // const [searchOpen, setSearchOpen] = React.useState(false)
  // const [headerAnchor, setHeaderAnchor] = React.useState<HTMLElement | null>()
  // React.useEffect(() => setHeaderAnchor(document.getElementById(headerId)), [])
  const headerRef = React.useRef<HTMLDivElement>(null)
  const brandsRef = React.useRef<HTMLDivElement>(null)

  const [anchor, setAnchor] = React.useState<any>()
  React.useEffect(
    () => (anchorId ? setAnchor(document.getElementById(anchorId)) : undefined),
    []
  )

  const handleCategoryClick = (item: any) => {
    if (!panelItem || panelItem !== item) {
      setPanelItem(item)
    } else {
      setPanelItem(undefined)
    }
    // closeSearchPanel()
  }

  const isLinksPanelOpened = () => panelItem !== undefined
  const closeLinksPanel = () => setPanelItem(undefined)

  // const closeSearchPanel = () => setSearchOpen(false)

  React.useEffect(() => {
    router.events.on("routeChangeStart", closeLinksPanel)

    return () => {
      router.events.off("routeChangeStart", closeLinksPanel)
    }
  }, [])

  return (
    <div
      className={classNames("relative", {
        [classes.linksBarPanelOpen]: isLinksPanelOpened(),
      })}
    >
      <LinksBar
        left={
          <div className={classes.sideControlLeft}>
            <HeaderSearchBar clear={isLinksPanelOpened()} />
          </div>
        }
        right={
          <div className={`${classes.sideControlRight} text-right`}>
            <div
              className="cursor-pointer"
              ref={brandsRef}
              onClick={() =>
                handleCategoryClick(
                  layout.ref.menuSections.find((item: any) =>
                    isBrandsPanel(item)
                  )
                )
              }
            >
              <Typography
                variant="body1"
                textClassName={`${classes.label} ${classes.labelRight}`}
                weightValue="boldest"
              >
                {getLabel("menu", "brands")}
              </Typography>
            </div>
          </div>
        }
      >
        <div
          ref={headerRef}
          className="relative flex items-center justify-center w-full cursor-pointer"
        >
          {layout.ref.menuSections
            .filter((item: any) => !isBrandsPanel(item) && isValidSection(item))
            .map((item: any, index: number) => {
              const isLink = !!item.data.contents?.link
              const linkItem = (
                <>
                  {item.data.contents.logo?.asset ? (
                    <img
                      src={imageUrl(item.data.contents.logo?.asset, {
                        width: 30,
                      })}
                      height={21}
                    />
                  ) : (
                    <Typography
                      textClassName={classes.label}
                      colorValue={item.data?.contents?.titleColor?.hex}
                      weightValue={
                        item.data?.contents?.titleColor?.hex
                          ? "bold"
                          : undefined
                      }
                      style={{
                        background: item.data?.contents?.backgroundColor?.hex,
                      }}
                    >
                      {item.data.contents.title}
                      {item.data.contents.icon?.asset && (
                        <img
                          src={imageUrl(item.data.contents.icon?.asset, {
                            width: 14,
                          })}
                          width={14}
                          className="ml-1"
                        />
                      )}
                    </Typography>
                  )}
                </>
              )
              return (
                <div
                  key={index}
                  className={classNames(classes.linkItem, {
                    "bg-black text-white": panelItem === item, // && popupState.isOpen,
                  })}
                  onClick={
                    !isLink
                      ? () => {
                          handleCategoryClick(item)
                        }
                      : undefined
                  }
                >
                  {isLink ? (
                    <Link href={item.data.contents.link} passHref>
                      <a style={{ textDecoration: "none !important" }}>
                        {linkItem}
                      </a>
                    </Link>
                  ) : (
                    linkItem
                  )}
                </div>
              )
            })}
          <CustomBox absolute left={0} top={0} w100 zIndex={1} cursorPointer />
        </div>
      </LinksBar>
      <div id={anchorId} className={classes.panelAnchor}></div>
      <DynamicModalLoader
        Component={HeaderLinksBarPanel}
        ComponentProps={{
          anchor,
          panelItem,
          panelClassName: userClasses?.panelContent,
          excludedCloseElements: [headerRef?.current, brandsRef?.current],
        }}
        open={isLinksPanelOpened()}
        onClose={closeLinksPanel}
      />
    </div>
  )
}

export default HeaderLinksBar
