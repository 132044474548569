import React from "react"
import { useToasts } from "react-toast-notifications"
import { useAppDispatch, useAppSelector } from "../../../../state/store"
import { useLabels } from "../../../contents/hooks"
import { setWelcomePanelOpen } from "../../../ui/state/uiSlice"
import { emailVerificationReset } from "../../state/accountVerification/verificationSlice"

const EmailVerifiedNotifications = () => {
  const { addToast } = useToasts()
  const { getLabel } = useLabels()
  const { verificationSuccess, intent } = useAppSelector(
    (state) => state.verifyUser
  )
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (
      !verificationSuccess ||
      window.location.pathname === "/account/verify-email/"
    ) {
      return
    }
    if (intent === "register") {
      dispatch(setWelcomePanelOpen(true))
    } else {
      addToast(getLabel("account", "change-email-success"), {
        autoDismiss: true,
        appearance: "success",
      })
    }
    dispatch(emailVerificationReset())
  }, [verificationSuccess])

  return <></>
}

export default EmailVerifiedNotifications
