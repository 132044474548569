import * as React from "react"
import { IconProps } from "./types"

export const HeartIconFilled = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.048"
    height="22.42"
    viewBox="0 0 26.048 22.42"
    {...props}
  >
    <g
      id="Tracciato_496"
      data-name="Tracciato 496"
      transform="translate(1733.042 -1670.25)"
    >
      <path
        id="Tracciato_498"
        data-name="Tracciato 498"
        d="M-1720.157,1692.67l-.282-.115c-.514-.211-12.6-5.266-12.6-14.71a7.326,7.326,0,0,1,2-5.266,7.342,7.342,0,0,1,5.152-2.327,7.271,7.271,0,0,1,5.86,3.052,7.221,7.221,0,0,1,5.78-3.054,7.437,7.437,0,0,1,7.253,7.614c0,9.378-12.352,14.479-12.878,14.692Z"
      />
    </g>
  </svg>
)
