import classNames from "classnames"
import Link from "next/link"
import React from "react"
import { useLabels } from "../../../../features/contents/hooks"
import CustomButton from "../../../atoms/Button"
import classes from "./MenuGenderPages.module.css"

const MenuGenderPages = () => {
  const { getLabel } = useLabels()
  return (
    <div className="flex justify-between w-full pl-4 pr-6 mt-4 mb-2">
      <div className={classNames(classes.btnContainer, "pr-1")}>
        <Link href="/c/donna/" passHref>
          <a>
            <CustomButton variant="outlined" className={classes.button}>
              {getLabel("menu", "woman")}
            </CustomButton>
          </a>
        </Link>
      </div>
      <div className={classNames(classes.btnContainer, "pl-1")}>
        <Link href="/c/uomo/" passHref>
          <a>
            <CustomButton variant="outlined" className={classes.button}>
              {getLabel("menu", "man")}
            </CustomButton>
          </a>
        </Link>
      </div>
    </div>
  )
}

export default MenuGenderPages
