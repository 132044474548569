import React from "react"
import {
  IoPlanetOutline,
  IoHelpCircleOutline,
  IoStorefrontOutline,
} from "react-icons/io5"

export interface MenuItem {
  icon?: React.ReactNode
  name: string
  url?: string
  children?: MenuItem[]
}

export const menuBottomItems: MenuItem[] = [
  {
    icon: <IoPlanetOutline />,
    name: "Traccia il mio ordine",
    url: "/order",
  },
  // {
  //   icon: <IoCardOutline />,
  //   name: "Carta fedeltà",
  // },
  {
    icon: <IoHelpCircleOutline />,
    name: "Faq",
    url: "/faq",
  },
  {
    icon: <IoStorefrontOutline />,
    name: "Trova un negozio",
    url: "/i-nostri-negozi",
  },
  // {
  //   icon: <IoStarOutline />,
  //   name: "Lascia il tuo feedback",
  // },
]
