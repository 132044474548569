import React from "react"
import CustomTextInput from "../../../atoms/CustomTextInput"
import {
  BiCheckbox as Checkbox,
  BiCheckSquare as CheckboxSelected,
} from "react-icons/bi"
import styles from "./FooterNewsletter.module.css"
import CustomButton from "../../../atoms/Button"
import { EditorialLabel } from "../../../../features/contents"
import { isValidEmail } from "../../../../features/authentication/services/pwdService"
import { useNewsletterManager } from "../../../../features/marketing/hooks/newsletter"

interface Props {
  mobile?: boolean
}

const FooterNewsletter = ({ mobile }: Props) => {
  const [dirty, setDirty] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [accepted, setAccepted] = React.useState(false)
  const { subscribe, loading, completed } = useNewsletterManager()

  const handleSubscribe = () => {
    setDirty(true)
    if (accepted && email.trim() && isValidEmail(email)) {
      subscribe(email)
    }
  }

  React.useEffect(() => {
    if (completed) {
      setEmail("")
      setAccepted(false)
      setDirty(false)
    }
  }, [completed])

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <EditorialLabel
          scope="newsletter"
          id="subscribe-title"
          variant="h6"
          component="p"
          className={styles.title}
        />
        <EditorialLabel
          scope="newsletter"
          id="subscribe-subtitle"
          variant="body1"
          style={{ fontSize: "15px", fontWeight: 300 }}
          className="flex items-center"
        />
      </div>
      <div className={styles.form}>
        <CustomTextInput
          label="Email"
          variant="standard"
          value={email}
          onChange={setEmail}
          classes={{
            container: styles.emailInputContainer,
            input: styles.emailInput,
            label: "bg-transparent pl-0",
          }}
        />
        <div className={styles.submitControl}>
          <div className={styles.checkboxContainer}>
            <input
              type="checkbox"
              id={mobile ? "footer-newsletter-mobile" : "footer-newsletter"}
              className="hidden"
              checked={accepted}
              onChange={(e) => setAccepted(e.target.checked)}
            ></input>
            <label
              htmlFor={
                mobile ? "footer-newsletter-mobile" : "footer-newsletter"
              }
              className="flex gap-2"
            >
              <div className={styles.checkbox}>
                <Checkbox className="icon-checkbox" color="#ffffff" />
                <CheckboxSelected
                  className="icon-checkbox-selected"
                  color="#ffffff"
                />
              </div>
              <div style={{ marginTop: 3 }}>
                <EditorialLabel
                  colorValue={dirty && !accepted ? "red" : undefined}
                  scope="disclaimers"
                  id="privacy-policy"
                  variant="body1"
                  weight="light"
                  className="flex-1"
                />
              </div>
            </label>
          </div>
          <CustomButton
            variant="outlined"
            className={styles.button}
            onClick={() => handleSubscribe()}
            loading={loading}
          >
            <EditorialLabel scope="newsletter" id="subscribe" plainText />
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default FooterNewsletter
