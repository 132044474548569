import classNames from "classnames"
import { Dictionary, groupBy, orderBy } from "lodash"
import {
  MenuData,
  MenuProductCategory,
} from "../../../../aggregators/menu/types"
import { menuBottomItems } from "../../../../mocks/menu"
import { isDateValid } from "../../../../modules/automation/timing"
import {
  editorialPageUrl,
  promoPagePath,
  serpPagePath,
} from "../../../../root/routing/urls"
import { MenuListItem } from "../../../../ui/molecules/Menu/MenuSection/MenuSectionItem"
import { MenuPanelSection } from "../../../../ui/organisms/Menu/MenuPanel"
import { dictValues } from "../../../../utils/array"

export interface BuildMenuClasses {
  highlighted: string
  underlined: string
}

export interface BuildMenuInput {
  layout: any
  menuData: MenuData
  classes: BuildMenuClasses
  onItemClick: (path: MenuListItem[]) => void
}

const isValidMenuItem = (item: any) => {
  if (!item.validity) {
    return true
  }

  return isDateValid(
    {
      validFrom: item.validity.validFrom,
      validTo: item.validity.validTo,
    },
    new Date()
  )
}

const isGenericItem = (item: any) => !item.type || item.type === "generic"
const isCategoryItem = (item: any) => item.type === "category"
const isBrandItem = (item: any) => item.type === "brand"

const getGenericItemUrl = (item: any, menuData: MenuData) => {
  if (item.target?.promo) {
    return promoPagePath(item.target.promo.slug)
  }
  if (item.target?.category) {
    const menuCategory =
      menuData?.categories[item.target.category.data?.code?.toLowerCase() ?? ""]
    return menuCategory?.url
  }
  if (item.target?.brand) {
    const brandItem =
      menuData?.brands[item.target?.brand?.data?.code?.toLowerCase() ?? ""]
    return brandItem?.url
  }
  if (item.target?.serp) {
    return serpPagePath(item.target.serp.slug)
  }
  if (item.target?.editorialPage) {
    return editorialPageUrl(
      item.target?.editorialPage?.slug,
      item.target?.editorialPage?.category
    )
  }
  return undefined
}

export class MobileMenuBuilder {
  build = (input: BuildMenuInput): MenuPanelSection[] => [
    {
      items: input.layout.ref.menuPanels[0].data.items
        .filter((x: any) => isValidMenuItem(x))
        .map((item: any) => this.createMenuListItem(item, input)),
    },
    {
      items: menuBottomItems.map((item) => ({
        content: item.name,
        icon: item.icon,
        link: item.url,
      })),
    },
  ]

  private createMenuListItem = (
    item: any,
    input: BuildMenuInput
  ): MenuListItem => {
    const link = isGenericItem(item)
      ? getGenericItemUrl(item, input.menuData)
      : undefined
    const menuItem: MenuListItem = {
      content: item.label,
      link,
      textColor: item.titleColor?.hex,
      backgroundColor: item.backgroundColor?.hex,
      className: classNames({
        [input.classes.highlighted]: item.highlighted,
        [input.classes.underlined]: item.underlined,
      }),
    }

    menuItem.children = this.createMenuItemChildren(item, input, menuItem)

    if (!link) {
      menuItem.onItemClick = () => {
        input.onItemClick([menuItem])
      }
    }
    return menuItem
  }

  private createMenuItemChildren = (
    item: any,
    input: BuildMenuInput,
    parent: MenuListItem
  ): MenuListItem[] => {
    if (isBrandItem(item)) {
      return this.createBrandSubmenu(input)
    }
    if (isCategoryItem(item) && item.target?.category?.data?.code) {
      return this.createCategorySubmenu(
        item.target.category.data.code,
        input,
        parent
      )
    }
    return this.createGenericItemSubmenu(item, input)
  }

  private createGenericItemSubmenu = (
    item: any,
    input: BuildMenuInput
  ): MenuListItem[] => {
    return (
      item.children?.map((x: any) => this.createMenuListItem(x, input)) ?? []
    )
  }

  private createBrandSubmenu = (input: BuildMenuInput): MenuListItem[] => {
    return orderBy(dictValues(input.menuData.brands), (x) => x.name)
      .filter((x) => x.count > 0)
      .map((x) => ({
        content: x.name,
        link: x.url,
        children: [],
      }))
  }

  private createCategorySubmenu = (
    categoryCode: string,
    input: BuildMenuInput,
    parent: MenuListItem
  ): MenuListItem[] => {
    const item = input.menuData.categories[categoryCode.toLowerCase()]
    if (item.count === 0) {
      return []
    }

    const childrenDict = groupBy(dictValues(input.menuData.categories), (x) =>
      x.parentCode?.toLowerCase()
    )

    return this.createCategoryChildItems(item, childrenDict, input, parent)
  }

  private createCategoryChildItems = (
    item: MenuProductCategory,
    childrenDict: Dictionary<MenuProductCategory[]>,
    input: BuildMenuInput,
    parent: MenuListItem
  ): MenuListItem[] => {
    const children =
      childrenDict[item.code.toLowerCase()]?.filter((x) => x.count > 0) ?? []
    return [
      ...(children.length > 1 ? [this.createCategoryViewAll(item, input)] : []),
      ...children.map((x) =>
        this.createCategoryMenuItem(x, childrenDict, input, parent)
      ),
    ]
  }

  private createCategoryViewAll = (
    item: MenuProductCategory,
    input: BuildMenuInput
  ): MenuListItem => {
    return {
      content: `${item.name}: vedi tutto`,
      link: item.url,
      className: input.classes.underlined,
    }
  }

  private createCategoryMenuItem = (
    item: MenuProductCategory,
    childrenDict: Dictionary<MenuProductCategory[]>,
    input: BuildMenuInput,
    parent?: MenuListItem
  ): MenuListItem => {
    const menuItem: MenuListItem = {
      content: item.name,
      parentPath: [...(parent?.parentPath ?? []), ...(parent ? [parent] : [])],
    }
    const children = this.createCategoryChildItems(
      item,
      childrenDict,
      input,
      menuItem
    )
    if (children.length > 0) {
      menuItem.onItemClick = () => {
        console.log("click", menuItem)
        input.onItemClick([...(menuItem.parentPath ?? []), menuItem])
      }
    } else {
      menuItem.link = item.url
    }
    menuItem.children = children
    return menuItem
  }
}
