import React from "react"
import FooterColumnItem from "./FooterColumnItem"

interface Props {
  data: any
  mobile?: boolean
}

const FooterColumn = ({ data, mobile }: Props) => {
  return (
    <div style={{ maxWidth: "90%" }}>
      {data.items.map((item: any, index: number) => (
        <FooterColumnItem key={index} item={item} mobile={mobile} />
      ))}
    </div>
  )
}

export default FooterColumn
