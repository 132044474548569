import { isNotLast } from "@punks/core"
import React from "react"
import Stroke from "../../../atoms/Stroke"
import MenuSection from "../../../molecules/Menu/MenuSection"
import { MenuListItem } from "../../../molecules/Menu/MenuSection/MenuSectionItem"
import MenuGenderPages from "./MenuGenderPages"

export interface MenuPanelSection {
  items: MenuListItem[]
}

interface MenuPanelProps {
  sections: MenuPanelSection[]
  head?: React.ReactNode
  className?: string
  leftPadding?: number
  narrow?: boolean
}

const MenuPanel = ({
  sections,
  head,
  className,
  leftPadding,
  narrow,
}: MenuPanelProps) => {
  return (
    <>
      {head && <div>{head}</div>}
      <MenuGenderPages />
      <div className={className}>
        {sections.map((section, index) => (
          <div key={index}>
            <MenuSection
              items={section.items}
              leftPadding={leftPadding}
              narrow={narrow}
            />
            {isNotLast(index, sections) && (
              <Stroke color="disabled" className="my-1" />
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default MenuPanel
