import { addSeconds } from "date-fns"

export const shouldResetTrigger = ({
  now,
  nextResetTimestamp,
}: {
  now: Date
  nextResetTimestamp: number
}) => {
  return now.getTime() > nextResetTimestamp
}

export const shouldTrigger = ({
  now,
  sessionStartTimestamp,
  delaySeconds,
}: {
  now: Date
  sessionStartTimestamp: number
  delaySeconds: number
}) => {
  const validFrom = addSeconds(sessionStartTimestamp, delaySeconds)
  return now.getTime() > validFrom.getTime()
}
