import React from "react"
import { Slide } from "../../../../ui/animations/Slide"
import IconBox from "../../../../ui/atoms/IconBox"
import Stroke from "../../../../ui/atoms/Stroke"
import MobileMenuHead from "./MobileMenuHead"
import MobileMenuPanel from "./MobileMenuPanel"
import { useRootContents } from "../../../../aggregators/rootData"
import { useRouter } from "next/router"
import classes from "./MobileMenuDrawer.module.css"
import { CustomDrawer } from "../../../../ui/molecules/Drawer"
import { MobileMenuBuilder } from "./builder"
import { MenuListItem } from "../../../../ui/molecules/Menu/MenuSection/MenuSectionItem"
import MobileSubMenuHead from "./MobileSubMenuHead"
import { ModalCloseIcon } from "../../../../ui/icons/ModalCloseIcon"

interface Props {
  open: boolean
  onClose: () => void
}

const menuBuilder = new MobileMenuBuilder()

const MobileMenuDrawer = ({ open, onClose }: Props) => {
  const [untouched, setUntouched] = React.useState(true)
  const [selectedPath, setSelectedPath] = React.useState<MenuListItem[]>([])
  const [prevPath, setPrevPath] = React.useState<MenuListItem[][]>([])
  const { layout, menuData } = useRootContents()
  const router = useRouter()

  const resetMenuState = () => {
    setSelectedPath([])
    setPrevPath([])
    onClose()
  }

  React.useEffect(() => {
    setPrevPath([selectedPath, prevPath[0]].filter((x) => x))
  }, [selectedPath])

  React.useEffect(() => {
    router.events.on("routeChangeStart", resetMenuState)

    return () => {
      router.events.off("routeChangeStart", resetMenuState)
    }
  }, [])

  React.useEffect(() => {
    if (!open) {
      setUntouched(true)
    }
  }, [open])

  if (!menuData) {
    return <></>
  }

  const handleSelectPath = (path: MenuListItem[]) => {
    setSelectedPath(path)
  }

  const handleBack = () =>
    setSelectedPath(selectedPath.slice(0, selectedPath.length - 1))

  const rootSections = React.useMemo(
    () =>
      menuBuilder.build({
        classes: {
          highlighted: classes.highlighted,
          underlined: classes.underlined,
        },
        layout,
        menuData,
        onItemClick: handleSelectPath,
      }),
    []
  )

  const isSelectedItem = (item: MenuListItem, level: number) => {
    return selectedPath.length === level && selectedPath[level - 1] === item
  }

  const slideDirection =
    prevPath.length === 2 && prevPath[0]?.length < prevPath[1]?.length
      ? "left"
      : "right"

  return (
    <CustomDrawer
      open={open}
      onClose={onClose}
      position="left"
      maxWidth="calc(100vw - 60px)"
      minWidth="calc(100vw - 60px)"
      translateFullScreen
      rightContent={{
        item: (
          <IconBox
            icon={
              <ModalCloseIcon style={{ width: 18, height: 18 }} color="#fff" />
            }
            onClick={onClose}
            style={{ fontSize: 30, zIndex: 1200 }}
          />
        ),
        offset: -60,
      }}
      className={classes.drawerRoot}
      zIndex={1000}
    >
      <Slide
        from="right"
        in={selectedPath.length === 0}
        timeout={untouched ? 0 : undefined}
        displayNone={selectedPath.length > 0}
      >
        <div>
          <MobileMenuPanel
            head={
              <>
                <MobileMenuHead />
                <Stroke color="disabled" style={{ marginTop: -1 }} />
              </>
            }
            sections={rootSections}
            className={classes.mainMenu}
          />
        </div>
      </Slide>
      {rootSections[0].items
        .filter((item) => (item.children?.length ?? 0) > 0)
        .map((item, index) => (
          <React.Fragment key={index}>
            <Slide
              from={slideDirection}
              in={isSelectedItem(item, 1)}
              displayNone={!isSelectedItem(item, 1)}
              inDelay={100}
            >
              <div>
                <MobileMenuPanel
                  head={
                    <MobileSubMenuHead
                      onBack={handleBack}
                      title={item?.content as string}
                    />
                  }
                  sections={[
                    {
                      items: item.children ?? [],
                    },
                  ]}
                  leftPadding={50}
                  narrow
                />
              </div>
            </Slide>
            {item.children
              ?.filter((subitem) => (subitem.children?.length ?? 0) > 0)
              .map((subitem, subitemIndex) => (
                <Slide
                  key={subitemIndex}
                  from="right"
                  in={isSelectedItem(subitem, 2)}
                  displayNone={!isSelectedItem(subitem, 2)}
                  inDelay={100}
                >
                  <div>
                    <MobileMenuPanel
                      head={
                        <MobileSubMenuHead
                          onBack={handleBack}
                          title={subitem?.content as string}
                        />
                      }
                      sections={[
                        {
                          items: subitem.children ?? [],
                        },
                      ]}
                      leftPadding={50}
                      narrow
                    />
                  </div>
                </Slide>
              ))}
          </React.Fragment>
        ))}
    </CustomDrawer>
  )
}

export default MobileMenuDrawer
