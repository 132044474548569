import { breakpoints } from "../../themes/variables"
import { useWindowSize } from "../ui/hooks/useWindowSize"

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl"
export const useIsDown = (breakpoint?: Breakpoint) => {
  const size = useWindowSize()
  if (!size || size.width === undefined) {
    return true
  }

  if (!breakpoint) {
    return true
  }

  switch (breakpoint) {
    case "xl":
      return true
    case "lg":
      return size.width < breakpoints.xl
    case "md":
      return size.width < breakpoints.lg
    case "sm":
      return size.width < breakpoints.md
    case "xs":
      return size.width < breakpoints.sm
  }
}

export const useIsMobile = () => useIsDown("sm")

export const useIsDownXs = () => useIsDown("xs")
