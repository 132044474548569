import { addMinutes, subDays } from "date-fns"
import {
  shouldResetTrigger,
  shouldTrigger,
} from "../../../../../modules/automation/triggers/activation"
import { readGameState } from "../../../../games/storage"
import { SessionState } from "../../../../session/state/sessionTypes"
import {
  deleteGamePopupState,
  readGamePopupState,
  saveGamePopupState,
} from "./store"

const DEFAULT_POPUP_RESET_MINUTES = 24 * 60
const DEFAULT_DELAY_SECONDS = 60

const hasAlreadyPlayedToday = (gameId: string) => {
  const state = readGameState()
  return (
    state?.gameId === gameId &&
    state?.playedTimestamp >= subDays(new Date(), 1).getTime()
  )
}

export const evaluateGameModal = async (
  modalDefinition: any,
  session: SessionState
) => {
  if (!modalDefinition?.data?.info?.enabled) {
    return undefined
  }

  if (hasAlreadyPlayedToday(modalDefinition?.data?.info?.gameId)) {
    console.log("Game already played today")
    return undefined
  }

  const state = readGamePopupState()
  if (
    !state &&
    shouldTrigger({
      now: new Date(),
      sessionStartTimestamp: session.startTimestamp,
      delaySeconds:
        modalDefinition?.data?.info?.activationDelaySeconds ??
        DEFAULT_DELAY_SECONDS * 60,
    })
  ) {
    saveGamePopupState({
      lastActivationTimestamp: new Date().getTime(),
      nextResetTimestamp: addMinutes(
        new Date(),
        modalDefinition?.data?.info?.resetMinutes ?? DEFAULT_POPUP_RESET_MINUTES
      ).getTime(),
    })
    return modalDefinition
  }

  if (
    state &&
    shouldResetTrigger({
      now: new Date(),
      nextResetTimestamp: state.nextResetTimestamp,
    })
  ) {
    deleteGamePopupState()
  }

  return undefined
}
