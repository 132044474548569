import React from "react"

export interface JobInput {
  callback: () => void
  interval: number
  active: boolean
  startImmediately?: boolean
}

export function useJob(input: JobInput) {
  const { callback, interval: intervalMs, active, startImmediately } = input
  const savedCallback = React.useRef()
  const [timer, setTimer] = React.useState<any>()

  React.useEffect(() => {
    ;(savedCallback as any).current = callback
  }, [callback])

  const stop = () => {
    if (timer) {
      clearInterval(timer)
      setTimer(undefined)
    }
  }

  const start = () => {
    if (timer) {
      stop()
    }
    function tick() {
      ;(savedCallback as any).current()
    }
    const interval = setInterval(tick, intervalMs)
    setTimer(interval)

    if (startImmediately) {
      tick()
    }
  }

  React.useEffect(() => {
    if (active) {
      start()
    } else {
      stop()
    }
  }, [active])

  React.useEffect(() => {
    return () => stop()
  }, [])
}
