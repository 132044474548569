import { flatten } from "lodash"
import { Basket } from "../../state/checkoutTypes"
import { useGetCommerceVariantsQuery } from "../../../../api/backend/backend-api"

export const useBasketDiscountedProducts = (basket?: Basket) => {
  const discountedProductEanCode = flatten(
    basket?.promotionsExtra?.availableProductPromoDiscounts?.map(
      (x) => x.eanCodes
    )
  )

  const { data, loading, error } = useGetCommerceVariantsQuery({
    variables: {
      input: {
        variantIds: discountedProductEanCode ?? [],
      },
    },
    fetchPolicy: "no-cache",
  })

  return {
    loading,
    error,
    products: data?.getCommerceVariants ?? [],
  }
}
