import classNames from "classnames"
import React from "react"
import classes from "./ProductBadge.module.css"

export interface ProductBadgeProps {
  backgroundColor: string
  textColor: string
  children: any
  className?: string
  height?: number
}

const ProductBadge = ({
  children,
  backgroundColor,
  textColor,
  className,
  height,
}: ProductBadgeProps) => {
  return (
    <span
      className={classNames(classes.root, className)}
      style={{
        ["--bg-color" as any]: backgroundColor,
        ["--text-color" as any]: textColor,
        ["--logo-height" as any]: `${height ?? 22}px`,
      }}
    >
      {children}
    </span>
  )
}

ProductBadge.defaultProps = {
  colorVariant: "main",
}

export default ProductBadge
