import React from "react"
import { BackFab, CloseFab } from "../../../atoms/Fab"
import { CustomCol, CustomRow } from "../../../spacings"
import Typography from "../../../atoms/Typography"
import classes from "./CustomDialogHead.module.css"
import classNames from "classnames"
import { TextVariant } from "@punks/core"

type TitleAlignment = "left" | "center"
type ClosePosition = "left" | "right" | "none"
type CloseControl = "back" | "close"

interface Classes {
  title?: string
}

interface Props {
  titleVariant?: TextVariant
  titleAlignment?: TitleAlignment
  closePosition: ClosePosition
  closeControl: CloseControl
  children?: React.ReactNode
  onClose?: () => void
  px?: number
  py?: number
  classes?: Classes
}

export const CustomDialogHead = ({
  titleVariant,
  titleAlignment,
  closePosition,
  children,
  onClose,
  closeControl,
  px,
  py,
  classes: userClasses,
}: Props) => {
  return (
    <div
      className={classes.root}
      style={{
        ["--px" as any]: px,
        ["--py" as any]: py,
      }}
    >
      <CustomRow reverse={closePosition === "right"} alignItems="center">
        <CustomCol
          xs={2}
          className={closePosition === "right" ? classes.closeRight : ""}
        >
          {closeControl === "close" ? (
            <CloseFab
              color="transparent"
              onClick={onClose}
              size="small"
              className={classes.close}
            />
          ) : (
            <BackFab
              color="transparent"
              onClick={onClose}
              size="small"
              className={classes.close}
            />
          )}
        </CustomCol>
        <CustomCol
          xs={titleAlignment === "left" ? 10 : 8}
          className={classNames({
            [classes.titleLeft]: titleAlignment === "left",
            [classes.titleCenter]: titleAlignment === "center",
          })}
        >
          <Typography
            variant={titleVariant ?? "subtitle1"}
            className={userClasses?.title}
          >
            {children}
          </Typography>
        </CustomCol>
      </CustomRow>
    </div>
  )
}

CustomDialogHead.defaultProps = {
  titleAlignment: "center",
  closePosition: "right",
  closeControl: "close",
}

export default CustomDialogHead
