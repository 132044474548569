import React from "react"
import { useRootContents } from "../../../../aggregators/rootData"
import AlgoliaFullTextSearchPanel from "../../../../features/algolia/components/AlgoliaFullTextSearchPanel"
import SearchPanel from "../../../../features/search/components/SearchPanel"

export interface FullTextSearchPanelWrapperProps {
  onClose: () => void
  term: string
}

const FullTextSearchPanelWrapper = ({
  term,
  onClose,
}: FullTextSearchPanelWrapperProps) => {
  const { websiteSettings } = useRootContents()

  if (websiteSettings?.algolia.fullTextSearchEnabled) {
    return <AlgoliaFullTextSearchPanel term={term} onClose={onClose} />
  }

  return <SearchPanel onClose={onClose} />
}

export default FullTextSearchPanelWrapper
