import classNames from "classnames"
import classes from "./DefaultMobileHeader.module.css"
import React from "react"
import { useIsFirstPage } from "../../../../../features/navigation/hooks/useIsFirstPage"
import HeaderMobile from "../../../../../ui/organisms/HeaderMobile"
import WishlistLink from "../../../../../features/wishlist/components/WishlistLink"
import SearchPanelButton from "../../../../../features/search/components/SearchPanelButton"
import BasketLink from "../../../../../features/checkout/components/basket/BasketLink"
import dynamic from "next/dynamic"
import DynamicModalLoader from "../../../../../features/ui/components/DynamicWrapper"
import {
  useExtraContentBlock,
  useRootContents,
} from "../../../../../aggregators/rootData"
import { imageUrl } from "../../../../../cms/services/images"
import TrustBox from "../../../../../features/integrations/components/TrustBox"
import PromoBar from "../../../../../ui/organisms/PromoBar"
import { toPromoBarProps } from "../DefaultDesktopHeader/converters/promoBar"
import { showPromoBar } from "../utils"
import { BurgherIcon } from "../../../../../ui/icons/BurgherIcon"

export interface DefaultMobileHeaderProps {
  onMenuClick: () => void
  onSearchClick: () => void
  sticky?: boolean
  shrink: boolean
  replaceLogo?: boolean
  home?: boolean
}

const HeadSearchModal = dynamic(() => import("../../HeadSearchModal"), {
  ssr: false,
})

const DefaultMobileHeader = ({
  onMenuClick,
  onSearchClick,
  sticky,
  shrink,
  replaceLogo,
  home,
}: DefaultMobileHeaderProps) => {
  const isFirstPage = useIsFirstPage()
  const [searchOpen, setSearchOpen] = React.useState(false)
  const { layout } = useRootContents()
  const tpHeaderWidgetMobile = useExtraContentBlock("tpHeaderWidgetMobile")

  return (
    <>
      <HeaderMobile
        topWidget={
          <>
            {layout.ref.promoBar?.data &&
              showPromoBar(layout.ref.promoBar, home ?? false) && (
                <PromoBar {...toPromoBarProps(layout.ref.promoBar.data)} />
              )}
            {tpHeaderWidgetMobile && (
              <TrustBox id="tpHeaderWidgetMobile" body={tpHeaderWidgetMobile} />
            )}
          </>
        }
        logoUrl={imageUrl(layout.data.header.logo, {
          width: 250,
        })}
        replaceLogo={replaceLogo}
        onMenuClick={onMenuClick}
        onSearchClick={onSearchClick}
        sticky={sticky}
        animateIn={isFirstPage}
        shrink={shrink}
        leftControls={
          <>
            <div
              className={classNames(classes.control, classes.controlLeft, {
                [classes.controlShrinked]: shrink,
              })}
              onClick={onMenuClick}
            >
              <BurgherIcon />
            </div>
            <div
              className={classNames(
                classes.control,
                classes.controlLeft,
                classes.search,
                {
                  [classes.controlShrinked]: shrink,
                }
              )}
            >
              <SearchPanelButton onClick={() => setSearchOpen(true)} />
            </div>
          </>
        }
        rightControls={
          <>
            <WishlistLink
              className={classNames(
                classes.control,
                classes.controlRight,
                classes.wishlist,
                {
                  [classes.controlShrinked]: shrink,
                }
              )}
            />
            <BasketLink
              className={classNames(classes.control, classes.controlRight, {
                [classes.controlShrinked]: shrink,
              })}
            />
          </>
        }
      />
      <DynamicModalLoader
        Component={HeadSearchModal}
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
      />
    </>
  )
}

DefaultMobileHeader.defaultProps = {
  sticky: true,
}

export default DefaultMobileHeader
