import React from "react"
import {
  IoChevronForwardOutline,
  IoChevronDownOutline,
  IoChevronUpOutline,
} from "react-icons/io5"
import ConditionalLink from "../../../utilities/ConditionalLink"
import classNames from "classnames"
import classes from "./MenuSectionItem.module.css"
import { Collapse } from "../../../animations/Collapse"
import CustomButton from "../../../atoms/Button"

type ListItemType = "simple" | "navigable" | "expandable"

export interface MenuListItem {
  icon?: React.ReactNode
  content: React.ReactNode
  className?: string
  textColor?: string
  backgroundColor?: string
  children?: MenuListItem[]
  subItems?: MenuListItem[]
  parentPath?: MenuListItem[]
  link?: string
  narrow?: boolean
  onItemClick?: () => void
}

const listItemType = (item: MenuListItem): ListItemType => {
  // if (item.onNavigationClick || item.onItemClick) {
  //   return "navigable"
  // }

  if ((item.subItems?.length ?? 0) > 0) {
    return "expandable"
  }

  return "simple"
}

interface MenuSectionItemProps {
  item: MenuListItem
  leftPadding?: number
  narrow?: boolean
}

const MenuSectionItem = ({
  item,
  leftPadding,
  narrow,
}: MenuSectionItemProps) => {
  const [expanded, setExpanded] = React.useState(false)
  const type = listItemType(item)

  const handleItemClick = (item: MenuListItem) => {
    if (item.link) {
      return
    }
    if (type === "expandable") {
      setExpanded(!expanded)
      return
    }
    item.onItemClick?.()
  }

  const handleNavigationClick = (item: MenuListItem) => {
    if (item.link) {
      return
    }
    item.onItemClick?.()
  }

  return (
    <ConditionalLink link={item.link}>
      <CustomButton
        variant="text"
        fullWidth
        invariantCase
        className={classNames(
          classes.listItem,
          {
            [classes.listItemNarrow]: narrow,
          },
          item.className
        )}
        classes={{
          inner: classes.listItemInner,
        }}
        style={{ paddingLeft: leftPadding }}
        onClick={() => {
          handleItemClick(item)
        }}
      >
        <div
          className={classNames(classes.description, {
            "px-1": !!item.backgroundColor,
          })}
          style={{
            ["--text-color" as any]: item.textColor,
            ["--bg-color" as any]: item.backgroundColor,
          }}
        >
          {item.icon && (
            <div className={classes.iconContainer}>{item.icon}</div>
          )}
          <div className={classes.link}>{item.content}</div>
        </div>
        {type === "navigable" && (
          <div
            className={classes.arrow}
            onClick={() => handleNavigationClick(item)}
          >
            <IoChevronForwardOutline style={{ fontSize: 12 }} />
          </div>
        )}
        {type === "expandable" && (
          <div className={classes.arrow} onClick={() => setExpanded(!expanded)}>
            {expanded ? (
              <IoChevronUpOutline style={{ fontSize: 12 }} />
            ) : (
              <IoChevronDownOutline style={{ fontSize: 12 }} />
            )}
          </div>
        )}
      </CustomButton>
      <Collapse in={expanded === true}>
        <div className={classes.subitemsContainer}>
          {item.subItems?.map((subitem, index) => (
            <div
              key={index}
              className={classNames(classes.subitem, subitem.className)}
              style={{ paddingLeft: leftPadding }}
            >
              {subitem.content}
            </div>
          ))}
        </div>
      </Collapse>
    </ConditionalLink>
  )
}

export default MenuSectionItem
