import React from "react"
import { TwRichTextField } from "../../../../root/punks/core/fields/RichTextField"
import Typography from "../../../atoms/Typography"
import { SanityNextImg } from "../../../../cms/components"

interface Props {
  data: any
  leftSection: any[]
  rightSection: any[]
}

const FooterBottom = ({ data, leftSection, rightSection }: Props) => {
  return (
    <>
      <div className="flex items-center">
        <Typography
          variant="body2"
          weight="boldest"
          fontSizeValue="12px"
          style={{ marginRight: 2 }}
        >
          {`© ${new Date().getFullYear()} `}
        </Typography>
        <TwRichTextField
          variant="body2"
          weight="boldest"
          className="inline-block"
          value={data.companyInfo}
          fontSizeValue="12px"
        />
      </div>
      <div className="flex items-center justify-start flex-1 ml-4">
        {leftSection?.map((section: any, sectionIndex: number) => (
          <React.Fragment key={sectionIndex}>
            {section.columns?.map((column: any, columnIndex: number) => (
              <React.Fragment key={columnIndex}>
                {column.items.map((item: any, index: number) => (
                  <div className="flex items-center" key={index}>
                    {item.images.map((image: any, imageIndex: number) => (
                      <SanityNextImg
                        layout="intrinsic"
                        noBox
                        key={imageIndex}
                        asset={image.icon}
                        style={{ maxHeight: image.iconHeight ?? 24 }}
                        imgWidth={68}
                        imgHeight={18}
                        alt={image.seoTitle}
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
      <div className={"flex items-center justify-end"}>
        {rightSection?.map((section: any, index: number) => (
          <div key={index} className={"w-full flex items-center gap-8"}>
            <Typography
              variant="h5"
              component="p"
              uppercase
              weight="boldest"
              fontSizeValue="12px"
            >
              {section.title}
            </Typography>
            {section.columns?.map((column: any, columnIndex: number) => (
              <React.Fragment key={columnIndex}>
                {column.items.map((item: any, index: number) => (
                  <div className="flex items-center" key={index}>
                    {item.images.map((image: any, imageIndex: number) => (
                      <SanityNextImg
                        noBox
                        layout="intrinsic"
                        key={imageIndex}
                        asset={image.icon}
                        style={{ maxHeight: image.iconHeight ?? 18 }}
                        imgWidth={68}
                        imgHeight={18}
                        alt={image.seoTitle}
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </>
  )
}

export default FooterBottom
