import { isInt } from "../../../utils/strings"

export const getSpacing = (value: string | undefined) => {
  if (value && isInt(value)) {
    return parseInt(value) * 8
  }

  return undefined
}

export const getSpacingPx = (value: string | undefined) => {
  const px = getSpacing(value)
  return px !== undefined ? `${px}px` : undefined
}
