import React from "react"
import { RawHtml } from "../../../../ui/utilities"

export interface TrustBoxProps {
  id: string
  body: string
}

const TrustBox = ({ id, body }: TrustBoxProps) => {
  const tp = () => (window as any).Trustpilot

  React.useEffect(() => {
    if (tp() && id) {
      const element = document.getElementById(id)
      tp().loadFromElement(element)
    }
  }, [])

  if (!body) {
    return <></>
  }

  return <RawHtml value={body} />
}
export default TrustBox
