import React from "react"
import { ArrowBackIos } from "@material-ui/icons"

import { ThemeColor } from "@punks/core"
import CustomFab from "../CustomFab"
import { ModalCloseIcon } from "../../icons/ModalCloseIcon"

interface IconFabProps {
  color?: ThemeColor
  colorValue?: string
  fontColor?: ThemeColor
  onClick?: () => void
  className?: string
  iconClassName?: string
  size?: "small" | "medium" | "large"
}

export const CloseFab = ({ iconClassName, ...props }: IconFabProps) => {
  return (
    <CustomFab {...props}>
      <ModalCloseIcon
        style={{
          width: 18,
          height: 18,
        }}
        className={iconClassName}
      />
    </CustomFab>
  )
}

export const BackFab = ({ iconClassName, ...props }: IconFabProps) => {
  return (
    <CustomFab {...props}>
      <ArrowBackIos className={iconClassName} />
    </CustomFab>
  )
}
