import classNames from "classnames"
import React from "react"
import classes from "./IconBox.module.css"

interface IconBoxProps {
  icon: React.ReactNode
  onClick?: () => void
  className?: string
  style?: React.CSSProperties
}

const IconBox = ({ icon, onClick, className, style }: IconBoxProps) => {
  return (
    <div
      className={classNames(classes.root, className)}
      style={style}
      onClick={onClick}
    >
      {icon}
    </div>
  )
}

export default IconBox
