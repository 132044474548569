import {
  readObject,
  removeObject,
  writeObject,
} from "../../../../../root/punks/utils/storage"

export interface NewsletterPopupState {
  lastActivationTimestamp: number
  nextResetTimestamp: number
}

const NEWSLETTER_POPUP_STATE_KEY = "_NewsletterPopupState"

export const saveNewsletterPopupState = (value: NewsletterPopupState) =>
  writeObject(NEWSLETTER_POPUP_STATE_KEY, value)

export const readNewsletterPopupState = () =>
  readObject(NEWSLETTER_POPUP_STATE_KEY) as NewsletterPopupState | undefined

export const deleteNewsletterPopupState = () =>
  removeObject(NEWSLETTER_POPUP_STATE_KEY)
