import classNames from "classnames"
import classes from "./SanityNextImg.module.css"
import {
  useNextSanityImage,
  UseNextSanityImageBuilder,
} from "next-sanity-image"
import Img from "next/image"
import React from "react"
import { client } from "../../../root/sanity/client"
import Head from "next/head"
import { SanityImageSource } from "@sanity/image-url/lib/types/types"

export type LayoutValue = "fill" | "fixed" | "intrinsic" | "responsive"

export interface SanityNextImgProps {
  alt?: string
  containerWidth?: string
  containerHeight?: string
  asset: any
  width?: number
  height?: number
  blur?: number
  className?: string
  imgClassName?: string
  objectFit?: NonNullable<JSX.IntrinsicElements["img"]["style"]>["objectFit"]
  objectPosition?: NonNullable<
    JSX.IntrinsicElements["img"]["style"]
  >["objectPosition"]
  layout?: LayoutValue
  sizes?: string
  aspectRatio?: number
  flip?: boolean
  hFull: boolean
  h100?: boolean
  w100?: boolean
  preload?: boolean
  style?: React.CSSProperties
  normalImgTag?: boolean
  noBox?: boolean
  blurUp?: boolean
  quality?: number
  imgProps?: React.ImgHTMLAttributes<unknown>
  imgWidth?: string | number
  imgHeight?: string | number
  onClick?: () => void
}

const normalizeHeight = (value: string | number) => {
  if (typeof value === "string") {
    return parseInt(value.replace("px", ""))
  }
  return value
}

const customImageBuilder = (props: any) => {
  const func: UseNextSanityImageBuilder = (imageUrlBuilder, options) => {
    let urlBuild = imageUrlBuilder.auto("format")

    if (props.height) {
      urlBuild = urlBuild.height(normalizeHeight(props.height))
    } else {
      const currentWidth =
        props.width ??
        options.width ??
        Math.min(options.originalImageDimensions.width, 400)
      urlBuild = urlBuild.width(currentWidth)
      if (props.aspectRatio) {
        urlBuild = urlBuild.height(Math.round(currentWidth / props.aspectRatio))
      }
    }

    if (props.flip) {
      urlBuild = urlBuild.flipHorizontal()
    }
    // if (process.env.SANITY_CDN_BASE_URL) {
    //   urlBuild.options.baseUrl = process.env.SANITY_CDN_BASE_URL
    // }

    if (props.quality) {
      urlBuild = urlBuild.quality(props.quality)
    }

    return urlBuild
  }
  return func
}

export const SanityNextImg = ({
  asset,
  alt,
  className,
  layout,
  objectFit,
  objectPosition,
  sizes,
  aspectRatio,
  quality,
  flip,
  h100,
  w100,
  hFull,
  preload,
  style,
  width,
  blurUp,
  normalImgTag,
  noBox,
  imgProps,
  imgWidth,
  imgHeight,
  imgClassName,
  onClick,
}: SanityNextImgProps) => {
  if (!asset) {
    return <></>
  }

  const sanityImg = useNextSanityImage(client(), asset as SanityImageSource, {
    imageBuilder: customImageBuilder({
      aspectRatio,
      flip,
      width,
      quality,
      height: imgProps?.height,
    }),
    enableBlurUp: blurUp ?? false,
  })
  const imageProps = {
    ...((sanityImg as any) ?? {}),
    ...(imgWidth ? { width: imgWidth } : undefined),
    ...(imgHeight ? { height: imgHeight } : undefined),
    ...(layout === "fill"
      ? { width: undefined, height: undefined }
      : undefined),
    unoptimized: layout !== "fill" && !!width,
    className: imgClassName,
  }

  if (!imageProps?.src) {
    return <></>
  }
  if (normalImgTag) {
    return (
      <img
        src={imageProps.src}
        className={className}
        style={style}
        onClick={onClick}
        {...imgProps}
      />
    )
  }

  if (noBox) {
    return (
      <Img
        alt={alt}
        quality={quality}
        onClick={onClick}
        {...imageProps}
        layout={layout as any}
        objectFit={objectFit ?? "contain"}
        objectPosition={objectPosition}
        sizes={sizes ?? "(max-width: 599px) 80vw, 300px"}
      />
    )
  }

  const boxClassName = classNames(className, {
    [classes.h100]: h100,
    [classes.w100]: w100,
    "h-full max-h-full": hFull,
  })

  if (imageProps.src.includes(".svg")) {
    return (
      <div className={`relative ${boxClassName}`} style={style}>
        <Img
          alt={alt}
          src={imageProps.src}
          loader={imageProps.loader}
          objectFit={objectFit}
          objectPosition={objectPosition}
          layout="fill"
          onClick={onClick}
        />
        {preload && (
          <Head>
            <link rel="preload" as="image" href={imageProps.src} />
          </Head>
        )}
      </div>
    )
  }

  return (
    <div
      className={`relative ${w100 ? "w-full" : ""} ${boxClassName}`}
      style={style}
    >
      <Img
        alt={alt}
        onClick={onClick}
        {...imageProps}
        layout={layout as any}
        objectFit={objectFit ?? "contain"}
        objectPosition={objectPosition}
        sizes={sizes ?? "(max-width: 599px) 80vw, 300px"}
      />
      {preload && (
        <Head>
          <link rel="preload" as="image" href={imageProps.src} />
        </Head>
      )}
    </div>
  )
}

SanityNextImg.defaultProps = {
  hFull: true,
}
