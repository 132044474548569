import { ImageContent } from "@punks/core"
import classNames from "classnames"
import React from "react"
import { SanityImg } from "../../../cms/components"
import { LinkField } from "../../../containers/editorialPage/converters/link"
import { updatePromoRouletteModalOpenState } from "../../../features/ui/state/uiActions"
import { TwRichTextField } from "../../../root/punks/core/fields/RichTextField"
import { useAppDispatch } from "../../../state/store"
import CustomButton from "../../atoms/Button"
import ConditionalLink from "../../utilities/ConditionalLink"
import classes from "./PromoBar.module.css"

export type PromoBarCtaType = "link" | "promoRoulette"

export interface PromoBarItemCta {
  cta?: any
  icon?: any
  type?: PromoBarCtaType
}

export interface PromoBarItem {
  text: any
  textStyle?: "default" | "wide"
  fontColor?: string
  cta?: PromoBarItemCta
  startIcon?: ImageContent
  startIconWidth?: string
  paddingX?: string
  paddingY?: string
  maxWidth?: string
  maxWidthMobile?: string
  letterSpacing?: string
  link?: LinkField
}

export interface PromoBarProps {
  backgroundColor?: string
  items: PromoBarItem[]
  shrink?: boolean
}

const PromoBarSlide = ({ item }: { item: PromoBarItem }) => {
  const dispatch = useAppDispatch()

  const handleClick = () => {
    if (item.cta?.type === "promoRoulette") {
      dispatch(updatePromoRouletteModalOpenState(true))
    }
  }

  return (
    <ConditionalLink link={item.link?.url}>
      <div
        className="flex items-center justify-center px-2 py-1 sm:text-center"
        style={{
          paddingLeft: item.paddingX,
          paddingRight: item.paddingX,
          paddingTop: item.paddingY,
          paddingBottom: item.paddingY,
        }}
      >
        <div
          className={classes.barItem}
          style={{
            ["--max-width-mobile" as any]: item.maxWidthMobile,
            ["--max-width-desktop" as any]: item.maxWidth,
          }}
        >
          {item.startIcon?.asset && (
            <SanityImg
              imgWidth={item.startIconWidth ?? 100}
              asset={item.startIcon.asset}
              className="mr-4"
            />
          )}
          <TwRichTextField
            value={item.text}
            colorValue={item.fontColor}
            className={classNames(classes.slideText, {
              [classes.slideTextWide]: item.textStyle === "wide",
            })}
            variant="body1"
            textCenter
            style={{
              ["--letter-spacing" as any]: item.letterSpacing,
            }}
          />
        </div>
        {(item.cta?.cta?.label || item.cta?.icon) && (
          <CustomButton
            onClick={handleClick}
            hoverInvariant
            className={classes.button}
            color="primary"
          >
            <SanityImg asset={item.cta?.icon} imgHeight={13} className="mr-2" />
            {item.cta?.cta?.label}
          </CustomButton>
        )}
      </div>
    </ConditionalLink>
  )
}

const PromoBar = ({ backgroundColor, items }: PromoBarProps) => {
  if (items.length === 0) {
    return <></>
  }
  return (
    <div
      style={{
        backgroundColor,
      }}
      className={classes.bar}
    >
      {items.length >= 1 && <PromoBarSlide item={items[0]} />}
    </div>
  )
}

export default PromoBar
