import React from "react"
import classes from "./DefaultDesktopHeader.module.css"
import { useIsFirstPage } from "../../../../../features/navigation/hooks/useIsFirstPage"
import Header from "../../../../../ui/organisms/Header"
import HeaderLinksBar from "./LinksPanel/HeaderLinksBar"
import BasketLink from "../../../../../features/checkout/components/basket/BasketLink"
import { useAppDispatch } from "../../../../../state/store"
import {
  updateBasketDrawerState,
  updateWishlistDrawerState,
} from "../../../../../features/ui/state/uiActions"
import WishlistLink from "../../../../../features/wishlist/components/WishlistLink"
import classNames from "classnames"
import AccountLink from "../../../../../features/user/components/AccountLink"
import {
  useExtraContentBlock,
  useRootContents,
} from "../../../../../aggregators/rootData"
import { imageUrl } from "../../../../../cms/services/images"
import PromoBar from "../../../../../ui/organisms/PromoBar"
import { toPromoBarProps } from "./converters/promoBar"
import TrustBox from "../../../../../features/integrations/components/TrustBox"
import { getDesktopTopPadding, showPromoBar } from "../utils"
import HeaderGenderPages from "./controls/HeaderGenderPages"

export interface DefaultDesktopHeaderProps {
  fixed?: boolean
  shrink: boolean
  home?: boolean
}

const DefaultDesktopHeader = ({
  fixed,
  shrink,
  home,
}: DefaultDesktopHeaderProps) => {
  const isFirstPage = useIsFirstPage()
  const dispatch = useAppDispatch()

  const headerId = "deskMenuHeader"

  const { layout } = useRootContents()
  const tpHeaderWidgetDesktop = useExtraContentBlock("tpHeaderWidgetDesktop")
  const headerHeight = getDesktopTopPadding(layout, home ?? false)

  return (
    <div
      style={{
        ["--shrink-6px" as any]: shrink ? "3px" : "6px",
        ["--shrink-9px" as any]: shrink ? "3px" : "9px",
        ["--header-height" as any]: headerHeight
          ? `${headerHeight}px`
          : undefined,
      }}
      className={classNames({
        [classes.rootFixed]: fixed,
      })}
    >
      <div
        className={classNames(
          classes.headerRoot,
          {
            [classes.fixed]: fixed,
          },
          "desktopHeader"
        )}
      >
        {layout.ref.promoBar?.data &&
          showPromoBar(layout.ref.promoBar, home ?? false) && (
            <PromoBar {...toPromoBarProps(layout.ref.promoBar.data)} />
          )}
        {tpHeaderWidgetDesktop && (
          <TrustBox id="tpHeaderWidgetDesktop" body={tpHeaderWidgetDesktop} />
        )}
        <Header
          shrink={shrink}
          id={headerId}
          logoUrl={imageUrl(layout.data.header.logo, {
            width: 250,
          })}
          className={classes.header}
          containerClassName={classes.headerContainer}
          leftControls={<HeaderGenderPages className={classes.headerContent} />}
          rightControls={
            <div className="flex items-center justify-end">
              <div className={`${classes.control} ${classes.controlRight}`}>
                <AccountLink className={classes.icon} />
              </div>
              <div className={`${classes.control} ${classes.controlRight}`}>
                <WishlistLink
                  type="button"
                  onClick={() => dispatch(updateWishlistDrawerState(true))}
                  className={classes.icon}
                />
              </div>
              <div className={`${classes.control} ${classes.controlRight}`}>
                <BasketLink
                  type="button"
                  onClick={() => dispatch(updateBasketDrawerState(true))}
                  className={classes.icon}
                />
              </div>
            </div>
          }
          animateIn={isFirstPage}
        />
        <HeaderLinksBar />
      </div>
    </div>
  )
}

export default DefaultDesktopHeader
