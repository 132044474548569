import React from "react"
import { FooterSocialSection } from "../types"
import classes from "./FooterSocialIcons.module.css"

interface Props {
  social: FooterSocialSection
  justifyEnd?: boolean
}

const FooterSocialIcons = ({ social, justifyEnd }: Props) => {
  return (
    <div
      className={`flex items-center ${
        justifyEnd ? "justify-end" : "justify-center"
      }`}
    >
      {social.items.map((item, index) => (
        <a
          className={classes.link}
          href={item.url}
          rel="noreferrer"
          target="_blank"
          key={index}
        >
          {item.icon}
        </a>
      ))}
    </div>
  )
}

export default FooterSocialIcons
