import dynamic from "next/dynamic"
import React from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../../../state/store"
import { useMarketingPopupTriggers } from "../../hooks/useMarketingPopupTriggers"
import { setPopupOpened } from "../../state/popupActions"

const NewsletterPopup = dynamic(() => import("../NewsletterPopup"), {
  ssr: false,
})

const GamePopup = dynamic(() => import("../GamePopup"), {
  ssr: false,
})

const PromoBannerController = () => {
  useMarketingPopupTriggers()
  const { opened, triggeredPopup } = useAppSelector((state) => state.popup)
  const dispatch = useDispatch()

  const handleClose = () => dispatch(setPopupOpened(false))

  return (
    <>
      {/* {triggeredPopup?.type === "timedPromo" && <PromoVoucherPopup />} */}
      {triggeredPopup?.data && triggeredPopup?.type === "gameModal" && (
        <GamePopup
          opened={opened}
          onClose={handleClose}
          data={triggeredPopup.data}
        />
      )}
      {triggeredPopup?.data && triggeredPopup?.type === "newsletter" && (
        <NewsletterPopup
          opened={opened}
          onClose={handleClose}
          data={triggeredPopup.data}
        />
      )}
    </>
  )
}

export default PromoBannerController
