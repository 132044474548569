import { TextVariant, ThemeColor, without } from "@punks/core"
import classNames from "classnames"
import React from "react"
import { isLast } from "../../../utils/array"
import { Collapse } from "../../animations/Collapse"
import Typography from "../../atoms/Typography"
import { ChevronDownIcon } from "../../icons/Chevron"
import classes from "./CustomAccordion.module.css"

export interface AccordionItem {
  id: string
  title: React.ReactNode
  content: React.ReactNode
}

export type IconSize = "small" | "medium"

export interface CustomAccordionClasses {
  head?: string
  title?: string
  content?: string
}

export interface CustomAccordionProps {
  textColor?: ThemeColor
  titleVariant: TextVariant
  items: AccordionItem[]
  expanded?: string[]
  onChange?: (newValue: boolean) => void
  className?: string
  noGutters?: boolean
  iconSize?: IconSize
  jsHidden?: boolean
  separators?: boolean
  classes?: CustomAccordionClasses
}

const CustomAccordion = ({
  textColor,
  items,
  expanded,
  onChange,
  className,
  noGutters,
  iconSize,
  jsHidden,
  separators,
  titleVariant,
  classes: userClasses,
}: CustomAccordionProps) => {
  const [uncontrolledExpanded, setUncontrolledExpanded] = React.useState<
    string[]
  >(expanded ?? [])
  const isControlled = () => onChange !== undefined

  const isExpanded = (item: AccordionItem) => {
    if (isControlled()) {
      return expanded?.includes(item.id)
    }
    return uncontrolledExpanded.includes(item.id)
  }

  const handleChange = (item: AccordionItem) => {
    if (isControlled()) {
      onChange?.(!isExpanded(item))
    }

    if (uncontrolledExpanded.includes(item.id)) {
      setUncontrolledExpanded(without(uncontrolledExpanded, item.id))
    } else {
      setUncontrolledExpanded([item.id])
    }
  }

  return (
    <div className={className}>
      {items.map((item, index) => (
        <div
          key={index}
          className={classNames(classes.accordionRoot, {
            [classes.borderTop]: separators,
            [classes.borderBottom]: separators && isLast(index, items),
          })}
          onChange={() => handleChange(item)}
        >
          <button
            className={classNames(
              classes.accordionSummaryRoot,
              {
                [classes.noGutters]: noGutters,
              },
              userClasses?.head
            )}
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
            onClick={() => handleChange(item)}
          >
            <div className="flex items-center justify-between w-full">
              <Typography
                variant={titleVariant}
                component="p"
                weightValue={500 as any}
                color={textColor}
                textClassName={userClasses?.title}
              >
                {item.title}
              </Typography>
              <ChevronDownIcon
                style={{
                  width: 16,
                }}
                className={classNames(
                  {
                    [classes.iconExpanded]: isExpanded(item),
                    [classes.iconMedium]: iconSize === "medium",
                    [classes.iconSmall]: iconSize === "small",
                  },
                  classes.icon
                )}
              />
            </div>
          </button>
          <Collapse
            in={isExpanded(item) ?? false}
            className={classNames(
              classes.accordionDetailsRoot,
              {
                [classes.noGutters]: noGutters,
              },
              userClasses?.content
            )}
          >
            {(!jsHidden || isExpanded(item)) && item.content}
          </Collapse>
        </div>
      ))}
    </div>
  )
}

CustomAccordion.defaultProps = {
  bordersColor: "disabled",
  iconSize: "medium",
  titleVariant: "body1",
}

export default CustomAccordion
