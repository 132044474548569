import React from "react"

interface Props {
  children: any
  className?: string
  style?: React.CSSProperties
  md?: boolean
  lg?: boolean
}

const breakpoint = (md?: boolean, lg?: boolean) => {
  if (lg) {
    return "lg:block"
  }
  if (md) {
    return "md:block"
  }

  return "sm:block"
}

const DesktopOnly = ({ children, className, md, lg, style }: Props) => {
  return (
    <div className={`hidden ${breakpoint(md, lg)} ${className}`} style={style}>
      {children}
    </div>
  )
}

export default DesktopOnly
